export class JsEncode {

    static CryptoJS = require("crypto-js");
    static key = 'av89an7f7y098e4n';

    private static get encryptMethodLength() {
        let encryptMethod: any = this.encryptMethod;
        let aesNumber = encryptMethod.match(/\d+/)[0];
        return parseInt(aesNumber);
    }

    private static get encryptKeySize() {
        let aesNumber = this.encryptMethodLength;
        let a: any = aesNumber / 8;
        return parseInt(a);
    }

    private static get encryptMethod() {
        return 'AES-256-CBC';
    }

    static decrypt(encryptedString: any) {

        let json:any = null;

        try {
            json = JSON.parse(this.CryptoJS.enc.Utf8.stringify(this.CryptoJS.enc.Base64.parse(encryptedString)));
        } catch (_e) {
            return null;
        }
        
        let salt = this.CryptoJS.enc.Hex.parse(json.salt);
        let iv = this.CryptoJS.enc.Hex.parse(json.iv);

        let encrypted = json.ciphertext;// no need to base64 decode.

        let iterations = parseInt(json.iterations);
        if (iterations <= 0) {
            iterations = 999;
        }
        let encryptMethodLength = (this.encryptMethodLength/4);// example: AES number is 256 / 4 = 64
        let hashKey = this.CryptoJS.PBKDF2(this.key, salt, {'hasher': this.CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength/8), 'iterations': iterations});

        let decrypted = this.CryptoJS.AES.decrypt(encrypted, hashKey, {'mode': this.CryptoJS.mode.CBC, 'iv': iv});

        return decrypted.toString(this.CryptoJS.enc.Utf8);
    }

    static encrypt(string: any) {
        let iv = this.CryptoJS.lib.WordArray.random(16);// the reason to be 16, please read on `encryptMethod` property.

        let salt = this.CryptoJS.lib.WordArray.random(256);
        let iterations = 999;
        let encryptMethodLength = (this.encryptMethodLength/4);// example: AES number is 256 / 4 = 64
        let hashKey = this.CryptoJS.PBKDF2(this.key, salt, {'hasher': this.CryptoJS.algo.SHA512, 'keySize': (encryptMethodLength/8), 'iterations': iterations});

        let encrypted = this.CryptoJS.AES.encrypt(string, hashKey, {'mode': this.CryptoJS.mode.CBC, 'iv': iv});
        let encryptedString = this.CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

        let output = {
            'ciphertext': encryptedString,
            'iv': this.CryptoJS.enc.Hex.stringify(iv),
            'salt': this.CryptoJS.enc.Hex.stringify(salt),
            'iterations': iterations
        };

        return this.CryptoJS.enc.Base64.stringify(this.CryptoJS.enc.Utf8.parse(JSON.stringify(output)));
    }

}