<button [disabled]="disabled" class="flex btn btn-primary items-center justify-start text-left mt-5 w-full" #ElementRef
    data-tw-toggle="modal" data-tw-target="#overlay-ipr" href="javascript:;">
    <ng-icon name="feather-file-text" size="16px" class="mr-2"></ng-icon>
    Instrumento de Valoración de Riesgos
</button>
<div id="overlay-ipr" class="modal modal-slide-over" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">

        <div class="modal-content" style="display: flex; flex-direction: column">
            <div class="modal-header p-5">
                <h2 class="font-medium text-base mr-auto flex items-center">
                    <ng-icon name="feather-file-text" size="16px" class="mr-2"></ng-icon>
                    Instrumento de Valoración de Riesgos
                </h2>
            </div>
            <div [formGroup]="assessmentData" class="modal-body">
                <div class="col-span-12 mt-5">
                    <div class="col-span-12 grid grid-cols-12 gap-1">
                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['responsible_assessment'].errors && assessmentData.controls['responsible_assessment'].touched ? 'has-error' : ''">
                            <label for="document-title" class="intro-x cursor-pointer form-label"><b>Responsable de la
                                    valoración: </b></label>
                            <div class="input-group intro-x">
                                <label for="document-title"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-user" size="1.25rem"></ng-icon>
                                </label>
                                <input [value]="assessmentData.get('responsible_assessment').value"
                                    formControlName="responsible_assessment" type="text"
                                    name="document-responsible_assessment" id="document-responsible_assessment"
                                    type="text" class="intro-x form-control py-3 px-4 block"
                                    placeholder="Responsable de la valoración" title="Responsable de la valoración" />
                            </div>
                            <div class="intro-x pristine-error text-danger mt-2"
                                *ngIf="assessmentData.controls['responsible_assessment'].errors && assessmentData.controls['responsible_assessment'].touched">
                                Por favor introduzca un Responsable de la valoración válido.</div>
                        </div>
                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['file'].errors && assessmentData.controls['file'].touched ? 'has-error' : ''">
                            <label for="document-title" class="intro-x cursor-pointer form-label"><b>Número de
                                    expediente: </b></label>
                            <div class="input-group intro-x">
                                <label for="document-title"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-file-text" size="1.25rem"></ng-icon>
                                </label>
                                <input [value]="assessmentData.get('file').value" formControlName="file" type="text"
                                    name="document-file" id="document-file" type="text"
                                    class="intro-x form-control py-3 px-4 block" placeholder="Número de expediente "
                                    title="Número de expediente " />
                            </div>
                            <div class="intro-x pristine-error text-danger mt-2"
                                *ngIf="assessmentData.controls['file'].errors && assessmentData.controls['file'].touched">
                                Por favor introduzca un Número de expediente válido.</div>
                        </div>
                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['date_assessment'].errors && assessmentData.controls['date_assessment'].touched ? 'has-error' : ''">
                            <label for="birthXYZdayDn" class="intro-x cursor-pointer form-label">Fecha de
                                valoración:</label>
                            <div class="input-group">
                                <label for="birthXYZdayDn"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-calendar" size="1.25rem"></ng-icon>
                                </label>
                                <input formControlName="date_assessment" id="date_assessmentDn" name="birthXYZdayDn"
                                    mask="00-00-0000" required type="text" autocomplete="doNotAutoComplete"
                                    class="intro-x form-control py-3 px-4 block" placeholder="Fecha de valoración"
                                    title="Fecha de valoración" />
                            </div>
                            <div *ngIf="assessmentData.controls['date_assessment'].errors && assessmentData.controls['date_assessment'].touched"
                                class="intro-x pristine-error text-danger mt-2">Por favor introduzca una fecha de
                                valoración válida.</div>
                        </div>
                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['date_facts'].errors && assessmentData.controls['date_facts'].touched ? 'has-error' : ''">
                            <label for="birthXYZdayDn" class="intro-x cursor-pointer form-label">Fecha en las que
                                ocurrieron los hechos:</label>
                            <div class="input-group">
                                <label for="birthXYZdayDn"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-calendar" size="1.25rem"></ng-icon>
                                </label>
                                <input formControlName="date_facts" id="date_factsDn" name="birthXYZdayDn"
                                    mask="00-00-0000" required type="text" autocomplete="doNotAutoComplete"
                                    class="intro-x form-control py-3 px-4 block"
                                    placeholder="Fecha en la que ocurrieron los hechos"
                                    title="Fecha en la que ocurrieron los hechos" />
                            </div>
                            <div *ngIf="assessmentData.controls['date_facts'].errors && assessmentData.controls['date_facts'].touched"
                                class="intro-x pristine-error text-danger mt-2">Por favor introduzca una fecha válida.
                            </div>
                        </div>

                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['complainant'].errors && assessmentData.controls['complainant'].touched ? 'has-error' : ''">
                            <label for="complainantDn" class="intro-x cursor-pointer form-label"><b>Persona
                                    Denunciante:</b></label>
                            <div class="input-group intro-x">
                                <label for="complainantDn"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-triangle" size="1.25rem"></ng-icon>
                                </label>
                                <ng-select2 #complainant [disabled]="!complainantList[0]" [data]="complainantList"
                                    formControlName="complainant" id="complainantDn" name="complainantDn"
                                    placeholder="Persona Denunciante" title="Persona Denunciante"
                                    class="intro-x form-control select2Override"></ng-select2>
                            </div>
                            <div class="intro-x pristine-error text-danger mt-2"
                                *ngIf="assessmentData.controls['complainant'].errors && assessmentData.controls['complainant'].touched">
                                Por favor seleccione una Persona Denunciante válida.</div>
                        </div>
                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['responsible'].errors && assessmentData.controls['responsible'].touched ? 'has-error' : ''">
                            <label for="responsibleDn" class="intro-x cursor-pointer form-label"><b>Persona Señalada
                                    Como
                                    Responsable:</b></label>
                            <div class="input-group intro-x">
                                <label for="responsibleDn"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-triangle" size="1.25rem"></ng-icon>
                                </label>
                                <ng-select2 #responsible [disabled]="!responsibleList[0]" [data]="responsibleList"
                                    formControlName="responsible" id="responsibleDn" name="responsibleDn"
                                    placeholder="Persona señalada como presunto responsable"
                                    title="Persona señalada como presunto responsable"
                                    class="intro-x form-control select2Override"></ng-select2>
                            </div>
                            <div class="intro-x pristine-error text-danger mt-2"
                                *ngIf="assessmentData.controls['responsible'].errors && assessmentData.controls['responsible'].touched">
                                Por favor seleccione una Persona señalada como presunto responsable válida.</div>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 grid grid-cols-12 gap-1 intro-x"
                    [ngClass]="{'hidden':(assessmentData.controls['complainant'].value ) == '' ||  (assessmentData.controls['responsible'].value ) == ''}">
                    <label class="intro-x col-span-12 ">
                        <b class="text-base !my-4 inline-flex"> A continuación se presentan factores clave para valorar
                            el
                            riesgo, realice el llenado correspondiente de cada uno de los indicadores.
                            :</b>

                    </label>
                    <div formArrayName="personal_complaint" class="col-span-12 mt-5 bg-violet-300 rounded-2xl  intro-x">
                        <label class="intro-x ">
                            <b class="text-base !my-4 inline-flex"> Factores clave para valorar el riesgo que vive la
                                persona denunciante:</b>
                        </label>
                        <p class="bg-violet-600 !min-h-[30px] leading-10 text-center text-white">
                            URGENCIA
                        </p>
                        <div class="p-2"
                            *ngFor="let value  of PropertyJson.personal_complaint | myfilter:'URGENCIA'; let i = index; ">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label> 
                            <div [formGroupName]="value.id-1"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-e-1" class="form-check-input" type="radio" [value]="{
                                        value:0,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-e-1">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-a-1" class="form-check-input" type="radio" [value]="{
                                        value:1,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-a-1">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-b-1" class="form-check-input" type="radio" [value]="{
                                        value:2,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-b-1">Se desconoce</label>
                                </div>
                                <!-- <div class="intro-x pristine-error text-danger mt-2" *ngIf="violenceData.controls['violence'].errors && violenceData.controls['violence'].touched">Por favor seleccione una opción.</div> -->

                            </div>
                        </div>
                        <p class="bg-violet-600 !min-h-[30px] leading-10 text-center text-white">
                            GRAVEDAD
                        </p>
                        <div class="p-2"
                            *ngFor="let value  of PropertyJson.personal_complaint | myfilter:'GRAVEDAD'; let i = index ">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label> 
                            <div [formGroupName]="value.id-1"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-e-2" class="form-check-input" type="radio" [value]="{
                                        value:0,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-2">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-a-2" class="form-check-input" type="radio" [value]="{
                                        value:1,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-2">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-b-2" class="form-check-input" type="radio" [value]="{
                                        value:2,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-2">Se desconoce</label>
                                </div>
                                <!-- <div class="intro-x pristine-error text-danger mt-2" *ngIf="violenceData.controls['violence'].errors && violenceData.controls['violence'].touched">Por favor seleccione una opción.</div> -->

                            </div>
                        </div>
                        <p class="bg-violet-600 !min-h-[30px] leading-10 text-center text-white">
                            FRECUENCIA
                        </p>
                        <div class="p-2"
                            *ngFor="let value  of PropertyJson.personal_complaint | myfilter:'FRECUENCIA'; let i = index ">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="value.id-1"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-e-3" class="form-check-input" type="radio" [value]="{
                                        value:0,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-e-3">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-a-3" class="form-check-input" type="radio" [value]="{
                                        value:1,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-a-3">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-b-3" class="form-check-input" type="radio" [value]="{
                                        value:2,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-b-3">Se desconoce</label>
                                </div>
                                <!-- <div class="intro-x pristine-error text-danger mt-2" *ngIf="violenceData.controls['violence'].errors && violenceData.controls['violence'].touched">Por favor seleccione una opción.</div> -->

                            </div>
                        </div>
                        <p class="bg-violet-600 !min-h-[30px] leading-10 text-center text-white">
                            POSICIÓN DE PODER
                        </p>
                        <div class="p-2"
                            *ngFor="let value  of PropertyJson.personal_complaint | myfilter:'POSICIÓN DE PODER'; let i = index ">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="value.id-1"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-e-4" class="form-check-input" type="radio" [value]="{
                                        value:0,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-e-4">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-a-4" class="form-check-input" type="radio" [value]="{
                                        value:1,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-a-4">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-b-4" class="form-check-input" type="radio" [value]="{
                                        value:2,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-b-4">Se desconoce</label>
                                </div>
                                <!-- <div class="intro-x pristine-error text-danger mt-2" *ngIf="violenceData.controls['violence'].errors && violenceData.controls['violence'].touched">Por favor seleccione una opción.</div> -->

                            </div>
                        </div>
                        <p class="bg-violet-600 !min-h-[30px] leading-10 text-center text-white">
                            PROBABLE RECURRENCIA DE LA CONDUCTA DENUNCIADA
                        </p>
                        <div class="p-2"
                            *ngFor="let value  of PropertyJson.personal_complaint | myfilter:'PROBABLE RECURRENCIA DE LA CONDUCTA DENUNCIADA'; let i = index ">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="value.id-1"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-e-5" class="form-check-input" type="radio" [value]="{
                                        value:0,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-e-5">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-a-5" class="form-check-input" type="radio" [value]="{
                                        value:1,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-a-5">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="violence-switch-{{i}}-b-5" class="form-check-input" type="radio" [value]="{
                                        value:2,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-b-5">Se desconoce</label>
                                </div>
                                <!-- <div class="intro-x pristine-error text-danger mt-2" *ngIf="violenceData.controls['violence'].errors && violenceData.controls['violence'].touched">Por favor seleccione una opción.</div> -->

                            </div>
                        </div>
                        <p class="bg-violet-600 !min-h-[30px] leading-10 text-center text-white">
                            INMINENCIA DEL RIESGO
                        </p>
                        <div class="p-2"
                            *ngFor="let value  of PropertyJson.personal_complaint | myfilter:'INMINENCIA DEL RIESGO'; let i = index ">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="value.id-1"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2" *ngIf="value.text != 'Otros (especificarlos)'">
                                    <input id="violence-switch-{{i}}-e-6" class="form-check-input" type="radio" [value]="{
                                        value:0,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-e-6">No</label>
                                </div>
                                <div class="form-check mt-2" *ngIf="value.text != 'Otros (especificarlos)'">
                                    <input id="violence-switch-{{i}}-a-6" class="form-check-input" type="radio" [value]="{
                                        value:1,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-a-6">Sí</label>
                                </div>
                                <div class="form-check mt-2" *ngIf="value.text != 'Otros (especificarlos)'">
                                    <input id="violence-switch-{{i}}-b-6" class="form-check-input" type="radio" [value]="{
                                        value:2,
                                        id:value.id
                                    }" formControlName="personal_complaint_value" />
                                    <label class="form-check-label" for="violence-switch-{{i}}-b-6">Se desconoce</label>
                                </div>
                                <div class="form-check mt-2" style="width:100%"
                                    *ngIf="value.text == 'Otros (especificarlos)'">
                                    <textarea appTrimInput [paragraphMode]="true" formControlName="personal_complaint_value"
                                        name="characteXYZristicsDn" id="characteXYZristicsDn" type="text"
                                        class="intro-x form-control py-3 px-4 block" placeholder="Descripción"
                                        title="Descripción"></textarea>
                                </div>
                                <!-- <div class="intro-x pristine-error text-danger mt-2" *ngIf="violenceData.controls['violence'].errors && violenceData.controls['violence'].touched">Por favor seleccione una opción.</div> -->

                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 mt-5"
                        [ngClass]="{'hidden':(assessmentData.controls['complainant'].value ) == '' ||  (assessmentData.controls['responsible'].value ) == ''}">
                        <div class="col-span-12 grid grid-cols-12 gap-1">
                            <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                                [ngClass]="assessmentData.controls['personal_complaint_factor_analysis'].errors && assessmentData.controls['personal_complaint_factor_analysis'].touched ? 'has-error' : ''">
                                <label for="document-title" class="intro-x cursor-pointer form-label"><b>ACTUACIÓN EN
                                        FUNCIÓN DE LA VALORACIÓN DEL RIESGO ACTUAL EXISTENTE </b></label>
                                <div class="input-group intro-x">
                                    <label for="document-personal_complaint_factor_analysis"
                                        class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                        <ng-icon name="feather-book" size="1.25rem"></ng-icon>
                                    </label>
                                    <textarea appTrimInput [paragraphMode]="true"
                                        formControlName="personal_complaint_factor_analysis"
                                        name="personal_complaint_factor_analysis"
                                        id="personal_complaint_factor_analysis" type="text"
                                        class="intro-x form-control py-3 px-4 block"
                                        placeholder="ACTUACIÓN EN FUNCIÓN DE LA VALORACIÓN DEL RIESGO ACTUAL EXISTENTE"
                                        title="ACTUACIÓN EN FUNCIÓN DE LA VALORACIÓN DEL RIESGO ACTUAL EXISTENTE"></textarea>

                                </div>
                            </div>
                            <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                                [ngClass]="assessmentData.controls['personal_complaint_foreseeable_circumstances'].errors && assessmentData.controls['personal_complaint_foreseeable_circumstances'].touched ? 'has-error' : ''">
                                <label for="document-title" class="intro-x cursor-pointer form-label"><b>CIRCUNSTANCIAS
                                        PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO </b></label>
                                <div class="input-group intro-x">
                                    <label for="document-personal_complaint_foreseeable_circumstances"
                                        class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                        <ng-icon name="feather-book" size="1.25rem"></ng-icon>
                                    </label>
                                    <textarea appTrimInput [paragraphMode]="true"
                                        formControlName="personal_complaint_foreseeable_circumstances"
                                        name="personal_complaint_foreseeable_circumstances"
                                        id="personal_complaint_foreseeable_circumstances" type="text"
                                        class="intro-x form-control py-3 px-4 block"
                                        placeholder="CIRCUNSTANCIAS PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO "
                                        title="CIRCUNSTANCIAS PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO "></textarea>

                                </div>
                                <div class="intro-x pristine-error text-danger mt-2"
                                    *ngIf="assessmentData.controls['personal_complaint_foreseeable_circumstances'].errors && assessmentData.controls['personal_complaint_foreseeable_circumstances'].touched">
                                    Por favor introduzca una circunstancia previsible válida.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-span-12 grid grid-cols-12 gap-1 intro-x"
                        [ngClass]="{'hidden':(assessmentData.controls['complainant'].value ) == '' ||  (assessmentData.controls['responsible'].value ) == ''}">
                        <b class="text-base !my-4 inline-flex  col-span-12"> SUMA DE RESPUESTAS AFIRMATIVAS /
                            VALORACIÓN DEL RIESGO </b>
                        <p class="{{CheckSumPersonalComplaint().color}} col-span-12 text-center rounded-2xl">
                            <strong>
                                {{CheckSumPersonalComplaint().text}}
                            </strong>
                            <br>
                            {{CheckSumPersonalComplaint().count_text }}
                        </p>
                        <p class="text-sm col-span-12 text-center text-slate-500">
                            Este instrumento es solo útil si se dispone de información para responder al menos 10 items.
                            En caso contrario se recomienda no hacer valoraciones definitivas hasta completar el número
                            mínimo de items.
                        </p>

                    </div>

                    <label class="intro-x col-span-12 ">
                        <b class="text-base !my-4 inline-flex"> Para valorar el riesgo, responda a las siguientes
                            preguntas:</b>
                        <p class="!my-4 col-span-12 ">¿La persona denunciante (o quien es señalada como afectada)
                            expresa que la persona
                            señalada como
                            responsable ha…</p>
                    </label>
                    <div formArrayName="respect_consideration"
                        class="col-span-12 mt-5 bg-teal-300 rounded-2xl p-2 intro-x">
                        <label class="intro-x ">
                            <b class="text-base !my-4 inline-flex">FALTAR EL RESPETO Y LA CONSIDERACIÓN DEBIDOS (ART
                                .09)</b>
                        </label>
                        <div *ngFor="let value  of PropertyJson.respect_consideration; let i = index">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="i"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="respect_consideration-switch-{{i}}-e" class="form-check-input"
                                        type="radio" [value]="{
                                        value:0,
                                        id:i
                                    }" formControlName="respect_consideration_value" />
                                    <label class="form-check-label"
                                        for="respect_consideration-switch-{{i}}-e">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="respect_consideration-switch-{{i}}-a" class="form-check-input"
                                        type="radio" [value]="{
                                        value:1,
                                        id:i
                                    }" formControlName="respect_consideration_value" />
                                    <label class="form-check-label"
                                        for="respect_consideration-switch-{{i}}-a">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="respect_consideration-switch-{{i}}-b" class="form-check-input"
                                        type="radio" [value]="{
                                        value:2,
                                        id:i
                                    }" formControlName="respect_consideration_value" />
                                    <label class="form-check-label" for="respect_consideration-switch-{{i}}-b">Se
                                        desconoce</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div formArrayName="hostility_coercion" class="col-span-12 mt-5 bg-sky-200 rounded-2xl p-2 intro-x">
                        <label class="intro-x ">
                            <b class="text-base !my-4 inline-flex"> HOSTILIDAD O COACCIÓN POR RAZONES IDEOLÓGICAS (ART
                                .10)</b>
                        </label>
                        <div *ngFor="let value  of PropertyJson.hostility_coercion; let i = index">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="i"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="hostility_coercion-switch-{{i}}-e" class="form-check-input" type="radio"
                                        [value]="{
                                        value:0,
                                        id:i
                                    }" formControlName="hostility_coercion_value" />
                                    <label class="form-check-label" for="hostility_coercion-switch-{{i}}-e">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="hostility_coercion-switch-{{i}}-a" class="form-check-input" type="radio"
                                        [value]="{
                                        value:1,
                                        id:i
                                    }" formControlName="hostility_coercion_value" />
                                    <label class="form-check-label" for="hostility_coercion-switch-{{i}}-a">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="hostility_coercion-switch-{{i}}-b" class="form-check-input" type="radio"
                                        [value]="{
                                        value:2,
                                        id:i
                                    }" formControlName="hostility_coercion_value" />
                                    <label class="form-check-label" for="hostility_coercion-switch-{{i}}-b">Se
                                        desconoce</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div formArrayName="sexual_violence" class="col-span-12 mt-5 bg-green-200 rounded-2xl p-2 intro-x">
                        <label class="intro-x ">
                            <b class="text-base !my-4 inline-flex"> VIOLENCIA SEXUAL (ART .17)</b>
                        </label>
                        <div *ngFor="let value  of PropertyJson.sexual_violence; let i = index">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="i"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="sexual_violence-switch-{{i}}-e" class="form-check-input" type="radio"
                                        [value]="{
                                        value:0,
                                        id:i
                                    }" formControlName="sexual_violence_value" />
                                    <label class="form-check-label" for="sexual_violence-switch-{{i}}-e">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="sexual_violence-switch-{{i}}-a" class="form-check-input" type="radio"
                                        [value]="{
                                        value:1,
                                        id:i
                                    }" formControlName="sexual_violence_value" />
                                    <label class="form-check-label" for="sexual_violence-switch-{{i}}-a">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="sexual_violence-switch-{{i}}-b" class="form-check-input" type="radio"
                                        [value]="{
                                        value:2,
                                        id:i
                                    }" formControlName="sexual_violence_value" />
                                    <label class="form-check-label" for="sexual_violence-switch-{{i}}-b">Se
                                        desconoce</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div formArrayName="school_violence" [ngClass]="{'hidden':checkSchoolViolence()}"
                        class="col-span-12 mt-5 bg-fuchsia-300 rounded-2xl p-2 intro-x">
                        <label class="intro-x ">
                            <b class="text-base !my-4 inline-flex"> VIOLENCIA ESCOLAR (ART .18)</b>
                        </label>
                        <div *ngFor="let value  of PropertyJson.school_violence; let i = index">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="i"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="school_violence-switch-{{i}}-e" class="form-check-input" type="radio"
                                        [value]="{
                                        value:0,
                                        id:i
                                    }" formControlName="school_violence_value" />
                                    <label class="form-check-label" for="school_violence-switch-{{i}}-e">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="school_violence-switch-{{i}}-a" class="form-check-input" type="radio"
                                        [value]="{
                                        value:1,
                                        id:i
                                    }" formControlName="school_violence_value" />
                                    <label class="form-check-label" for="school_violence-switch-{{i}}-a">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="school_violence-switch-{{i}}-b" class="form-check-input" type="radio"
                                        [value]="{
                                        value:2,
                                        id:i
                                    }" formControlName="school_violence_value" />
                                    <label class="form-check-label" for="school_violence-switch-{{i}}-b">Se
                                        desconoce</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div formArrayName="workplace_violence" [ngClass]="{'hidden':checkWorkplaceViolence()}"
                        class="col-span-12 mt-5 bg-rose-300 rounded-2xl p-2 intro-x">
                        <label class="intro-x ">
                            <b class="text-base !my-4 inline-flex"> VIOLENCIA LABORAL (ART .19)</b>
                        </label>
                        <div *ngFor="let value  of PropertyJson.workplace_violence; let i = index">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="i"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="workplace_violence-switch-{{i}}-e" class="form-check-input" type="radio"
                                        [value]="{
                                        value:0,
                                        id:i
                                    }" formControlName="workplace_violence_value" />
                                    <label class="form-check-label" for="workplace_violence-switch-{{i}}-e">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="workplace_violence-switch-{{i}}-a" class="form-check-input" type="radio"
                                        [value]="{
                                        value:1,
                                        id:i
                                    }" formControlName="workplace_violence_value" />
                                    <label class="form-check-label" for="workplace_violence-switch-{{i}}-a">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="workplace_violence-switch-{{i}}-b" class="form-check-input" type="radio"
                                        [value]="{
                                        value:2,
                                        id:i
                                    }" formControlName="workplace_violence_value" />
                                    <label class="form-check-label" for="workplace_violence-switch-{{i}}-b">Se
                                        desconoce</label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div formArrayName="gender_violence" class="col-span-12 mt-5 bg-amber-200 rounded-2xl p-2 intro-x">
                        <label class="intro-x ">
                            <b class="text-base !my-4 inline-flex"> VIOLENCIA DE GÉNERO (ART .20)</b>
                        </label>
                        <div *ngFor="let value  of PropertyJson.gender_violence; let i = index">
                            <label class="intro-x">
                                <p>{{value.text}}:</p>
                            </label>
                            <div [formGroupName]="i"
                                style="display:flex !important; justify-content: space-evenly; margin-top: 20px; margin-bottom: 20px;">
                                <div class="form-check mt-2">
                                    <input id="gender_violence-switch-{{i}}-e" class="form-check-input" type="radio"
                                        [value]="{
                                        value:0,
                                        id:i
                                    }" formControlName="gender_violence_value" />
                                    <label class="form-check-label" for="gender_violence-switch-{{i}}-e">No</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="gender_violence-switch-{{i}}-a" class="form-check-input" type="radio"
                                        [value]="{
                                        value:1,
                                        id:i
                                    }" formControlName="gender_violence_value" />
                                    <label class="form-check-label" for="gender_violence-switch-{{i}}-a">Sí</label>
                                </div>
                                <div class="form-check mt-2">
                                    <input id="gender_violence-switch-{{i}}-b" class="form-check-input" type="radio"
                                        [value]="{
                                        value:2,
                                        id:i
                                    }" formControlName="gender_violence_value" />
                                    <label class="form-check-label" for="gender_violence-switch-{{i}}-b">Se
                                        desconoce</label>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 mt-5"
                    [ngClass]="{'hidden':(assessmentData.controls['complainant'].value ) == '' ||  (assessmentData.controls['responsible'].value ) == ''}">
                    <div class="col-span-12 grid grid-cols-12 gap-1">
                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['factor_analysis'].errors && assessmentData.controls['factor_analysis'].touched ? 'has-error' : ''">
                            <label for="document-title" class="intro-x cursor-pointer form-label"><b>ACTUACIÓN EN
                                    FUNCIÓN DE LA VALORACIÓN DEL RIESGO ACTUAL EXISTENTE </b></label>
                            <div class="input-group intro-x">
                                <label for="document-factor_analysis"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-book" size="1.25rem"></ng-icon>
                                </label>
                                <textarea appTrimInput [paragraphMode]="true" formControlName="factor_analysis"
                                    name="factor_analysis" id="factor_analysis" type="text"
                                    class="intro-x form-control py-3 px-4 block"
                                    placeholder="ACTUACIÓN EN FUNCIÓN DE LA VALORACIÓN DEL RIESGO ACTUAL EXISTENTE"
                                    title="ACTUACIÓN EN FUNCIÓN DE LA VALORACIÓN DEL RIESGO ACTUAL EXISTENTE"></textarea>

                            </div>
                        </div>
                        <div class="col-span-12 md:col-span-12 2xl:col-span-12 mt-5"
                            [ngClass]="assessmentData.controls['foreseeable_circumstances'].errors && assessmentData.controls['foreseeable_circumstances'].touched ? 'has-error' : ''">
                            <label for="document-title" class="intro-x cursor-pointer form-label"><b>CIRCUNSTANCIAS
                                    PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO </b></label>
                            <div class="input-group intro-x">
                                <label for="document-foreseeable_circumstances"
                                    class="intro-x cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
                                    <ng-icon name="feather-book" size="1.25rem"></ng-icon>
                                </label>
                                <textarea appTrimInput [paragraphMode]="true"
                                    formControlName="foreseeable_circumstances" name="foreseeable_circumstances"
                                    id="foreseeable_circumstances" type="text"
                                    class="intro-x form-control py-3 px-4 block"
                                    placeholder="CIRCUNSTANCIAS PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO "
                                    title="CIRCUNSTANCIAS PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO "></textarea>

                            </div>
                            <div class="intro-x pristine-error text-danger mt-2"
                                *ngIf="assessmentData.controls['foreseeable_circumstances'].errors && assessmentData.controls['foreseeable_circumstances'].touched">
                                Por favor introduzca una circunstancia previsible válida.</div>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 grid grid-cols-12 gap-1 intro-x"
                    [ngClass]="{'hidden':(assessmentData.controls['complainant'].value ) == '' ||  (assessmentData.controls['responsible'].value ) == ''}">
                    <b class="text-base !my-4 inline-flex  col-span-12"> SUMA DE RESPUESTAS AFIRMATIVAS /
                        VALORACIÓN DEL RIESGO </b>
                    <p class="{{CheckSum().color}} col-span-12 text-center rounded-2xl">
                        <strong>
                            {{CheckSum().text}}
                        </strong>
                        <br>
                        {{CheckSum().count_text }}
                    </p>
                    <p class="text-sm col-span-12 text-center text-slate-500">
                        Este instrumento es solo útil si se dispone de información para responder al menos 10 items. En
                        caso contrario se recomienda no hacer valoraciones definitivas hasta completar el número mínimo
                        de items.
                    </p>
                </div>
            </div>

            <div style="flex: 1"></div>

            <div class="modal-body w-full border-t border-slate-200/60">
                <div class="flex"> 

                   

                    <button class="flex-1 btn btn-secondary rounded-r-none" data-tw-dismiss="modal">Cancelar</button>
                    <!-- [disabled]="Motivo.invalid || loading || selectedUsers.invalid" -->
					<button id="ans4" class="flex-1 btn btn-primary rounded-l-none" (click)="formSubmit(disableOverlay)" 
                    
                    >Continuar</button>
					<button class="flex-1 btn btn-primary rounded-l-none hidden" #disableOverlay data-tw-dismiss="modal">Continuar</button>

                </div>
            </div>
        </div>
    </div>
</div>