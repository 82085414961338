<div style="flex: 1 0 auto; min-height: 550px; max-width: 100%" class="flex items-center justify-center z-10">
	<div class="container sm:px-10">
		<div class="block xl:grid grid-cols-2 gap-4">
			<div class="hidden xl:flex flex-col">
				<div class="my-auto">
					<div class="flex items-center flex-col" style="max-width: 470px">
						<img class="-intro-x w-1/2 -mt-16 mx-auto" src="assets/img/udg-escudo.png" />
						<div class="-intro-x text-center text-white text-3xl font-medium leading-tight mt-10">
							Hagamos que el mundo
							<br />
							sea más justo, juntos y juntas.
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="!step" class="flex flex-col">
				<div class="my-auto">
					<div>
						<div class="-intro-x">
							<img class="xl:hidden md:ml-0 mx-auto mb-10 w-32" src="assets/img/udg-escudo.png" />
							<h2 class="text-center md:text-left text-3xl text-black font-semibold">
								Bienvenido, {{ (UserDataFull.names + ' ' + UserDataFull.last_names) | titlecase }}
								<br />
								Nuestra Misión es poder hacer que presentes tu denuncia. Esto solo tomará un minuto
							</h2>
						</div>
						<div class="intro-x mt-5">
							<a (click)="this.step = true; initializeLib()"
								class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">¡Adelante!</a>
							<a class="btn btn-outline-danger py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top" (click)="backHome()">Salir</a>
						</div>
					</div>
				</div>
			</div>
			<div #form *ngIf="step" class="xl:h-auto flex xl:py-0 xl:my-0 pt-5">
				<form [formGroup]="completeData" autocomplete="doNotAutoComplete"
					class="relative my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
					<app-spinner *ngIf="loader"></app-spinner>

					<img class="xl:hidden mx-auto mb-10" src="assets/img/logo_udg.png" />
					<h2 class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">Por favor completa los siguientes datos:
					</h2>
					<div class="intro-x mt-8">
						<div (click)="validateGender()" class="input-group intro-x mt-4"
							[ngClass]="(completeData.controls['birthday'].touched || completeData.controls['gender'].touched) && (completeData.controls['birthday'].errors || completeData.controls['gender'].errors) ? 'has-error' : ''">
							<label for="birthday"
								class="cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
								<ng-icon name="feather-calendar" size="1.25rem"></ng-icon>
							</label>
							<input mask="00-00-0000" required formControlName="birthday" id="birthday" name="birthday" type="text"
								autocomplete="doNotAutoComplete" tooltip="Fecha de Nacimiento" class="intro-x form-control py-3 px-4 block"
								placeholder="Fecha de Nacimiento" />
							<ng-select2 #gender [disabled]="!genderList[0]" [data]="genderList" formControlName="gender" id="gender"
								name="gender" placeholder="Género" class="intro-x form-control select2Override"></ng-select2>
						</div>
						<div class="intro-x pristine-error text-danger mt-2"
							*ngIf="(completeData.controls['birthday'].touched || completeData.controls['gender'].touched) && (completeData.controls['birthday'].errors || completeData.controls['gender'].errors)">
							Por favor introduzca una fecha de nacimiento o seleccione un género válido.</div>

						<div class="input-group intro-x mt-4"
							[ngClass]="completeData.controls['phone'].touched && completeData.controls['phone'].errors ? 'has-error' : ''">
							<label for="phXYZone"
								class="cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
								<ng-icon name="feather-phone" size="1.25rem"></ng-icon>
							</label>
							<input required formControlName="phone" id="phXYZone" name="phXYZone" type="text"
								autocomplete="doNotAutoComplete" mask="(00) 0000 0000" class="intro-x form-control py-3 px-4 block"
								placeholder="Teléfono" />
						</div>
						<div class="intro-x pristine-error text-danger mt-2"
							*ngIf="completeData.controls['phone'].touched && completeData.controls['phone'].errors">Por favor introduzca un
							número de teléfono válido.</div>

						<div (click)="validateStateMunicipality()" class="input-group intro-x mt-4"
							[ngClass]="(completeData.controls['state'].touched || completeData.controls['municipality'].touched) && (completeData.controls['state'].errors || completeData.controls['municipality'].errors) ? 'has-error' : ''">
							<label for="state" class="cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
								<ng-icon name="feather-map" size="1.25rem"></ng-icon>
							</label>
							<ng-select2 #statessel2 [disabled]="!statesList[0]" [data]="statesList" (ngModelChange)="stateChanged()"
								formControlName="state" id="state" name="state" placeholder="Estado"
								class="intro-x form-control select2Override"></ng-select2>
							<ng-select2 [disabled]="!municipalityList[0]" [data]="municipalityList" formControlName="municipality"
								id="municipality" name="municipality" placeholder="Municipio"
								class="intro-x form-control select2Override"></ng-select2>
						</div>
						<div class="intro-x pristine-error text-danger mt-2"
							*ngIf="(completeData.controls['state'].touched || completeData.controls['municipality'].touched) && (completeData.controls['state'].errors || completeData.controls['municipality'].errors)">
							Por favor seleccione un estado o municipio válido.</div>

						<div class="input-group intro-x mt-4"
							[ngClass]="(completeData.controls['colony'].touched || completeData.controls['zipcode'].touched) && (completeData.controls['colony'].errors || completeData.controls['zipcode'].errors) ? 'has-error' : ''">
							<label for="colXYZony"
								class="cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
								<ng-icon name="feather-map" size="1.25rem"></ng-icon>
							</label>
							<input appTrimInput [value]="completeData.get('colony').value" required formControlName="colony" maxlength="50"
								id="colXYZony" name="colXYZony" type="text" autocomplete="doNotAutoComplete"
								class="intro-x form-control py-3 px-4 block" placeholder="Colonia" />
							<input required formControlName="zipcode" mask="00000" min="00000" max="99999" id="ziXpcYZode" name="ziXpcYZode"
								type="text" autocomplete="doNotAutoComplete" class="intro-x form-control py-3 px-4 block"
								placeholder="Código Postal" />
						</div>
						<div class="intro-x pristine-error text-danger mt-2"
							*ngIf="(completeData.controls['colony'].touched || completeData.controls['zipcode'].touched) && (completeData.controls['colony'].errors || completeData.controls['zipcode'].errors)">
							Por favor introduzca una colonia o código postal válido.</div>

						<div class="input-group intro-x mt-4"
							[ngClass]="completeData.controls['street'].touched && completeData.controls['street'].errors ? 'has-error' : ''">
							<label for="strXYZeet"
								class="cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
								<ng-icon name="feather-map-pin" size="1.25rem"></ng-icon>
							</label>
							<input appTrimInput [value]="completeData.get('street').value" required formControlName="street" maxlength="100"
								id="strXYZeet" name="strXYZeet" type="text" autocomplete="doNotAutoComplete"
								class="intro-x form-control py-3 px-4 block" placeholder="Calle" />
						</div>
						<div class="intro-x pristine-error text-danger mt-2"
							*ngIf="completeData.controls['street'].touched && completeData.controls['street'].errors">Por favor introduzca
							una calle válida.</div>

						<div class="input-group intro-x mt-4"
							[ngClass]="(completeData.controls['instreet'].touched || completeData.controls['extstreet'].touched) && (completeData.controls['instreet'].errors || completeData.controls['extstreet'].errors) ? 'has-error' : ''">
							<label for="instXYZreet"
								class="cursor-pointer input-group-text intro-x py-3 px-4 flex items-center justify-center">
								<ng-icon name="feather-home" size="1.25rem"></ng-icon>
							</label>
							<input formControlName="instreet" maxlength="10" id="instXYZreet" name="instXYZreet" type="text"
								autocomplete="doNotAutoComplete" class="intro-x form-control py-3 px-4 block"
								placeholder="Número de Interior" />
							<input formControlName="extstreet" maxlength="10" id="extstXYZreet" name="extstXYZreet" type="text"
								autocomplete="doNotAutoComplete" class="intro-x form-control py-3 px-4 block"
								placeholder="Número de Exterior" />
						</div>
						<div class="intro-x pristine-error text-danger mt-2"
							*ngIf="(completeData.controls['instreet'].touched || completeData.controls['extstreet'].touched) && (completeData.controls['instreet'].errors || completeData.controls['extstreet'].errors)">
							Por favor introduzca un número de interior o exterior válido.</div>
					</div>
					<!-- <div class="intro-x mt-10 xl:mt-8 text-slate-600 text-center xl:text-left">Al registrarse, acepta nuestros <a
							href="https://www.iubenda.com/condiciones-de-uso/58902792" class="iubenda-noiframe iubenda-embed"
							title="Términos y Condiciones">Términos y Condiciones</a> & <a class="text-primary"
							href="/assets/docs/AvisoPrivacidad.pdf" target="_blank">Política de privacidad</a></div> -->
					<div class="intro-x mt-10 xl:mt-8 text-slate-600 text-center xl:text-left">Al registrarse, acepta nuestra <a class="text-primary"
							href="https://transparencia.udg.mx/aviso-confidencialidad-integral" target="_blank">Política de privacidad</a></div>
					<div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
						<button (click)="submmitComplete()" [disabled]="!completeData.valid || !canComplete"
							class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top">Siguiente</button>
						<a class="btn btn-outline-danger py-3 px-4 w-full xl:w-32 mt-3 xl:mt-0 align-top" (click)="backHome()">Salir</a>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>