import { DOCUMENT } from '@angular/common';
import { Component, ViewChild, Inject, OnInit, ElementRef, Renderer2, AfterViewInit, Input, HostListener } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserData } from 'src/app/core/interfaces/user-data';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { ActivatedRoute, Router } from '@angular/router';
import { JsEncode } from 'src/app/core/exports/JsEncode';
import WaveSurfer from 'wavesurfer.js';

@Component({
	selector: 'app-web-viewer',
	templateUrl: './web-viewer.component.html',
	styleUrls: ['./web-viewer.component.scss'],
	host: { class: 'flex flex-1 w-full h-full' },
})
export class WebViewerComponent implements OnInit, AfterViewInit {
	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		(this.WaveSurferDoc as any).drawer.containerWidth = (this.WaveSurferDoc as any).drawer.container.clientWidth;
		(this.WaveSurferDoc as any).drawBuffer();
	}

	@ViewChild('viewer', { static: true }) viewer!: ElementRef;
	WaveSurferDoc: any;
	@Input() filePath: any;
	private _unsubscribe = new Subject<void>();
	UserData$?: Observable<any>;
	UserDataFull: UserData = {} as UserData;
	viewPausePlay: boolean = false;
	viewTimeProcess: string = '';
	setPlaybackRate: number = 1;
	CurrentTime: number = 0;
	volumen: number = 1;
	volumenMute: number = 1;
	file: any = {
		name: 'Prueba',
		mime: '',
		content: '',
		blob: '',
		blobUrl: '',
		fileExtension: '',
	};

	loader: boolean = true;

	constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private store: Store, private router: Router, private toastr: ToastrService, private apiService: ApiService, private activatedRoute: ActivatedRoute) {
		this.UserData$ = this.store.pipe(select(UserDataFull));
		this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe((data: UserData) => {
			this.UserDataFull = data;
		});
	}

	ngOnInit(): void {
		this.renderer.addClass(this.document.body, 'p-0');
		this.renderer.removeClass(this.document.body, 'main');
	}

	ngAfterViewInit(): void {
		//Encrypted file path
		let filePath = this.filePath;

		//If no file redirect
		if (!filePath) return;

		//Get file
		this.file.name = 'Prueba';
		this.file.mime = filePath.mime;
		this.file.content = filePath.content;
		this.file.fileExtension = filePath.fileExtension;
		this.file.blob = this.b64toBlob(this.file.content, this.file.mime);
		this.file.blobUrl = URL.createObjectURL(this.file.blob);

		var CursorPlugin = (window.WaveSurfer as any).cursor;

		var wavesurfer = WaveSurfer.create({
			container: '#audio-viewer',
			plugins: [
				CursorPlugin.create({
					hideOnBlur: false,
					color: '#FFFFFF',
					width: '1px',
					opacity: '1',
					showTime: true,
					customShowTimeStyle: {
						color: 'aliceblue',
						opacity: '1',
						paddingLeft: '10px',
						paddingRight: '10px',
					},
				}),
			],
			waveColor: '#505b65',
			progressColor: '#aabed1',
		});
		this.WaveSurferDoc = wavesurfer;
		wavesurfer.on('audioprocess', () => {
			if (wavesurfer.isPlaying()) {
				wavesurfer.setVolume(this.volumen);
				var date = new Date(0);
				date.setSeconds(wavesurfer.getCurrentTime()); // specify value for SECONDS here
				var timeString = date.toISOString().substr(11, 8);
				this.viewTimeProcess = timeString;
				(this.document as any).getElementById('time-total').innerText = timeString;
			}
		});
		wavesurfer.load(this.file.blobUrl);
		this.loader = false;
	}

	b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
		const byteCharacters = atob(b64Data);
		const byteArrays:any = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray:any = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}

	PausePlay() {
		if (this.viewPausePlay) {
			this.viewPausePlay = false;
			this.WaveSurferDoc.pause();
		} else {
			this.viewPausePlay = true;
			this.WaveSurferDoc.play();
		}
	}
	getMute() {
		if (this.volumen == 0) {
			this.volumen = this.volumenMute;
		} else {
			this.volumenMute = this.volumen;
			this.volumen = 0;
		}
	}
	changeZoom() {
		if (this.volumen == 0) {
			this.volumenMute = 1;
			this.WaveSurferDoc.setVolume(this.volumen);
		} else {
			this.WaveSurferDoc.setVolume(this.volumen);
		}
	}

	skipBack() {
		this.WaveSurferDoc?.skip(-5);
	}
	skipForward() {
		this.WaveSurferDoc?.skip(5);
	}
	setPlaybackRateFunction() {
		this.WaveSurferDoc?.pause();
		switch (this.WaveSurferDoc?.getPlaybackRate()) {
			case 0.5:
				this.WaveSurferDoc?.setPlaybackRate(1);
				this.WaveSurferDoc?.play();
				break;
			case 1:
				this.WaveSurferDoc?.setPlaybackRate(1.5);
				this.WaveSurferDoc?.play();
				break;
			case 1.5:
				this.WaveSurferDoc?.setPlaybackRate(2);
				this.WaveSurferDoc?.play();
				break;
			case 2:
				this.WaveSurferDoc?.setPlaybackRate(0.5);
				this.WaveSurferDoc?.play();
				break;
			default:
				this.WaveSurferDoc?.setPlaybackRate(1);
				this.WaveSurferDoc?.play();
				break;
		}
	}
}
