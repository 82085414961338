import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, takeUntil } from 'rxjs';
import { JsEncode } from 'src/app/core/exports/JsEncode';
import { surtechUtils } from 'src/app/core/exports/surtechUtils';
import { UserData } from 'src/app/core/interfaces/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { ApiService } from 'src/app/core/services/api/api.service';
import Swal from 'sweetalert2';
import { AUDIO_TYPES } from '../shared.types';

@Component({
	selector: 'app-view-doc',
	templateUrl: './view-doc.component.html',
	styleUrls: ['./view-doc.component.scss'],
	host: { class: 'flex-1' },
})
export class ViewDocComponent implements OnInit {
	@HostListener('contextmenu', ['$event'])
	onRightClick(event: any) {
		event.preventDefault();
	}
	filePath: any;
	private _unsubscribe = new Subject<void>();
	UserData$?: Observable<any>;
	UserDataFull: UserData = {} as UserData;
	audioTypes: string[] = AUDIO_TYPES;

	file: any = {
		mime: '',
		content: '',
	};

	selectFile: string | null = null;
	loader: boolean = true;

	constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private store: Store, public apiService: ApiService, private router: Router, private toastr: ToastrService, private sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) {
		this.UserData$ = this.store.pipe(select(UserDataFull));
		this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe((data: UserData) => {
			this.UserDataFull = data;
		});

		this.activatedRoute.queryParams.subscribe((params: any) => {
			this.file.name = params.name;
		});

		let filePath = this.activatedRoute.snapshot.paramMap.get('file') || null;
		if (!filePath) return;
		this.loader = true;
		this.toastr.info(surtechUtils.statusMessages('obteniendo-archivo'))
		this.apiService.getfile(filePath).subscribe({
			next: _data => {
				this.loader = false;
				this.toastr.clear();
				this.file.mime = JsEncode.decrypt(_data.mime);
				this.file.fileExtension = JsEncode.decrypt(filePath).split('.').pop();

				// Imagenes
				if (this.file.mime.includes('image')) {
					this.file.content = `data:${this.file.mime};base64,${JsEncode.decrypt(_data.content)}`;
					this.selectFile = 'image';
					return;
				}
				// PDF
				if (this.file.mime.includes('pdf')) {
					const byteArray = new Uint8Array(
						atob(JsEncode.decrypt(_data.content))
							.split('')
							.map(char => char.charCodeAt(0))
					);
					this.file.content = byteArray;
					this.selectFile = 'pdf';
					return;
				}
				// Otros archivos
				if (this.file.mime.includes('octet-stream')) {
					this.file.content = JsEncode.decrypt(_data.content);
					if (!this.audioTypes.includes(this.file.fileExtension)) {
						this.selectFile = 'octet-stream';
						this.download(this.file.name, this.file.content, this.file.mime);
						return;
					}
					this.selectFile = 'audio';
					return;
				}
				// Para cualquier otro mime
				this.file.content = JsEncode.decrypt(_data.content);
				this.download(`${this.file.name}`, this.file.content, this.file.mime);
			},
			error: _err => {
				this.router.navigate(['/dashboard']);
				this.toastr.clear();
				window.scrollTo(0, 0);
				this.loader = false;
			},
		});
	}

	download(fileName, content, mime) {
		Swal.fire({
			...(surtechUtils.alertDefaults as any),
			...{
				title: 'Descarga Iniciada',
				icon: 'info',
				text: surtechUtils.statusMessages('archivo-no-previsualizable'),
			},
		});
		var element = document.createElement('a');
		element.setAttribute('href', `data:${mime};base64,` + content);
		element.setAttribute('download', fileName);

		element.style.display = 'none';
		document.body.appendChild(element);
		element.click();
		document.body.removeChild(element);
		this.router.navigate(['/dashboard']);
		window.scrollTo(0, 0);
	}

	ngOnInit(): void {
		this.renderer.removeClass(this.document.body, 'p-0');
		this.renderer.addClass(this.document.body, 'main');
		this.renderer.addClass(this.document.body, 'px-8');
		this.renderer.addClass(this.document.body, 'py-5');
		window.scrollTo(0, 0);
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}
}
