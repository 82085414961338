<nav class="side-nav">
	<ul>
		<li>
			<a class="side-menu" [routerLink]="'home'" routerLinkActive="side-menu--active">
				<div class="side-menu__icon flex">
					<ng-icon name="feather-home" size="24px"></ng-icon>
				</div>
				<div class="side-menu__title">Inicio</div>
			</a>
		</li>
		<li>
			<a class="side-menu" [routerLink]="'complaints'" routerLinkActive="side-menu--active">
				<div class="side-menu__icon flex">
					<ng-icon name="feather-briefcase" size="24px"></ng-icon>
				</div>
				<div class="side-menu__title">Denuncias</div>
			</a>
		</li>
		
		<li>
			<a class="side-menu" [routerLink]="'schedule'" routerLinkActive="side-menu--active">
				<div class="side-menu__icon flex">
					<ng-icon name="feather-calendar" size="24px"></ng-icon>
				</div>
				<div class="side-menu__title">Agenda</div>
			</a>
		 </li>
		<!--<li>
			<a class="side-menu" [routerLink]="'user'" routerLinkActive="side-menu--active">
				<div class="side-menu__icon flex">
					<ng-icon name="feather-user" size="24px"></ng-icon>
				</div>
				<div class="side-menu__title">Usuarios</div>
			</a>
		</li>
		<li>
			<a class="side-menu" [routerLink]="'groups'" routerLinkActive="side-menu--active">
				<div class="side-menu__icon flex">
					<ng-icon name="feather-users" size="24px"></ng-icon>
				</div>
				<div class="side-menu__title">Grupos</div>
			</a>
		</li> -->
	</ul>
</nav>
