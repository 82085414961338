import { Action } from '@ngrx/store';
import { UsersFilterModel, GroupsFilterModel, FirstContactsFilterModel, FlowchartsFormatsFilterModel } from '../../models/users-filter.model';

export enum FilterActionsTypes {
	UsersFilter = '[Users filter] Users Filter',
	UserFilterClear = '[Users filterClear] Users filterClear',
	GroupsFilter = '[Groups filter] Groups Filter',
	GroupsFilterClear = '[Groups filterClear] Groups filterClear',
	FirstContactsFilter = '[FirstContacts filter] FirstContacts Filter',
	FirstContactsFilterClear = '[FirstContacts filterClear] FirstContacts filterClear',
	FlowchartsFormatsFilter = '[FlowchartsFormats filter] FlowchartsFormats Filter',
	FlowchartsFormatsFilterClear = '[FlowchartsFormats filterClear] FlowchartsFormats filterClear',
	
}
export class UsersFilterClear implements Action {
	readonly type = FilterActionsTypes.UserFilterClear;

	constructor() {}
}
export class UserFilter implements Action {
	readonly type = FilterActionsTypes.UsersFilter;
	constructor(public payload: { UsersFilter: UsersFilterModel }) {}
}

export class GroupsFilterClear implements Action {
	readonly type = FilterActionsTypes.GroupsFilterClear;

	constructor() {}
}
export class GroupsFilter implements Action {
	readonly type = FilterActionsTypes.GroupsFilter;
	constructor(public payload: { GroupsFilter: GroupsFilterModel }) {}
}

export class FirstContactsFilterClear implements Action {
	readonly type = FilterActionsTypes.FirstContactsFilterClear;

	constructor() {}
}
export class FirstContactsFilter implements Action {
	readonly type = FilterActionsTypes.FirstContactsFilter;
	constructor(public payload: { FirstContactsFilter: FirstContactsFilterModel }) {}
	
}

export class FlowchartsFormatsFilterClear implements Action {
	readonly type = FilterActionsTypes.FlowchartsFormatsFilterClear;

	constructor() {}
}
export class FlowchartsFormatsFilter implements Action {
	readonly type = FilterActionsTypes.FlowchartsFormatsFilter;
	constructor(public payload: { FlowchartsFormatsFilter: FlowchartsFormatsFilterModel }) {}
	
}
export type UserActions = UserFilter | UsersFilterClear | GroupsFilterClear | GroupsFilter | FirstContactsFilterClear | FirstContactsFilter | FlowchartsFormatsFilterClear |FlowchartsFormatsFilter;
