import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { UserData } from 'src/app/core/interfaces/user-data';
import { GroupsFilterClear, UsersFilterClear, FirstContactsFilterClear, FlowchartsFormatsFilterClear } from 'src/app/core/reducer/table-filter/table-filter.actions';
import { Logout, UserDataSave } from 'src/app/core/reducer/user-data/user-data.actions';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';

@Component({
	selector: 'app-top-bar',
	templateUrl: './top-bar.component.html',
	styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
	@Input() loggedIn = false;
	@ViewChild('toggleButton') toggleButton!: ElementRef;
	@ViewChild('toggleNotificationButton') toggleNotificationButton!: ElementRef;
	@ViewChild('toggleConfigButton') toggleConfigButton!: ElementRef;
	@ViewChild('menu') menu!: ElementRef;
	@ViewChild('menuNotification') menuNotification!: ElementRef;
	@ViewChild('menuConfig') menuConfig!: ElementRef;

	isMenuOpen = false;
	isNotificationMenuOpen = false;
	isConfigMenuOpen = false;

	UserData$?: Observable<any>;
	private _unsubscribe = new Subject<void>();
	UserDataFull: UserData = {} as UserData;

	constructor(private renderer: Renderer2, private store: Store, private router: Router) {
		//Notifications / Profile Dropdowns functionality
		this.renderer.listen('window', 'click', (e: Event) => {
			//Profile Dropdown
			if (e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement) {
				let isMenu = false;
				if ((e as any).path) {
					(e as any).path.forEach((element: any) => {
						if (element == this.menu.nativeElement) {
							isMenu = true;
							if ((e as any).target.className.includes('dropdown-item')) {
								isMenu = false;
							}
						}
					});
				}

				this.isMenuOpen = isMenu;
			}

			//Notifications Dropdown
			if (e.target !== this.toggleConfigButton?.nativeElement && e.target !== this.menuNotification?.nativeElement) {
				let isMenu = false;
				if ((e as any).path) {
					(e as any).path.forEach((element: any) => {
						if (element == this.menuNotification?.nativeElement) {
							isMenu = true;
							if ((e as any).target.className.includes('notification-item')) {
								isMenu = false;
							}
						}
					});
				}
				this.isNotificationMenuOpen = isMenu;
			}

			if (e.target !== this.toggleConfigButton?.nativeElement && e.target !== this.menuConfig?.nativeElement) {
				let isMenu = false;
				if ((e as any).path) {
					(e as any).path.forEach((element: any) => {
						if (element == this.menuConfig?.nativeElement) {
							isMenu = true;
							if ((e as any).target.className.includes('dropdown-item')) {
								isMenu = false;
							}
						}
					});
				}
				this.isConfigMenuOpen = isMenu;
			}
		});

		this.UserData$ = this.store.pipe(select(UserDataFull));
		this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe(data => {
			this.UserDataFull = data;
		});
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}

	ngOnInit(): void {}

	ResetFilter(value: string) {
		switch (value) {
			case 'Usuarios':
				if (!this.router.url.includes('/dashboard/settings/users/list')) {
					this.store.dispatch(new UsersFilterClear());
				}
				break;
			case 'Grupos':
				if (!this.router.url.includes('/dashboard/settings/groups/list')) {
					this.store.dispatch(new GroupsFilterClear());
				}
				break;
			case 'PrimerContacto':
				if (!this.router.url.includes('/dashboard/settings/first-contact/list')) {
					this.store.dispatch(new FirstContactsFilterClear());
				}
				break;
			case 'FlujogramaFormatos':
				if (!this.router.url.includes('/dashboard/settings/flowcharts-formats/list')) {
					this.store.dispatch(new FlowchartsFormatsFilterClear());
				}
				break;
		}
	}

	logout() {
		localStorage.clear();
		localStorage.clear();
		this.store.dispatch(new Logout());
		this.router.navigateByUrl('/home/login');
	}
}
