<div id="faq-accordion-1" [ngClass]="{ hidden: !(complaintData && type == 'resume') }" class="accordion print-all">
	<div class="accordion-item">
		<div id="faq-accordion-content-1" class="accordion-header">
			<button class="accordion-button show" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-1"
				aria-expanded="true" aria-controls="faq-accordion-collapse-1">Denuncia</button>
		</div>
		<div id="faq-accordion-collapse-1" class="accordion-collapse show" aria-labelledby="faq-accordion-content-1"
			data-tw-parent="#faq-accordion-1">
			<div id="print-1" class="grid grid-cols-12 mt-5 p-0">
				<div class="sur-bg-light col-span-12 sur-border">
					<b>Datos de la Denuncia</b>
				</div>
				<div class="grid grid-cols-12 col-span-12">
					<div class="col-span-12 md:col-span-6 sur-border">
						<b>Folio:</b>
						<br />
						<span>{{ complaintData.code ? complaintData.code : 'Se asignará una vez generada la denuncia' }}</span>
					</div>
					<div class="col-span-12 md:col-span-6 sur-border">
						<b>Fecha:</b>
						<br />
						<span>{{ complaintData.date ? formatDate(complaintData.date) : (nowDate | date: 'dd-MM-YYYY') }}</span>
					</div>
					<div class="col-span-12 md:col-span-6 sur-border">
						<b>Estado:</b>
						<br />
						<span>{{ complaintData.state ? getListString(complaintData.state, lists.statesList) :
							complaintData.whistleblower.state ? getListString(complaintData.whistleblower.state, lists.statesList) :
							'---' }}</span>
					</div>
					<div class="col-span-12 md:col-span-6 sur-border">
						<b>Municipio:</b>
						<br />
						<span>{{ complaintData.municipality ? getListString(complaintData.municipality, lists.municipalityList) :
							complaintData.whistleblower.municipality ? getListString(complaintData.whistleblower.municipality,
							lists.municipalityList) : '---' }}</span>
					</div>
					<div class="col-span-12 sur-border">
						<b>Existencia de Víctimas o Actos de Violencia:</b>
						<br />
						<span>{{ complaintData.haveVictims ? 'Sí' : 'No' }}</span>
					</div>
					<div class="col-span-12 sur-border">
						<b>Descripción de los Hechos:</b>
						<br />
						<span>{{ complaintData.acts ? complaintData.acts : '---' }}</span>
					</div>
					<div class="col-span-12 sur-border">
						<b>Observaciones:</b>
						<br />
						<span>{{ complaintData.observations ? complaintData.observations : '---' }}</span>
					</div>
					<div class="col-span-12 md:col-span-6 sur-border">
						<b>Medidas Cautelares Solicitadas:</b>
						<br />
						<ul>
							<li *ngFor="let measure of complaintData.measures; let i = index">
								<span>- {{ getListString(measure.id, lists.measuresList) | ucFirst }}</span>
							</li>
						</ul>
					</div>
					<div class="col-span-12 md:col-span-6 sur-border">
						<b>Justificación de las Medidas Cautelares Solicitadas:</b>
						<br />
						<span>{{ complaintData.measuresJustification ? complaintData.measuresJustification : '---' }}</span>
					</div>
					<div class="col-span-12 md:col-span-6 sur-border">
						<b>Pruebas Presentadas:</b>
						<br />
						<ul>
							<li *ngFor="let evidence of complaintData.evidencesTypes; let i = index">
								<span>- {{ evidence.text }}</span>
							</li>
						</ul>
					</div>
					<div class="grid grid-cols-12 col-span-12 md:col-span-6 p-0">
						<div class="col-span-12 sm:col-span-4 sur-bg-light sur-border">
							<b class="flex items-center">Nombre de la Prueba</b>
						</div>
						<div class="col-span-4 hidden sm:flex sur-bg-light sur-border">
							<b class="flex items-center">Tipo</b>
						</div>
						<div class="col-span-4 hidden sm:flex sur-bg-light sur-border">
							<b class="flex items-center">Peso</b>
						</div>
						<div *ngIf="!complaintData.evidencesNames" class="col-span-12 sur-border">
							<span>No se cargaron medios de prueba</span>
						</div>
						<div *ngIf="complaintData.evidencesNames" class="col-span-12">
							<div *ngFor="let evidence of complaintData.evidencesNames; let i = index" class="col-span-12 grid grid-cols-12">
								<div class="col-span-12 sm:col-span-4 sur-border">
									<div *ngIf="evidence.path; then linkEvidence; else textEvidence">here is ignored</div>
									<ng-template #linkEvidence>
										<a target="_blank" class="text-primary flex" [href]="'/view/' + evidence.path + '?name=' + evidence.name">{{
											'Prueba ' + (i + 1) }} <ng-icon class="ml-2 flex flex-auto justify-end"
												name="feather-external-link" size="1.25rem" style="display: flex"></ng-icon></a>
									</ng-template>
									<ng-template #textEvidence>
										<span>{{ evidence.name }}</span>
									</ng-template>
								</div>
								<div class="col-span-4 hidden sm:flex sur-border">
									<span>{{ getCleanFileType(evidence.name) }}</span>
								</div>
								<div class="col-span-4 hidden sm:flex sur-border">
									<span>{{ getReadableFileSize(evidence.size, evidence.transform != undefined ? evidence.transform
										: true) }}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-2" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-2"
				aria-expanded="false" aria-controls="faq-accordion-collapse-2">Denunciante</button>
		</div>
		<div id="faq-accordion-collapse-2" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-2"
			data-tw-parent="#faq-accordion-1">
			<div id="print-2" class="grid grid-cols-12 table-bordered mt-5">
				<div class="sur-bg-light col-span-12 sur-border">
					<b>Datos de la Persona Denunciante</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ complaintData.whistleblower.names | titleCase}} {{ complaintData.whistleblower.lastNames | titleCase}}</span>
				</div>
				<ng-container *ngIf="!hideSensibleData">
					<div *ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Fecha de Nacimiento:</b>
							<br />
							<span>{{ complaintData.whistleblower.birthday ? (complaintData.whistleblower.birthday | mask: '00-00-0000')
								:
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Género:</b>
							<br />
							<span>{{ complaintData.whistleblower.gender ? getListString(complaintData.whistleblower.gender,
								lists.genderList) : '---' }}</span>
						</div>
					</div>
					<div *ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Teléfono:</b>
							<br />
							<span>{{ complaintData.whistleblower.phone ? (complaintData.whistleblower.phone | mask: '(00) 0000 0000') :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ complaintData.whistleblower.email ? complaintData.whistleblower.email : '---' }}</span>
						</div>
					</div>
					<div *ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Pertenece a un Grupo de Vulnerabilidad:</b>
							<br />
							<span>{{ complaintData.whistleblower.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Se Encuentra Fuera de México:</b>
							<br />
							<span>{{ complaintData.whistleblower.outMexico ? 'Sí' : 'No' }}</span>
						</div>
					</div>
					<div
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && complaintData.whistleblower.outMexico"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sur-border">
							<b>Localidad Actual:</b>
							<br />
							<span>{{ complaintData.whistleblower.location ? complaintData.whistleblower.location : '---' }}</span>
						</div>
					</div>
					<div
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ complaintData.whistleblower.state ? getListString(complaintData.whistleblower.state,
								lists.statesList)
								: '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ complaintData.whistleblower.municipality ? getListString(complaintData.whistleblower.municipality,
								lists.municipalityList) : '---' }}</span>
						</div>
					</div>
					<div
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ complaintData.whistleblower.colony ? (complaintData.whistleblower.colony | titlecase) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ complaintData.whistleblower.zipcode ? complaintData.whistleblower.zipcode : '---' }}</span>
						</div>
					</div>
					<div
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sur-border">
							<b>Calle:</b>
							<br />
							<span>{{ (complaintData.whistleblower.street ? complaintData.whistleblower.street : '---') | titlecase }}</span>
						</div>
					</div>
					<div
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ complaintData.whistleblower.extstreet ? complaintData.whistleblower.extstreet : '---'
								}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ complaintData.whistleblower.instreet ? complaintData.whistleblower.instreet : '---'
								}}</span>
						</div>
					</div>
					<div *ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>El Denunciante Forma Parte Del:</b>
							<br />
							<span>{{ complaintData.whistleblower.participantRole ?
								getListString(complaintData.whistleblower.participantRole, lists.participantRoleList) :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Otro Tipo de Personal:</b>
							<br />
							<span>{{ complaintData.whistleblower.participantRoleOthers ?
								complaintData.whistleblower.participantRoleOthers :
								'---' }}</span>
						</div>
					</div>
					<div *ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Instancia de Adscripción/Inscripción:</b>
							<br />
							<span>{{ complaintData.whistleblower.instance ?
								getListString(complaintData.whistleblower.instance,
								lists.instanceList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Escuela/Dependencia:</b>
							<br />
							<span>{{ complaintData.whistleblower.entity ?
								getListString(complaintData.whistleblower.entity,
								lists.entityList) : '---' }}</span>
						</div>
					</div>
					<div
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email"
						class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sur-border">
							<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
							<br />
							<span>{{ complaintData.whistleblower.characteristics ?
								complaintData.whistleblower.characteristics : '---'
								}}</span>
						</div>
					</div>
				</ng-container>

			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-3" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-3"
				aria-expanded="false" aria-controls="faq-accordion-collapse-3">{{
				complaintData.victims?.length == 0 ||
				complaintData.victims == null ? 'Sin victimas' : 'Víctima(s)' }}</button>
		</div>
		<div id="faq-accordion-collapse-3" class="accordion-collapse collapse"
			aria-labelledby="faq-accordion-content-3"
			data-tw-parent="#faq-accordion-1">
			<div id="print-3" class="grid grid-cols-12 table-bordered mt-5"
				*ngFor="let participant of complaintData.victims; let i = index">
				<div class="sur-bg-light col-span-12 sur-border">
					<b>Datos de la Víctima #{{ i + 1 }}</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names | titlecase }} {{ participant.lastNames | titlecase }}</span>
				</div>
				<ng-container *ngIf="!hideSensibleData">
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Fecha de Nacimiento:</b>
							<br />
							<span>{{ participant.birthday ? (participant.birthday | mask: '00-00-0000') :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Género:</b>
							<br />
							<span>{{ participant.gender ? getListString(participant.gender,
								lists.genderList) : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Teléfono:</b>
							<br />
							<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ participant.email ? participant.email : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Pertenece a un Grupo de Vulnerabilidad:</b>
							<br />
							<span>{{ participant.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Se Encuentra Fuera de México:</b>
							<br />
							<span>{{ participant.outMexico ? 'Sí' : 'No' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && participant.outMexico">
						<b>Localidad Actual:</b>
						<br />
						<span>{{ participant.location ? participant.location : '---' }}</span>
					</div>
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ participant.state ? getListString(participant.state, lists.statesList)
								: '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ participant.municipality ? getListString(participant.municipality,
								lists.municipalityList) :
								'---'
								}}</span>
						</div>
					</div>
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ (participant.colony ? participant.colony : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ participant.zipcode ? participant.zipcode : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
						<b>Calle:</b>
						<br />
						<span>{{ (participant.street ? participant.street : '---') | titlecase }}</span>
					</div>
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ participant.extstreet ? participant.extstreet : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ participant.instreet ? participant.instreet : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>La Víctima Forma Parte Del:</b>
							<br />
							<span>{{ participant.participantRole ?
								getListString(participant.participantRole,
								lists.participantRoleList)
								:
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Otro Tipo de Personal:</b>
							<br />
							<span>{{ participant.participantRoleOthers ? participant.participantRoleOthers :
								'---' }}</span>
						</div>
					</div>
					<div class="col-span-12 grid grid-cols-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Instancia de Adscripción/Inscripción:</b>
							<br />
							<span>{{ participant.instance ? getListString(participant.instance,
								lists.instanceList) : '---'
								}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Escuela/Dependencia:</b>
							<br />
							<span>{{ participant.entity ? getListString(participant.entity,
								lists.entityList) : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
						<br />
						<span>{{ participant.characteristics ? participant.characteristics : '---'
							}}</span>
					</div>
				</ng-container>

				<ng-container *ngFor="let parent of participant.parents; let j = index">
					<div class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sur-border sur-bg-light">
							<b>
								Tutor #{{ j + 1 }} de la Víctima #{{ i + 1 }}
							</b>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Nombre:</b>
							<br />
							<span>
								{{
								parent.names && parent.lastNames
								? (parent.names + ' ' + parent.lastNames | titlecase)
								: parent.names
								? (parent.names | titlecase)
								: parent.lastNames
								? (parent.lastNames | titlecase)
								: '---'
								}}
							</span>
						</div>
						<ng-container *ngIf="!hideSensibleData">
							<div class="col-span-12 sm:col-span-6 sur-border"
								*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
								<b>Correo Electrónico:</b>
								<br />
								<span>{{ parent.email ? parent.email : '---' }}</span>
							</div>
							<div class="col-span-12 grid grid-cols-12"
								*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
								<div class="col-span-12 sm:col-span-6 sur-border">
									<b>Estado:</b>
									<br />
									<span>{{ parent.state ? getListString(parent.state, lists.statesList) :
										'---' }}</span>
								</div>
								<div class="col-span-12 sm:col-span-6 sur-border">
									<b>Municipio:</b>
									<br />
									<span>{{ parent.municipality ? getListString(parent.municipality,
										lists.municipalityList) : '---'
										}}</span>
								</div>
							</div>
							<div class="col-span-12 grid grid-cols-12"
								*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
								<div class="col-span-12 sm:col-span-6 sur-border">
									<b>Colonia:</b>
									<br />
									<span>{{ (parent.colony ? parent.colony : '---') | titlecase }}</span>
								</div>
								<div class="col-span-12 sm:col-span-6 sur-border">
									<b>Código Postal:</b>
									<br />
									<span>{{ parent.zipcode ? parent.zipcode : '---' }}</span>
								</div>
							</div>
							<div class="col-span-12 sur-border"
								*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
								<b>Calle:</b>
								<br />
								<span>{{ (parent.street ? parent.street : '---') | titlecase }}</span>
							</div>
							<div class="col-span-12 grid grid-cols-12"
								*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
								<div class="col-span-12 sm:col-span-6 sur-border">
									<b>No. Exterior:</b>
									<br />
									<span>{{ parent.extstreet ? parent.extstreet : '---' }}</span>
								</div>
								<div class="col-span-12 sm:col-span-6 sur-border">
									<b>No. Interior:</b>
									<br />
									<span>{{ parent.instreet ? parent.instreet : '---' }}</span>
								</div>
							</div>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-4" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-4"
				aria-expanded="false" aria-controls="faq-accordion-collapse-4">Persona(s) Señalada(s) como Presunto(s)
				Responsables</button>
		</div>
		<div id="faq-accordion-collapse-4" class="accordion-collapse collapse"
			aria-labelledby="faq-accordion-content-4" data-tw-parent="#faq-accordion-1">
			<div id="print-4" *ngFor="let participant of complaintData.responsibles; let i = index"
				class="grid grid-cols-12 table-bordered mt-5">
				<div class="col-span-12 sur-border sur-bg-light">
					<b>Datos de la Persona Señalada como Presunto Responsable #{{ i + 1 }}</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names | titlecase }} {{ participant.lastNames | titlecase }}</span>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Fecha de Nacimiento:</b>
						<br />
						<span>{{ participant.birthday ? (participant.birthday | mask: '00-00-0000') :
							'---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Género:</b>
						<br />
						<span>{{ participant.gender ? getListString(participant.gender,
							lists.genderList) : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Teléfono:</b>
						<br />
						<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') :
							'---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Correo Electrónico:</b>
						<br />
						<span>{{ participant.email ? participant.email : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Pertenece a un Grupo de Vulnerabilidad:</b>
						<br />
						<span>{{ participant.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Se Encuentra Fuera de México:</b>
						<br />
						<span>{{ participant.outMexico ? 'Sí' : 'No' }}</span>
					</div>
				</div>
				<div class="col-span-12 sur-border"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && participant.outMexico">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ participant.location ? participant.location : '---' }}</span>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Estado:</b>
						<br />
						<span>{{ participant.state ? getListString(participant.state, lists.statesList)
							: '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Municipio:</b>
						<br />
						<span>{{ participant.municipality ? getListString(participant.municipality,
							lists.municipalityList) :
							'---'
							}}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Colonia:</b>
						<br />
						<span>{{ (participant.colony ? participant.colony : '---' ) | titlecase}}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Código Postal:</b>
						<br />
						<span>{{ participant.zipcode ? participant.zipcode : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 sur-border"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
					<b>Calle:</b>
					<br />
					<span>{{ (participant.street ? participant.street : '---') | titlecase }}</span>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !participant.outMexico">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Exterior:</b>
						<br />
						<span>{{ participant.extstreet ? participant.extstreet : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Interior:</b>
						<br />
						<span>{{ participant.instreet ? participant.instreet : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>La Persona Señalada como Responable Forma Parte Del:</b>
						<br />
						<span>{{ participant.participantRole ?
							getListString(participant.participantRole,
							lists.participantRoleList) : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Otro Tipo de Personal:</b>
						<br />
						<span>{{ participant.participantRoleOthers ?
							participant.participantRoleOthers :
							'---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Instancia de Adscripción/Inscripción:</b>
						<br />
						<span>{{ participant.instance ?
							getListString(participant.instance,
							lists.instanceList) : '---'
							}}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Escuela/Dependencia:</b>
						<br />
						<span>{{ participant.entity ? getListString(participant.entity,
							lists.entityList) : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 sur-border"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
					<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
					<br />
					<span>{{ participant.characteristics ?
						participant.characteristics :
						'---'
						}}</span>
				</div>
				<ng-container *ngFor="let parent of participant.parents; let j = index">
					<div class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sur-border sur-bg-light">
							<b>
								Tutor #{{ j + 1 }} del la Persona Señalada como Presunto Responsable #{{
								i + 1 }}
							</b>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border ">
							<b>Nombre:</b>
							<br />
							<span>
								{{
								parent.names && parent.lastNames
								? (parent.names + ' ' + parent.lastNames | titlecase)
								: parent.names
								? (parent.names | titlecase)
								: parent.lastNames
								? (parent.lastNames | titlecase)
								: '---'
								}}
							</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border "
							*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ parent.email ? parent.email : '---' }}</span>
						</div>
						<div class="col-span-12 grid grid-cols-12"
							*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
							<div class="col-span-12 sm:col-span-6 sur-border">
								<b>Estado:</b>
								<br />
								<span>{{ parent.state ? getListString(parent.state, lists.statesList) :
									'---' }}</span>
							</div>
							<div class="col-span-12 sm:col-span-6 sur-border">
								<b>Municipio:</b>
								<br />
								<span>{{ parent.municipality ? getListString(parent.municipality,
									lists.municipalityList) : '---'
									}}</span>
							</div>
						</div>
						<div class="col-span-12 grid grid-cols-12"
							*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
							<div class="col-span-12 sm:col-span-6 sur-border">
								<b>Colonia:</b>
								<br />
								<span>{{ (parent.colony ? parent.colony : '---') | titlecase }}</span>
							</div>
							<div class="col-span-12 sm:col-span-6 sur-border">
								<b>Código Postal:</b>
								<br />
								<span>{{ parent.zipcode ? parent.zipcode : '---' }}</span>
							</div>
						</div>
						<div class="col-span-12 sur-border"
							*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
							<b>Calle:</b>
							<br />
							<span>{{ (parent.street ? parent.street : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 grid grid-cols-12"
							*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
							<div class="col-span-12 sm:col-span-6 sur-border">
								<b>No. Exterior:</b>
								<br />
								<span>{{ parent.extstreet ? parent.extstreet : '---' }}</span>
							</div>
							<div class="col-span-12 sm:col-span-6 sur-border">
								<b>No. Interior:</b>
								<br />
								<span>{{ parent.instreet ? parent.instreet : '---' }}</span>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-5" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-5"
				aria-expanded="false" aria-controls="faq-accordion-collapse-5">{{
				complaintData.witnesses?.length == 0 ||
				complaintData.witnesses == null ? 'Sin testigo' : 'Testigo(s)' }}</button>
		</div>
		<div id="faq-accordion-collapse-5" class="accordion-collapse collapse"
			aria-labelledby="faq-accordion-content-5" data-tw-parent="#faq-accordion-1">
			<div id="print-5" *ngFor="let participant of complaintData.witnesses; let i = index"
				class="grid grid-cols-12 col-span-12 mt-5">
				<div class="col-span-12 sur-bg-light sur-border">
					<b>Datos de la o el Testigo #{{ i + 1 }}</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names | titlecase }} {{ participant.lastNames | titlecase }}</span>
				</div>
				<ng-container *ngIf="!hideSensibleData">
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Teléfono:</b>
							<br />
							<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ participant.email ? participant.email : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Instancia de Adscripción/Inscripción:</b>
							<br />
							<span>{{ participant.instance ? getListString(participant.instance,
								lists.instanceList) : '---'
								}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Escuela/Dependencia:</b>
							<br />
							<span>{{ participant.entity ? getListString(participant.entity,
								lists.entityList) : '---' }}</span>
						</div>
					</div>
					<div class="sur-border col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
						<br />
						<span>{{ participant.characteristics ? (participant.characteristics | ucFirst) :
							'---' }}</span>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-2" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-2"
				aria-expanded="false" aria-controls="faq-accordion-collapse-2">Primer Contacto</button>
		</div>
		<div id="faq-accordion-collapse-2" class="accordion-collapse collapse"
			aria-labelledby="faq-accordion-content-2"
			data-tw-parent="#faq-accordion-1">
			<div id="print-2" class="grid grid-cols-12 col-span-12 mt-5">
				<div class="col-span-12 sur-border sur-bg-light">
					<b>Datos del Primer Contacto</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ complaintData.firtContact?.names | titlecase }} {{
						complaintData.firtContact?.lastNames | titlecase }}</span>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Fecha de Nacimiento:</b>
						<br />
						<span>{{ complaintData.firtContact?.birthday ?
							(complaintData.firtContact?.birthday | mask: '00-00-0000')
							:
							'---'
							}}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Género:</b>
						<br />
						<span>{{ complaintData.firtContact?.gender ?
							getListString(complaintData.firtContact?.gender,
							lists.genderList) :
							'---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Teléfono:</b>
						<br />
						<span>{{ complaintData.firtContact?.phone ? (complaintData.firtContact?.phone |
							mask: '(00) 0000 0000') :
							'---'
							}}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Correo Electrónico:</b>
						<br />
						<span>{{ complaintData.firtContact?.email ? complaintData.firtContact?.email :
							'---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Pertenece a un Grupo de Vulnerabilidad:</b>
						<br />
						<span>{{ complaintData.firtContact?.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Se Encuentra Fuera de México:</b>
						<br />
						<span>{{ complaintData.firtContact?.outMexico ? 'Sí' : 'No' }}</span>
					</div>
				</div>
				<div class="col-span-12 sur-border"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email && complaintData.firtContact?.outMexico">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ complaintData.firtContact?.location ? complaintData.firtContact?.location
						: '---' }}</span>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email && !complaintData.firtContact?.outMexico">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Estado:</b>
						<br />
						<span>{{ complaintData.firtContact?.state ?
							getListString(complaintData.firtContact?.state,
							lists.statesList)
							:
							'---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Municipio:</b>
						<br />
						<span>{{ complaintData.firtContact?.municipality ?
							getListString(complaintData.firtContact?.municipality,
							lists.municipalityList) : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email && !complaintData.firtContact?.outMexico">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Colonia:</b>
						<br />
						<span>{{ (complaintData.firtContact?.colony ? complaintData.firtContact?.colony :
							'---') | titlecase }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Código Postal:</b>
						<br />
						<span>{{ complaintData.firtContact?.zipcode ? complaintData.firtContact?.zipcode :
							'---' }}</span>
					</div>
				</div>
				<div class="col-span-12 sur-border"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email && !complaintData.firtContact?.outMexico">
					<b>Calle:</b>
					<br />
					<span>{{ (complaintData.firtContact?.street ? complaintData.firtContact?.street :
						'---') | titlecase }}</span>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email && !complaintData.firtContact?.outMexico">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Exterior:</b>
						<br />
						<span>{{ complaintData.firtContact?.extstreet ?
							complaintData.firtContact?.extstreet : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Interior:</b>
						<br />
						<span>{{ complaintData.firtContact?.instreet ? complaintData.firtContact?.instreet
							: '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>El Denunciante Forma Parte Del:</b>
						<br />
						<span>{{ complaintData.firtContact?.participantRole ?
							getListString(complaintData.firtContact?.participantRole,
							lists.participantRoleList) : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Otro Tipo de Personal:</b>
						<br />
						<span>{{ complaintData.firtContact?.participantRoleOthers ?
							complaintData.firtContact?.participantRoleOthers :
							'---' }}</span>
					</div>
				</div>
				<div class="col-span-12 grid grid-cols-12"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Instancia de Adscripción/Inscripción:</b>
						<br />
						<span>{{ complaintData.firtContact?.instance ?
							getListString(complaintData.firtContact?.instance,
							lists.instanceList) : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Escuela/Dependencia:</b>
						<br />
						<span>{{ complaintData.firtContact?.entity ?
							getListString(complaintData.firtContact?.entity,
							lists.entityList) :
							'---' }}</span>
					</div>
				</div>
				<div class="col-span-12 sur-border"
					*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.firtContact?.email">
					<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
					<br />
					<span>{{ complaintData.firtContact?.characteristics ?
						complaintData.firtContact?.characteristics : '---'
						}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
<div id="faq-accordion-1" [ngClass]="{ hidden: !(complaintData && type == 'resumen-create') }"
	class="accordion mt-5 print-all">
	<div class="accordion-item">
		<div id="faq-accordion-content-1" class="accordion-header">
			<button class="accordion-button" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-1"
				aria-expanded="true" aria-controls="faq-accordion-collapse-1">Denuncia</button>
		</div>
		<div id="faq-accordion-collapse-1" class="accordion-collapse collapse show"
			aria-labelledby="faq-accordion-content-1"
			data-tw-parent="#faq-accordion-1">
			<div id="print-1" class="grid grid-cols-12 col-span-12 mt-5">
				<div class="col-span-12 sur-border sur-bg-light">
					<b>Datos de la Denuncia</b>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Folio:</b>
					<br />
					<span>{{ complaintData.code ? complaintData.code : 'Se asignará una vez generada la denuncia' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Fecha:</b>
					<br />
					<span>{{ complaintData.date ? formatDate(complaintData.date) : (nowDate | date:
						'dd-MM-YYYY') }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Estado:</b>
					<br />
					<span>{{ complaintData.state ? getListString(complaintData.state,
						lists.statesList) :
						complaintData.whistleblower.state ?
						getListString(complaintData.whistleblower.state,
						lists.statesList) :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Municipio:</b>
					<br />
					<span>{{ complaintData.municipality ? getListString(complaintData.municipality,
						lists.municipalityList)
						:
						complaintData.whistleblower.municipality ?
						getListString(complaintData.whistleblower.municipality,
						lists.municipalityList) : '---' }}</span>
				</div>
				<div class="col-span-12 sur-border">
					<b>Existencia de Víctimas o Actos de Violencia:</b>
					<br />
					<span>{{ complaintData.haveVictims ? 'Sí' : 'No' }}</span>
				</div>
				<div class="col-span-12 sur-border">
					<b>Descripción de los Hechos:</b>
					<br />
					<span>{{ complaintData.acts ? complaintData.acts : '---' }}</span>
				</div>
				<div class="col-span-12 sur-border">
					<b>Observaciones:</b>
					<br />
					<span>{{ complaintData.observations ? complaintData.observations : '---'
						}}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Medidas Cautelares Solicitadas:</b>
					<br />
					<ul>
						<li *ngFor="let measure of complaintData.measures; let i = index">
							<span>- {{ getListString(measure.id, lists.measuresList) | ucFirst
								}}</span>
						</li>
					</ul>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Justificación de las Medidas Cautelares Solicitadas:</b>
					<br />
					<span>{{ complaintData.measuresJustification ?
						complaintData.measuresJustification : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Pruebas Presentadas:</b>
					<br />
					<ul>
						<li *ngFor="let evidence of complaintData.evidencesTypes; let i = index">
							<span>- {{ evidence.text }}</span>
						</li>
					</ul>
				</div>
				<div class="grid grid-cols-12 col-span-12 md:col-span-6 p-0">
					<div class="col-span-12 sm:col-span-4 sur-bg-light sur-border">
						<b>Nombre de la Prueba</b>
					</div>
					<div class="col-span-4 hidden sm:flex sur-bg-light sur-border">
						<b class="flex items-center">Tipo</b>
					</div>
					<div class="col-span-4 hidden sm:flex sur-bg-light sur-border">
						<b class="flex items-center">Peso</b>
					</div>
					<div *ngIf="!complaintData.evidencesNames" class="col-span-12 sur-border">
						<span>No se cargaron medios de prueba</span>
					</div>
					<div *ngIf="complaintData.evidencesNames" class="col-span-12">
						<div *ngFor="let evidence of complaintData.evidencesNames; let i = index" class="col-span-12 grid grid-cols-12">
							<div class="col-span-12 sm:col-span-4 sur-border">
								<div *ngIf="evidence.path; then linkEvidence; else textEvidence">here is ignored</div>
								<ng-template #linkEvidence>
									<span>
										<a target="_blank" class="text-primary flex" [href]="'/view/' + evidence.path + '?name=' + evidence.name">{{
											'Prueba ' + (i + 1) }} <ng-icon class="ml-2 flex flex-auto justify-end"
												name="feather-external-link" size="1.25rem" style="display: flex"></ng-icon></a>
									</span>
								</ng-template>
								<ng-template #textEvidence>
									<span>{{ evidence.name }}</span>
								</ng-template>
							</div>
							<div class="col-span-4 hidden sm:flex sur-border">
								<span>{{ getCleanFileType(evidence.name) }}</span>
							</div>
							<div class="col-span-4 hidden sm:flex sur-border">
								<span>{{ getReadableFileSize(evidence.size, evidence.transform != undefined ? evidence.transform
									: true) }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-2" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-2"
				aria-expanded="false" aria-controls="faq-accordion-collapse-2">Denunciante</button>
		</div>
		<div id="faq-accordion-collapse-2" class="accordion-collapse collapse"
			aria-labelledby="faq-accordion-content-2" data-tw-parent="#faq-accordion-1">
			<div id="print-2" class="grid grid-cols-12 table-bordered mt-5">
				<div class="sur-bg-light col-span-12 sur-border">
					<b>Datos de la Persona Denunciante</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ complaintData.whistleblower.names | titleCase }} {{ complaintData.whistleblower.lastNames | titleCase }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Fecha de Nacimiento:</b>
					<br />
					<span>{{ complaintData.whistleblower.birthday ? (complaintData.whistleblower.birthday | mask: '00-00-0000') : '---'
						}}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Género:</b>
					<br />
					<span>{{ complaintData.whistleblower.gender ?
						getListString(complaintData.whistleblower.gender,
						lists.genderList) : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Teléfono:</b>
					<br />
					<span>{{ complaintData.whistleblower.phone ? (complaintData.whistleblower.phone | mask: '(00) 0000 0000') :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ complaintData.whistleblower.email ? complaintData.whistleblower.email :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Pertenece a un Grupo de Vulnerabilidad:</b>
					<br />
					<span>{{ complaintData.whistleblower.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Se Encuentra Fuera de México:</b>
					<br />
					<span>{{ complaintData.whistleblower.outMexico ? 'Sí' : 'No' }}</span>
				</div>
				<div *ngIf="complaintData.whistleblower.outMexico" class="col-span-12 sur-border">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ complaintData.whistleblower.location ?
						complaintData.whistleblower.location : '---' }}</span>
				</div>
				<div *ngIf="!complaintData.whistleblower.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Estado:</b>
						<br />
						<span>{{ complaintData.whistleblower.state ?
							getListString(complaintData.whistleblower.state,
							lists.statesList)
							: '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Municipio:</b>
						<br />
						<span>{{ complaintData.whistleblower.municipality ?
							getListString(complaintData.whistleblower.municipality,
							lists.municipalityList) : '---' }}</span>
					</div>
				</div>
				<div *ngIf="!complaintData.whistleblower.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Colonia:</b>
						<br />
						<span>{{ (complaintData.whistleblower.colony ? complaintData.whistleblower.colony
							: '---' ) | titlecase}}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Código Postal:</b>
						<br />
						<span>{{ complaintData.whistleblower.zipcode ?
							complaintData.whistleblower.zipcode : '---' }}</span>
					</div>
				</div>
				<div *ngIf="!complaintData.whistleblower.outMexico" class="col-span-12 sur-border">
					<b>Calle:</b>
					<br />
					<span>{{ (complaintData.whistleblower.street ? complaintData.whistleblower.street
						: '---') | titlecase }}</span>
				</div>
				<div *ngIf="!complaintData.whistleblower.outMexico" class="grid grid-cols-12 col-span-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Exterior:</b>
						<br />
						<span>{{ complaintData.whistleblower.extstreet ?
							complaintData.whistleblower.extstreet : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Interior:</b>
						<br />
						<span>{{ complaintData.whistleblower.instreet ?
							complaintData.whistleblower.instreet : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>El Denunciante Forma Parte Del:</b>
					<br />
					<span>{{ complaintData.whistleblower.participantRole ?
						getListString(complaintData.whistleblower.participantRole,
						lists.participantRoleList) : '---'
						}}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Otro Tipo de Personal:</b>
					<br />
					<span>{{ complaintData.whistleblower.participantRoleOthers ?
						complaintData.whistleblower.participantRoleOthers :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ complaintData.whistleblower.instance ?
						getListString(complaintData.whistleblower.instance,
						lists.instanceList) : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ complaintData.whistleblower.entity ? getListString(complaintData.whistleblower.entity, lists.entityList) :
						'---' }}</span>
				</div>
				<div class="col-span-12 sur-border">
					<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
					<br />
					<span>{{ complaintData.whistleblower.characteristics ? complaintData.whistleblower.characteristics :'---'}}</span>
				</div>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-3" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-3"
				aria-expanded="false" aria-controls="faq-accordion-collapse-3">{{complaintData.victims?.length == 0 || complaintData.victims
				== null ? 'Sin victimas' : 'Víctima(s)' }}</button>
		</div>
		<div id="faq-accordion-collapse-3" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-3"
			data-tw-parent="#faq-accordion-1">
			<div id="print-3" class="grid grid-cols-12 table-bordered mt-5"
				*ngFor="let participant of complaintData.victims; let i = index">
				<div class="sur-bg-light col-span-12 sur-border">
					<b>Datos de la Víctima #{{ i + 1 }}</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names | titlecase }} {{ participant.lastNames | titlecase }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Fecha de Nacimiento:</b>
					<br />
					<span>{{ participant.birthday ? (participant.birthday | mask: '00-00-0000') :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Género:</b>
					<br />
					<span>{{ participant.gender ? getListString(participant.gender,
						lists.genderList) : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Teléfono:</b>
					<br />
					<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ participant.email ? participant.email : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Pertenece a un Grupo de Vulnerabilidad:</b>
					<br />
					<span>{{ participant.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Se Encuentra Fuera de México:</b>
					<br />
					<span>{{ participant.outMexico ? 'Sí' : 'No' }}</span>
				</div>
				<div *ngIf="participant.outMexico" class="col-span-12 sur-border">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ participant.location ? participant.location : '---' }}</span>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Estado:</b>
						<br />
						<span>{{ participant.state ? getListString(participant.state, lists.statesList)
							: '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Municipio:</b>
						<br />
						<span>{{ participant.municipality ? getListString(participant.municipality,
							lists.municipalityList) :
							'---'
							}}</span>
					</div>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Colonia:</b>
						<br />
						<span>{{ (participant.colony ? participant.colony : '---') | titlecase }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Código Postal:</b>
						<br />
						<span>{{ participant.zipcode ? participant.zipcode : '---' }}</span>
					</div>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 sur-border">
					<b>Calle:</b>
					<br />
					<span>{{ (participant.street ? participant.street : '---') | titlecase }}</span>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Exterior:</b>
						<br />
						<span>{{ participant.extstreet ? participant.extstreet : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Interior:</b>
						<br />
						<span>{{ participant.instreet ? participant.instreet : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>La Víctima Forma Parte Del:</b>
					<br />
					<span>{{ participant.participantRole ? getListString(participant.participantRole, lists.participantRoleList) : '---'
						}}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Otro Tipo de Personal:</b>
					<br />
					<span>{{ participant.participantRoleOthers ? participant.participantRoleOthers :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ participant.instance ? getListString(participant.instance,
						lists.instanceList) : '---'
						}}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ participant.entity ? getListString(participant.entity,
						lists.entityList) : '---' }}</span>
				</div>
				<div class="col-span-12 sur-border">
					<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
					<br />
					<span>{{ participant.characteristics ? participant.characteristics : '---' }}</span>
				</div>
				<ng-container *ngFor="let parent of participant.parents; let j = index">
					<div class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sur-border sur-bg-light">
							<b>
								Tutor #{{ j + 1 }} de la Víctima #{{ i + 1 }}
							</b>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Nombre:</b>
							<br />
							<span> {{
								parent.names && parent.lastNames
								? (parent.names + ' ' + parent.lastNames | titlecase)
								: parent.names
								? (parent.names | titlecase)
								: parent.lastNames
								? (parent.lastNames | titlecase)
								: '---'
								}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ parent.email ? parent.email : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ parent.state ? getListString(parent.state, lists.statesList) :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ parent.municipality ? getListString(parent.municipality,
								lists.municipalityList) : '---'
								}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ (parent.colony ? parent.colony : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ parent.zipcode ? parent.zipcode : '---' }}</span>
						</div>
						<div class="col-span-12 sur-border">
							<b>Calle:</b>
							<br />
							<span>{{ (parent.street ? parent.street : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ parent.extstreet ? parent.extstreet : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ parent.instreet ? parent.instreet : '---' }}</span>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-4" class="accordion-header">
			<button class="accordion-button  collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-4"
				aria-expanded="false" aria-controls="faq-accordion-collapse-4">Persona(s) Señalada(s) como Presunto(s)
				Responsables</button>
		</div>
		<div id="faq-accordion-collapse-4" class="accordion-collapse collapse"
			aria-labelledby="faq-accordion-content-4" data-tw-parent="#faq-accordion-1">
			<div id="print-4" *ngFor="let participant of complaintData.responsibles; let i = index"
				class="grid grid-cols-12 table-bordered mt-5">
				<div class="col-span-12 sur-border sur-bg-light">
					<b>Datos de la Persona Señalada como Presunto Responsable #{{ i + 1 }}</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names | titlecase }} {{ participant.lastNames | titlecase }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Fecha de Nacimiento:</b>
					<br />
					<span>{{ participant.birthday ? (participant.birthday | mask: '00-00-0000') :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Género:</b>
					<br />
					<span>{{ participant.gender ? getListString(participant.gender,
						lists.genderList) : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Teléfono:</b>
					<br />
					<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ participant.email ? participant.email : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Pertenece a un Grupo de Vulnerabilidad:</b>
					<br />
					<span>{{ participant.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Se Encuentra Fuera de México:</b>
					<br />
					<span>{{ participant.outMexico ? 'Sí' : 'No' }}</span>
				</div>
				<div *ngIf="participant.outMexico" class="col-span-12 sur-border">
					<b>Localidad Actual:</b>
					<br />
					<span>{{ participant.location ? participant.location : '---' }}</span>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Estado:</b>
						<br />
						<span>{{ participant.state ? getListString(participant.state, lists.statesList)
							: '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Municipio:</b>
						<br />
						<span>{{ participant.municipality ? getListString(participant.municipality,
							lists.municipalityList) :
							'---'
							}}</span>
					</div>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Colonia:</b>
						<br />
						<span>{{ (participant.colony ? participant.colony : '---') | titlecase }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Código Postal:</b>
						<br />
						<span>{{ participant.zipcode ? participant.zipcode : '---' }}</span>
					</div>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 sur-border">
					<b>Calle:</b>
					<br />
					<span>{{ (participant.street ? participant.street : '---') | titlecase }}</span>
				</div>
				<div *ngIf="!participant.outMexico" class="col-span-12 grid grid-cols-12">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Exterior:</b>
						<br />
						<span>{{ participant.extstreet ? participant.extstreet : '---' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>No. Interior:</b>
						<br />
						<span>{{ participant.instreet ? participant.instreet : '---' }}</span>
					</div>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>La Persona Señalada como Responsable Forma Parte Del:</b>
					<br />
					<span>{{ participant.participantRole ?
						getListString(participant.participantRole,
						lists.participantRoleList)
						:
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Otro Tipo de Personal:</b>
					<br />
					<span>{{ participant.participantRoleOthers ? participant.participantRoleOthers :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ participant.instance ? getListString(participant.instance,
						lists.instanceList) : '---'
						}}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ participant.entity ? getListString(participant.entity,
						lists.entityList) : '---' }}</span>
				</div>
				<div class="col-span-12 sur-border">
					<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
					<br />
					<span>{{ participant.characteristics ? participant.characteristics : '---' }}</span>
				</div>
				<ng-container *ngFor="let parent of participant.parents; let j = index">
					<div class="col-span-12 grid grid-cols-12">
						<div class="col-span-12 sur-border sur-bg-light">
							<b>
								Tutor #{{ j + 1 }} del la Persona Señalada como Presunto Responsable #{{
								i + 1 }}
							</b>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border  t">
							<b>Nombre:</b>
							<br />
							<span> {{
								parent.names && parent.lastNames
								? (parent.names + ' ' + parent.lastNames | titlecase)
								: parent.names
								? (parent.names | titlecase)
								: parent.lastNames
								? (parent.lastNames | titlecase)
								: '---'
								}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border ">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ parent.email ? parent.email : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ parent.state ? getListString(parent.state, lists.statesList) :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ parent.municipality ? getListString(parent.municipality, lists.municipalityList) : '---'}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ (parent.colony ? parent.colony : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ parent.zipcode ? parent.zipcode : '---' }}</span>
						</div>
						<div class="col-span-12 sur-border">
							<b>Calle:</b>
							<br />
							<span>{{ (parent.street ? parent.street : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ parent.extstreet ? parent.extstreet : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ parent.instreet ? parent.instreet : '---' }}</span>
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="accordion-item">
		<div id="faq-accordion-content-5" class="accordion-header">
			<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
				data-tw-target="#faq-accordion-collapse-5"
				aria-expanded="false" aria-controls="faq-accordion-collapse-5">{{
				complaintData.witnesses?.length == 0 ||
				complaintData.witnesses == null ? 'Sin testigo' : 'Testigo(s)' }}</button>
		</div>
		<div id="faq-accordion-collapse-5" class="accordion-collapse collapse"
			aria-labelledby="faq-accordion-content-5"
			data-tw-parent="#faq-accordion-1">
			<div id="print-5" *ngFor="let participant of complaintData.witnesses; let i = index"
				class="grid grid-cols-12 col-span-12 mt-5">
				<div class="col-span-12 sur-bg-light sur-border">
					<b>Datos de la o el Testigo #{{ i + 1 }}</b>
				</div>
				<div class="col-span-12 sur-border">
					<b>Nombre:</b>
					<br />
					<span>{{ participant.names | titlecase }} {{ participant.lastNames | titlecase }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Teléfono:</b>
					<br />
					<span>{{ participant.phone ? (participant.phone | mask: '(00) 0000 0000') :
						'---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Correo Electrónico:</b>
					<br />
					<span>{{ participant.email ? participant.email : '---' }}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Instancia de Adscripción/Inscripción:</b>
					<br />
					<span>{{ participant.instance ? getListString(participant.instance,
						lists.instanceList) : '---'
						}}</span>
				</div>
				<div class="col-span-12 sm:col-span-6 sur-border">
					<b>Escuela/Dependencia:</b>
					<br />
					<span>{{ participant.entity ? getListString(participant.entity,
						lists.entityList) : '---' }}</span>
				</div>
				<div class="col-span-12 sur-border">
					<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
					<br />
					<span>{{ participant.characteristics ? (participant.characteristics | ucFirst) :
						'---' }}</span>
				</div>
			</div>
		</div>
	</div>
</div>

<div [ngClass]="{ hidden: !(complaintData && type == 'constancy') }" class="complaintResume">
	<div class="flex flex-col sm:flex-row items-left">
		<h2 class="font-medium text-base">Denuncia</h2>
		<div *ngIf="canExport" class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0">
			<a class="btn btn-primary">Exportar Documento</a>
		</div>
	</div>

	<div id="faq-accordion-3" class="accordion mt-5 print-all-proof">
		<div class="accordion-item">
			<div id="faq-accordion-content-13" class="accordion-header">
				<button class="accordion-button" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-13"
					aria-expanded="true" aria-controls="faq-accordion-collapse-13">Datos de la Denuncia</button>
			</div>
			<div id="faq-accordion-collapse-1" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-13"
				data-tw-parent="#faq-accordion-3">
				<div class="grid grid-cols-12 mt-5 p-0">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Folio:</b>
						<br />
						<span>{{ complaintData.code ? complaintData.code : 'Se asignará una vez generada la denuncia' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Fecha:</b>
						<br />
						<span>{{ complaintData.date ? formatDate(complaintData.date) : (nowDate | date: 'dd-MM-YYYY') }}</span>
					</div>
				</div>
			</div>
		</div>
		<div class="accordion-item">
			<div id="faq-accordion-content-23" class="accordion-header">
				<button class="accordion-button" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-23"
					aria-expanded="true" aria-controls="faq-accordion-collapse-23">{{ complaintData?.type_person?.title }}</button>
			</div>
			<div id="faq-accordion-collapse-23" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-23"
				data-tw-parent="#faq-accordion-3">
				<div class="grid grid-cols-12 mt-5 p-0">
					<div class="col-span-12 sur-border sur-bg-light"><b>{{ complaintData?.type_person?.name }}</b></div>
					<div class="col-span-12 sur-border">
						<b>Nombre:</b>
						<br />
						<span>{{ complaintData.DataPerson?.names | titlecase }} {{ complaintData.DataPerson?.lastNames | titlecase }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Instancia de Adscripción/Inscripción:</b>
							<br />
							<span>{{ complaintData.DataPerson?.instance ? getListString(complaintData.DataPerson?.instance,
								lists.instanceList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Domicilio de la instancia de adscripción:</b>
							<br />
							<span>{{ complaintData.DataPerson?.instanceAddress ? (complaintData.DataPerson?.instanceAddress |
								titlecase) : complaintData.DataPerson?.instance ? getListString(complaintData.DataPerson?.instance,
								lists.instanceAddressList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Teléfono:</b>
							<br />
							<span>{{ complaintData.DataPerson?.phone ? (complaintData.DataPerson?.phone | mask: '(00) 0000 0000') :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ complaintData.DataPerson?.email ? complaintData.DataPerson?.email : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ complaintData.DataPerson?.state ? getListString(complaintData.DataPerson?.state,
								lists.statesList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ complaintData.DataPerson?.municipality ? getListString(complaintData.DataPerson?.municipality,
								lists.municipalityList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ (complaintData.DataPerson?.colony ? complaintData.DataPerson?.colony : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ complaintData.DataPerson?.zipcode ? complaintData.DataPerson?.zipcode : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Calle:</b>
						<br />
						<span>{{ (complaintData.DataPerson?.street ? complaintData.DataPerson?.street : '---') | titlecase }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ complaintData.DataPerson?.extstreet ? complaintData.DataPerson?.extstreet : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ complaintData.DataPerson?.instreet ? complaintData.DataPerson?.instreet : '---' }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="accordion-item">
			<div id="faq-accordion-content-33" class="accordion-header">
				<button class="accordion-button" type="button" data-tw-toggle="collapse" data-tw-target="#faq-accordion-collapse-33"
					aria-expanded="true" aria-controls="faq-accordion-collapse-33">Denunciante</button>
			</div>
			<div id="faq-accordion-collapse-33" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-33"
				data-tw-parent="#faq-accordion-3">
				<div class="grid grid-cols-12 mt-5 p-0">
					<div class="col-span-12 sur-border sur-bg-ligh"><b>Datos de la Persona Denunciante</b></div>
					<div class="col-span-12 sur-border">
						<b>Nombre:</b>
						<br />
						<span>{{ complaintData.whistleblower?.names | titlecase }} {{ complaintData.whistleblower?.lastNames | titlecase }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Fecha de Nacimiento:</b>
							<br />
							<span>{{ complaintData.whistleblower?.birthday ? (complaintData.whistleblower?.birthday | mask:
								'00-00-0000') : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Género:</b>
							<br />
							<span>{{ complaintData.whistleblower?.gender ? getListString(complaintData.whistleblower?.gender,
								lists.genderList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Teléfono:</b>
							<br />
							<span>{{ complaintData.whistleblower?.phone ? (complaintData.whistleblower?.phone | mask: '(00) 0000 0000')
								: '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ complaintData.whistleblower?.email ? complaintData.whistleblower?.email : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Firma de la Persona Denunciante:</b>
						<img src="{{ complaintData.b64signature }}" />
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Pertenece a un Grupo de Vulnerabilidad:</b>
							<br />
							<span>{{ complaintData.whistleblower?.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Se Encuentra Fuera de México:</b>
							<br />
							<span>{{ complaintData.whistleblower?.outMexico ? 'Sí' : 'No' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && complaintData.whistleblower.outMexico">
						<b>Localidad Actual:</b>
						<br />
						<span>{{ complaintData.whistleblower?.location ? complaintData.whistleblower?.location : '---' }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ complaintData.whistleblower?.state ? getListString(complaintData.whistleblower?.state,
								lists.statesList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ complaintData.whistleblower?.municipality ?
								getListString(complaintData.whistleblower?.municipality, lists.municipalityList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ (complaintData.whistleblower?.colony ? complaintData.whistleblower?.colony : '---' ) | titlecase}}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ complaintData.whistleblower?.zipcode ? complaintData.whistleblower?.zipcode : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<b>Calle:</b>
						<br />
						<span>{{ (complaintData.whistleblower?.street ? complaintData.whistleblower?.street : '---') | titlecase }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ complaintData.whistleblower?.extstreet ? complaintData.whistleblower?.extstreet : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ complaintData.whistleblower?.instreet ? complaintData.whistleblower?.instreet : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>El Denunciante Forma Parte Del:</b>
							<br />
							<span>{{ complaintData.whistleblower?.participantRole ?
								getListString(complaintData.whistleblower?.participantRole, lists.participantRoleList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Otro Tipo de Personal:</b>
							<br />
							<span>{{ complaintData.whistleblower?.participantRoleOthers ?
								complaintData.whistleblower?.participantRoleOthers : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Instancia de Adscripción/Inscripción:</b>
							<br />
							<span>{{ complaintData.whistleblower?.instance ? getListString(complaintData.whistleblower?.instance,
								lists.instanceList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Escuela/Dependencia:</b>
							<br />
							<span>{{ complaintData.whistleblower?.entity ? getListString(complaintData.whistleblower?.entity,
								lists.entityList) : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
						<br />
						<span>{{ complaintData.whistleblower?.characteristics ? (complaintData.whistleblower?.characteristics |
							ucFirst) : '---' }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div *ngIf="complaintData && type == 'presentation-proof'" class="complaintResume">
	<div class="flex flex-col sm:flex-row items-left">
		<h2 class="font-medium text-base">Denuncia</h2>
		<div *ngIf="canExport" class="w-full sm:w-auto sm:ml-auto mt-3 sm:mt-0">
			<a class="btn btn-primary">Exportar Documento</a>
		</div>
	</div>

	<div id="faq-accordion-2" class="accordion mt-5">
		<div class="accordion-item">
			<div id="faq-accordion-content-12" class="accordion-header">
				<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
					data-tw-target="#faq-accordion-collapse-12"
					aria-expanded="true" aria-controls="faq-accordion-collapse-12">Datos de la Denuncia</button>
			</div>
			<div id="faq-accordion-collapse-12" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-12"
				data-tw-parent="#faq-accordion-2">
				<div class="grid grid-cols-12 mt-5 p-0">
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Folio:</b>
						<br />
						<span>{{ complaintData.code ? complaintData.code : 'Se asignará una vez generada la denuncia' }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Fecha:</b>
						<br />
						<span>{{ complaintData.date ? formatDate(complaintData.date) : (nowDate | date: 'dd-MM-YYYY') }}</span>
					</div>
				</div>
			</div>
		</div>

		<div class="accordion-item">
			<div id="faq-accordion-content-32" class="accordion-header">
				<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
					data-tw-target="#faq-accordion-collapse-32"
					aria-expanded="true" aria-controls="faq-accordion-collapse-32">Primer Contacto</button>
			</div>
			<div id="faq-accordion-collapse-32" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-32"
				data-tw-parent="#faq-accordion-2">
				<div class="grid grid-cols-12 mt-5 p-0">
					<div class="col-span-12 sur-border sur-bg-light">
						<b>Datos del Primer Contacto</b>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Nombre:</b>
						<br />
						<span>{{ complaintData.firtContact.names | titlecase }} {{ complaintData.firtContact.lastNames | titlecase }}</span>
					</div>
					<div class="col-span-12 sm:col-span-6 sur-border">
						<b>Firma:</b>
						<img *ngIf="presentationProofFCsign" src="{{ presentationProofFCsign }}" />
						<br *ngIf="!presentationProofFCsign" />
						<span *ngIf="!presentationProofFCsign">---</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Instancia de Adscripción/Inscripción:</b>
							<br />
							<span>{{ complaintData.firtContact.instance ? getListString(complaintData.firtContact.instance,
								lists.instanceList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Domicilio de la instancia de adscripción:</b>
							<br />
							<span>{{ complaintData.firtContact.instanceAddress ? (complaintData.firtContact.instanceAddress | titlecase)
								: complaintData.firtContact.instance ? getListString(complaintData.firtContact.instance,
								lists.instanceAddressList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Teléfono:</b>
							<br />
							<span>{{ complaintData.firtContact.phone ? (complaintData.firtContact.phone | mask: '(00) 0000 0000') :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ complaintData.firtContact.email ? complaintData.firtContact.email : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ complaintData.firtContact.state ? getListString(complaintData.firtContact.state, lists.statesList)
								: '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ complaintData.firtContact.municipality ? getListString(complaintData.firtContact.municipality,
								lists.municipalityList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ (complaintData.firtContact.colony ? complaintData.firtContact.colony : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ complaintData.firtContact.zipcode ? complaintData.firtContact.zipcode : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Calle:</b>
						<br />
						<span>{{ (complaintData.firtContact.street ? complaintData.firtContact.street : '---') | titlecase }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ complaintData.firtContact.extstreet ? complaintData.firtContact.extstreet : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ complaintData.firtContact.instreet ? complaintData.firtContact.instreet : '---' }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="accordion-item">
			<div id="faq-accordion-content-22" class="accordion-header">
				<button class="accordion-button collapsed" type="button" data-tw-toggle="collapse"
					data-tw-target="#faq-accordion-collapse-22"
					aria-expanded="true" aria-controls="faq-accordion-collapse-22">Denunciante</button>
			</div>
			<div id="faq-accordion-collapse-22" class="accordion-collapse collapse" aria-labelledby="faq-accordion-content-22"
				data-tw-parent="#faq-accordion-2">
				<div class="grid grid-cols-12 mt-5 p-0">
					<div class="col-span-12 sur-border sur-bg-light">
						<b>Datos de la Persona Denunciante</b>
					</div>
					<div class="col-span-12 sur-border">
						<b>Nombre:</b>
						<br />
						<span>{{ complaintData.whistleblower.names | titlecase }} {{ complaintData.whistleblower.lastNames | titlecase }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Fecha de Nacimiento:</b>
							<br />
							<span>{{ complaintData.whistleblower.birthday ? (complaintData.whistleblower.birthday | mask: '00-00-0000')
								: '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Género:</b>
							<br />
							<span>{{ complaintData.whistleblower.gender ? getListString(complaintData.whistleblower.gender,
								lists.genderList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Teléfono:</b>
							<br />
							<span>{{ complaintData.whistleblower.phone ? (complaintData.whistleblower.phone | mask: '(00) 0000 0000') :
								'---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Correo Electrónico:</b>
							<br />
							<span>{{ complaintData.whistleblower.email ? complaintData.whistleblower.email : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Firma de la Persona Denunciante:</b>
						<img src="{{ complaintData.b64signature }}" />
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Pertenece a un Grupo de Vulnerabilidad:</b>
							<br />
							<span>{{ complaintData.whistleblower.vulnerabilityGroup ? 'Sí' : 'No' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Se Encuentra Fuera de México:</b>
							<br />
							<span>{{ complaintData.whistleblower.outMexico ? 'Sí' : 'No' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && complaintData.whistleblower.outMexico">
						<b>Localidad Actual:</b>
						<br />
						<span>{{ complaintData.whistleblower.location ? complaintData.whistleblower.location : '---' }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Estado:</b>
							<br />
							<span>{{ complaintData.whistleblower.state ? getListString(complaintData.whistleblower.state,
								lists.statesList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Municipio:</b>
							<br />
							<span>{{ complaintData.whistleblower.municipality ? getListString(complaintData.whistleblower.municipality,
								lists.municipalityList) : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Colonia:</b>
							<br />
							<span>{{ (complaintData.whistleblower.colony ? complaintData.whistleblower.colony : '---') | titlecase }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Código Postal:</b>
							<br />
							<span>{{ complaintData.whistleblower.zipcode ? complaintData.whistleblower.zipcode : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<b>Calle:</b>
						<br />
						<span>{{ (complaintData.whistleblower.street ? complaintData.whistleblower.street : '---') | titlecase }}</span>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email && !complaintData.whistleblower.outMexico">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Exterior:</b>
							<br />
							<span>{{ complaintData.whistleblower.extstreet ? complaintData.whistleblower.extstreet : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>No. Interior:</b>
							<br />
							<span>{{ complaintData.whistleblower.instreet ? complaintData.whistleblower.instreet : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>El Denunciante Forma Parte Del:</b>
							<br />
							<span>{{ complaintData.whistleblower.participantRole ?
								getListString(complaintData.whistleblower.participantRole, lists.participantRoleList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Otro Tipo de Personal:</b>
							<br />
							<span>{{ complaintData.whistleblower.participantRoleOthers ?
								complaintData.whistleblower.participantRoleOthers : '---' }}</span>
						</div>
					</div>
					<div class="grid grid-cols-12 col-span-12"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Instancia de Adscripción/Inscripción:</b>
							<br />
							<span>{{ complaintData.whistleblower.instance ? getListString(complaintData.whistleblower.instance,
								lists.instanceList) : '---' }}</span>
						</div>
						<div class="col-span-12 sm:col-span-6 sur-border">
							<b>Escuela/Dependencia:</b>
							<br />
							<span>{{ complaintData.whistleblower.entity ? getListString(complaintData.whistleblower.entity,
								lists.entityList) : '---' }}</span>
						</div>
					</div>
					<div class="col-span-12 sur-border"
						*ngIf="UserDataFull.id_person != 2 && UserDataFull.email != complaintData.whistleblower.email">
						<b>Código de Alumno/Empleado o Características Adicionales del Individuo:</b>
						<br />
						<span>{{ complaintData.whistleblower.characteristics ? (complaintData.whistleblower.characteristics |
							ucFirst) : '---' }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>