import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-mobile-menu',
	templateUrl: './mobile-menu.component.html',
	styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
	private _unsubscribe = new Subject<void>();

	constructor() {}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}

	ngOnInit(): void {
		setTimeout(() => {
			$('#mobile-menu-toggler').on('click', function () {
				if ($('.mobile-menu').find('ul').first()[0].offsetParent !== null) {
					$('.mobile-menu').find('ul').first().slideUp();
				} else {
					$('.mobile-menu').find('ul').first().slideDown();
				}
			});

			$('.mobile-menu')
				.find('.menu')
				.on('click', function (this: any) {
					if ($(this).parent().find('ul').length) {
						if ($(this).parent().find('ul').first()[0].offsetParent !== null) {
							$(this).find('.menu__sub-icon').removeClass('transform rotate-180');
							$(this)
								.parent()
								.find('ul')
								.first()
								.slideUp(300, function (this: any) {
									$(this).removeClass('menu__sub-open');
								});
						} else {
							$(this).find('.menu__sub-icon').addClass('transform rotate-180');
							$(this)
								.parent()
								.find('ul')
								.first()
								.slideDown(300, function (this: any) {
									$(this).addClass('menu__sub-open');
								});
						}
					} else {
						$(this).parent().parent().slideUp();
					}
				});
		}, 0);
	}
}
