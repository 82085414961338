import { Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[scActionValidate]' })
export class ActionValidatorDirective {
	constructor(
		private templateRef: TemplateRef<any>,
		private viewContainer: ViewContainerRef
	) { }

	@Input()
	set scActionValidate(action: string) {
		if (this.hasAccess(action)) {
			this.viewContainer.createEmbeddedView(this.templateRef);
		} else {
			this.viewContainer.clear();
		}
	}



	hasAccess(action: string): boolean {
		if (!action) {
			return false;
		}
		const permissions = this.getPermissions();
		const wildcardAction = action.split(':')[0] + ':*';
		return permissions.includes(action) || permissions.includes(wildcardAction);
	}

	private getPermissions(): Array<string> {
		return [
			'permiso:leer',
			'permiso:crear',
			'permiso:eliminar',
			'permiso:actualizar',
			'permiso:*'
		]
		// return JSON.parse(this.localStorageHelper.getItem(LOCAL_STORAGE_CONSTANTS.PERMISOS));
	}

}
