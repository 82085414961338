import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserRegister } from 'src/app/core/interfaces/user-register';
import { UserLogin } from 'src/app/core/interfaces/user-login';
import { TwoSteps } from 'src/app/core/interfaces/two-steps';
import { UserComplete } from 'src/app/core/interfaces/user-complete';
import { environment } from '../../../../environments/environment';
import { JsEncode } from '../../exports/JsEncode';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(private http: HttpClient) {}

	apiURL = environment.apiURL;
	//apiURL = 'http://localhost/surtechudg-back-v2/public/api/';

	//Registrar nuevo usuario
	add_user(user: UserRegister): Observable<any> {
		let data = {
			names: user.first_name,
			last_names: user.last_name,
			password: user.password,
			email: user.email,
		};

		return this.http.post<UserRegister>(this.apiURL + 'user', data);
	}
	//Registrar usuario nuevo desde el backoffice
	add_user_backoffice(user: any): Observable<any> {
		return this.http.post<UserRegister>(this.apiURL + 'user', user);
	}
	//Registrar usuario nuevo desde el backoffice
	add_udg_groups(data: any): Observable<any> {
		return this.http.post<UserRegister>(this.apiURL + 'add_udg_groups', data);
	}
	create_flowcharts_formats(data: any): Observable<any> {
		const formData = new FormData();

		formData.append('id_action_type', data.id_action_type);
		formData.append('id_flow_format_type', data.id_flow_format_type);
		formData.append('id_stage', data.id_stages);
		formData.append('documento', data.documento);

		return this.http.post<any>(this.apiURL + 'create_flowcharts_formats', formData);
	}
	//Validar login y generar pin de dos pasos
	user_login(user: UserLogin): Observable<any> {
		let data = {
			user: user.name_user,
			password: user.password,
		};

		return this.http.post<UserLogin>(this.apiURL + 'user_login', data);
	}

	//Validar pin de dos pasos
	user_two_step(twoStepData: TwoSteps): Observable<any> {
		return this.http.post(this.apiURL + 'user_two_step', twoStepData);
	}

	//Completar datos
	update_person_location(user: UserComplete): Observable<any> {
		let update_data = {
			birth_date: user.date_birth,
			fk_id_cat_gender: user.id_cat_gender,
			mobile_phone: user.phone,
			fk_id_cat_state: user.state,
			fk_id_cat_municipality: user.id_municipio,
			suburb: user.colonia,
			zipcode: user.zip,
			street: user.street,
			ext_number: user.external_num,
			int_number: user.internal_num,
		};

		return this.http.put<UserRegister>(this.apiURL + 'user', update_data, {});
	}

	//Listar estados
	list_cat_countries_states(id_country: number | null = null): Observable<any> {
		let queryRoute = id_country ? `/${id_country}` : '';
		return this.http.get(this.apiURL + 'list_cat_countries_states' + queryRoute);
	}

	//Obtener el archivo subido en un formato o flujograma
	get_content_flowcharts_formats(id_flow_format: number): Observable<any> {
		return this.http.get(this.apiURL + 'get_content_flowcharts_formats', { params: { id_flow_format: id_flow_format } });
	}

	//tipo de usuarios
	list_cat_flowcharts_formats_types(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_flowcharts_formats_types');
	}

	//tipo de usuarios
	list_cat_profiles(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_profiles');
	}

	//Listar municipios
	list_cat_states_municipalities(id_state: number | null = null): Observable<any> {
		let queryRoute = id_state ? `/${id_state}` : '';
		return this.http.get<any>(this.apiURL + 'list_cat_states_municipalities' + queryRoute);
	}

	//Listar todos los municipios
	listfullmunicipality(): Observable<any> {
		return this.http.post<any>(this.apiURL + 'listfullmunicipality', {});
	}

	//Listar generos
	list_cat_genders(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_genders');
	}

	//Listar instancias
	list_cat_udg_instances(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_udg_instances', {});
	}

	//Listar subinstancias
	list_cat_udg_subinstances(id_instance: number | null = null): Observable<any> {
		let queryRoute = id_instance ? `/${id_instance}` : '';
		return this.http.get(this.apiURL + 'list_cat_udg_subinstances' + queryRoute);
	}

	//Listar tipos de miembros en la udg
	list_cat_members_types(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_members_types');
	}

	//Listar tipos de pruebas
	list_cat_complaints_evidences_types(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_complaints_evidences_types');
	}

	//Listar tipos de medidas cautelares
	list_cat_complaints_precautionaries_measures_types(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_complaints_precautionaries_measures_types');
	}

	//Listar tipos de notas
	list_cat_complaints_notes_types(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_complaints_notes_types');
	}

	//Listar denuncias
	list_complaints(): Observable<any> {
		return this.http.get(this.apiURL + 'list_complaints');
	}

	//Listar tipos de acciones
	list_cat_complaints_actions_types(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_complaints_actions_types');
	}

	list_cat_complaints_stages(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_complaints_stages');
	}
	//Listar tipos de acciones
	list_cat_type_groups(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_type_groups');
	}

	//Listar tipos
	list_cat_appointment_types(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_appointment_types');
	}

	//Listar tipos de entidades
	list_cat_appointment_entities_roles(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_appointment_entities_roles');
	}

	//Listar tipos de estatus
	list_cat_appointment_status(): Observable<any> {
		return this.http.get(this.apiURL + 'list_cat_appointment_status');
	}

	//Recuperar Contraseña
	user_recovery(email: string): Observable<any> {
		return this.http.get(this.apiURL + 'user_recovery/' + email);
	}

	//Cambiar contraseña - recuperar contraseña
	recovery_password(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'recovery_password', data);
	}

	//Cambiar contraseña dentro del perfil
	change_password(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'change_password', data);
	}

	//Actualizar perfil
	update_profile(data: any): Observable<any> {
		let update_data = {
			names: data.new_user_data.names,
			last_names: data.new_user_data.lastNames,
			birth_date: data.new_user_data.birthday,
			fk_id_cat_gender: data.new_user_data.gender,
			mobile_phone: data.new_user_data.phone,
			fk_id_cat_state: data.new_user_data.state,
			fk_id_cat_municipality: data.new_user_data.municipality,
			suburb: data.new_user_data.colony,
			zipcode: data.new_user_data.zipcode,
			street: data.new_user_data.street,
			ext_number: data.new_user_data.extstreet,
			int_number: data.new_user_data.instreet,
			b64_profile_pic: data.b64_profile_pic,
		};

		return this.http.put(this.apiURL + 'user', update_data);
	}

	//Crear denuncia
	create_complaint(complaint: any, evidences: any[] = []): Observable<any> {
		const formData = new FormData();

		formData.append('complaint', JSON.stringify(complaint));

		evidences.forEach(element => {
			formData.append('evidences[]', element);
		});

		return this.http.post(this.apiURL + 'complaint', formData);
	}

	//Unirse a denuncia
	join_complaint(code: any): Observable<any> {
		return this.http.post(this.apiURL + 'join_complaint', code);
	}

	//Obtener denuncia
	get_complaint(id_complaint: number): Observable<any> {
		return this.http.get(this.apiURL + 'complaint/' + id_complaint);
	}

	//Envio de contacto
	sendContact(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'contact_form', data);
	}

	//Consultar primer contacto en la sub instancia
	consult_fisrt_contact(id_subinstance: number): Observable<any> {
		return this.http.get(this.apiURL + 'consult_fisrt_contact?id_subinstancia=' + id_subinstance + '&agendado=true');
	}

	//Agendar cita con el primer contacto
	schedule_appointment(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'schedule_appointment', data);
	}

	//Actualizar horario del primer contacto
	update_first_contact_schedule(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'update_first_contact_schedule', data);
	}

	//Consultar calendario del usuario
	consult_calendar_user(id_user: any): Observable<any> {
		// return this.http.get(this.apiURL + 'consult_calendar_user');
		return this.http.get(this.apiURL + 'get_appointment_by_entity?id=' + id_user + '&tipo=U&agendado=true');
	}
	//Consultar calendario del usuario
	consult_calendar_group(id_group: any): Observable<any> {
		// return this.http.get(this.apiURL + 'consult_calendar_user');
		return this.http.get(this.apiURL + 'get_appointment_by_entity?id=' + id_group + '&tipo=G&agendado=true');
	}
	consult_calendar_group_type_d(id_group: any): Observable<any> {
		// return this.http.get(this.apiURL + 'consult_calendar_user');
		return this.http.get(this.apiURL + 'get_appointment_by_entity?id=' + id_group + '&tipo=D&agendado=true');
	}
	consult_calendar_fisrt_contact(id_group: any): Observable<any> {
		// return this.http.get(this.apiURL + 'consult_calendar_user');
		return this.http.get(this.apiURL + 'get_appointment_by_entity?id=' + 1 + '&tipo=U');
	}

	//Obtener archivo
	getfile(string: string): Observable<any> {
		return this.http.get(this.apiURL + 'getfile', {
			params: {
				file: string,
			},
		});
	}

	//Overlay Constancia de presentacion
	overlay_presentation_proof(data: any, check: boolean): Observable<any> {
		if (check) {
			return this.http.post(this.apiURL + 'overlay_presentation_proof_crys', data);
		} else {
			return this.http.post(this.apiURL + 'overlay_presentation_proof', data);
		}
	}

	//Overlay Determinar tipo de violencia
	overlay_determination_type_violence(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_determination_type_violence', data);
	}

	//Overlay Determinar tipo de violencia crys
	overlay_determination_type_violence_crys(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_determination_type_violence_crys', data);
	}

	//Overlay Determinar competicion
	overlay_determine_competition(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_determine_competition', data);
	}

	//Overlay Determinar radicacion
	overlay_determine_radication(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_determine_radication', data);
	}

	//Overlay Determinar tipo de violencia
	overlay_determination_type_aggravating(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_determination_type_aggravating', data);
	}

	//Overlay determinar sancion
	overlay_determine_sanction(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_determine_sanction', data);
	}

	//Overlay remitir denuncia
	overlay_submit_complaint(data: any, check: boolean): Observable<any> {
		if (check) {
			return this.http.post(this.apiURL + 'overlay_submit_complaint_crys', data);
		} else {
			return this.http.post(this.apiURL + 'overlay_submit_complaint', data);
		}
	}

	//Overlay constancia de denuncia
	overlay_proof_complaint(data: any): Observable<any> {
		const formData = new FormData();
		formData.append('id_complaint', data.id_complaint);
		formData.append('all_right', data.all_right);
		formData.append('missing', data.missing);
		formData.append('html', data.html);
		data.files.forEach((element: any) => {
			formData.append('documents[]', element);
		});
		return this.http.post(this.apiURL + 'overlay_proof_complaint', formData);
	}

	//Overlay verificacion de datos como responsables
	overlay_verification_responsible_data(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_verification_responsible_data', data);
	}

	//Overlay subir documentos
	overlay_upload_documents(data: any): Observable<any> {
		const formData = new FormData();
		formData.append('id_complaint', data.id_complaint);
		formData.append('title', data.title);
		formData.append('description', data.description);
		if (data.permission) {
			data.permission.forEach((permission: any) => {
				formData.append('permission[]', permission);
			});
		}
		data.files.forEach((element: any) => {
			formData.append('documents[]', element);
		});
		return this.http.post(this.apiURL + 'overlay_upload_documents', formData);
	}

	//Overlay Registrar denuncia
	overlay_register_complaint(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_register_complaint', data);
	}

	//Overlay Solicitar Ratificacion de firma
	overlay_request_signature_ratification(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_request_signature_ratification', data);
	}
	overlay_request_signature_ratification2(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_request_signature_ratification_pc_wt', data);
	}
	overlay_comprehensive_care(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_comprehensive_care', data);
	}
	close_appoinment(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'close_appoinment', data);
	}

	//Overlay Ratificacion de firma
	overlay_signature_ratification(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_signature_ratification', data);
	}

	overlay_signature_ratification2(data: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_signature_ratification_pc_wt', data);
	}

	//Overlay subir pruebas
	overlay_evidences(id_complaint: any, evidences: any[] = []): Observable<any> {
		const formData = new FormData();

		formData.append('id_complaint', id_complaint);

		evidences.forEach(element => {
			formData.append('evidences[]', element);
		});

		return this.http.post(this.apiURL + 'upload_proof', formData);
	}

	//Overlay identificar atencion integral
	overlay_comprehensive_care_identification(formData: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_comprehensive_care_identification', formData);
	}

	//Overlay verificar requisitos de la denuncia
	overlay_verification_complaint_requeriments(formData: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_verification_complaint_requeriments', formData);
	}

	//Overlay aclarar denuncia
	overlay_clarification_complaint(formData: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_clarification_complaint', formData);
	}

	//Overlay actualizar codigo de la denuncia
	overlay_update_complaint_code(formData: any): Observable<any> {
		return this.http.post(this.apiURL + 'overlay_update_complaint_code', formData);
	}

	//Consultar calendario del usuario
	list_users(page: number, num: number, search: string): Observable<any> {
		return this.http.get(this.apiURL + `user_list?page=${page}&num=${num}&search=${search}`);
	}
	//Consultar calendario del usuario
	list_groups(page: number, num: number, search: string, tipo: number): Observable<any> {
		return this.http.get(this.apiURL + `list_groups?page=${page}&num=${num}&search=${search}&tipo=${tipo}`);
	}
	list_formats_diagrams(page: number, num: number, search: string, tipo: number | null): Observable<any> {
		return this.http.get(this.apiURL + `list_formats_diagrams?page=${page}&num=${num}&tipo=${tipo}`);
	}

	//Consultar calendario del usuario
	list_first_contacts(page: number, num: number, search: string): Observable<any> {
		return this.http.get(this.apiURL + `list_first_contacts?page=${page}&num=${num}&search=${search}`);
	}

	//Consultar informacion del usuario
	users_find(id_user: string): Observable<any> {
		return this.http.get(this.apiURL + `user_find/${id_user}`);
	}
	//Consultar un grupo
	groups_find(id_group: string): Observable<any> {
		return this.http.get(this.apiURL + `groups_find/${id_group}`);
	}
	//Consultar un grupo
	find_inst_sub_groups(id_group: string): Observable<any> {
		return this.http.get(this.apiURL + `find_inst_sub_groups/${id_group}`);
	}

	update_upl(user_data: object): Observable<any> {
		return this.http.post(this.apiURL + `update_upl`, user_data);
	}

	edit_udg_groups(group_data: object): Observable<any> {
		return this.http.post(this.apiURL + `edit_udg_groups`, group_data);
	}

	add_inst_sub_groups(group_data: object): Observable<any> {
		return this.http.post(this.apiURL + `add_inst_sub_groups`, group_data);
	}
	add_first_contact(user_data: object): Observable<any> {
		return this.http.post(this.apiURL + `add_first_contact`, user_data);
	}

	status_user(id_user: any, status: boolean): Observable<any> {
		return this.http.post(this.apiURL + 'user_enable_disable', {
			id_user: id_user,
			active: status,
		});
	}

	list_nouser_group(id_group: string, page: number, num: number, search: string): Observable<any> {
		return this.http.get(this.apiURL + `list_nouser_group?page=${page}&num=${num}&search=${search}&id_group=${id_group}`);
	}

	list_user_group(id_group: string): Observable<any> {
		return this.http.get(this.apiURL + `list_user_group?id_group=${id_group}`);
	}

	//Consultar un grupo
	list_insta_subinta_group(id_group: string): Observable<any> {
		return this.http.get(this.apiURL + `list_insta_subinta_group?id_udg_group=${id_group}`);
	}

	list_first_contacts_inst_sub(id_group: string): Observable<any> {
		return this.http.get(this.apiURL + `list_first_contacts_inst_sub?id_user=${id_group}`);
	}

	add_groups_members_roles(data: object): Observable<any> {
		return this.http.post(this.apiURL + `add_groups_members_roles`, data);
	}

	del_groups_members_roles(data: object): Observable<any> {
		return this.http.post(this.apiURL + `del_groups_members_roles`, data);
	}

	schedule_group_appointment(data: object): Observable<any> {
		return this.http.post(this.apiURL + `schedule_group_appointment`, data);
	}
	overlay_schedule_comprehensive_care(data: object): Observable<any> {
		return this.http.post(this.apiURL + `overlay_schedule_comprehensive_care`, data);
	}
	update_schedule_group_appointment(data: object): Observable<any> {
		return this.http.post(this.apiURL + `update_schedule_group_appointment`, data);
	}
	overlay_filer_complaint(data: object): Observable<any> {
		return this.http.post(this.apiURL + `overlay_filer_complaint`, data);
	}
	overlay_note_create(data: object): Observable<any> {
		return this.http.post(this.apiURL + `overlay_note_create`, data);
	}
	overlay_request_clarification(data: any): Observable<any> {
		const formData = new FormData();
		formData.append('id_complaint', JSON.stringify(data.id_complaint));
		formData.append('motivo', JSON.stringify(data.motivo));
		formData.append('archivos[]', data.archivos[0]);

		return this.http.post(this.apiURL + `overlay_request_clarification`, formData);
	}
	overlay_close_complaint(data: object): Observable<any> {
		return this.http.post(this.apiURL + `overlay_close_complaint`, data);
	}
	overlay_unfiler_complaint(data: object): Observable<any> {
		return this.http.post(this.apiURL + `overlay_unfiler_complaint`, data);
	}
	overlay_answer_clarifying(data: any): Observable<any> {
		const formData = new FormData();
		formData.append('id_complaint', JSON.stringify(data.id_complaint));
		formData.append('respuesta', JSON.stringify(data.respuesta));
		formData.append('archivos[]', data.archivos[0]);
		return this.http.post(this.apiURL + `overlay_answer_clarifying`, formData);
	}
	update_groups_first_contact_schedule(data: object): Observable<any> {
		return this.http.post(this.apiURL + `update_groups_first_contact_schedule`, data);
	}
	get_groups_workhours(id: any): Observable<any> {
		return this.http.get(this.apiURL + `get_groups_workhours?id_group=${id}`);
	}
	consult_groups_appointment(id: any): Observable<any> {
		return this.http.get(this.apiURL + `consult_groups_appointment?id_complaint=${id}`);
	}
	overlay_up_ipr(data: any): Observable<any> {
		const formData = new FormData();

		formData.append('id_complaint', data.id_complaint);
		formData.append('ipr', data.ipr);

		return this.http.post(this.apiURL + `overlay_up_ipr`, formData);
	}
	overlay_up_final_resolution(data: any): Observable<any> {
		const formData = new FormData();

		formData.append('id_complaint', data.id_complaint);
		formData.append('in_favor', data.in_favor);
		formData.append('against', data.against);
		formData.append('resolution', data.resolution);

		return this.http.post(this.apiURL + `overlay_up_final_resolution`, formData);
	}
	get_content_ipr(): Observable<any> {
		return this.http.get(this.apiURL + `get_content_ipr`);
	}

	list_secondary_complaint(id: any): Observable<any> {
		return this.http.get(this.apiURL + `list_secondary_complaint?primary_complaint=${id}`);
	}

	get_content_final_resolution(): Observable<any> {
		return this.http.get(this.apiURL + `get_content_final_resolution`);
	}

	delete_format_diagram(data: object): Observable<any> {
		return this.http.post(this.apiURL + `delete_format_diagram`, data);
	}

	list_schedule_apponitment_involved(id: any): Observable<any> {
		return this.http.get(this.apiURL + `list_schedule_apponitment_involved?id_complaint=${id}`);
	}
	schedule_hearing_appointment(data: object): Observable<any> {
		return this.http.post(this.apiURL + `schedule_hearing_appointment`, data);
	}
	update_schedule_hearing_appointment(data: object): Observable<any> {
		return this.http.post(this.apiURL + `update_schedule_hearing_appointment`, data);
	}
	complaint_merge(data: object): Observable<any> {
		return this.http.post(this.apiURL + `complaint_merge`, data);
	}
	makepdf(data: object): Observable<any> {
		return this.http.post(this.apiURL + `makepdf`, data);
	}
}
