<div
	class="bg-white top-bar-boxed h-[70px] z-[51] relative border-b border-white/[0.08] -mt-6 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-0 mb-12">
	<div class="h-full items-center">
		<div class="h-full flex items-center">
			<a routerLink="/" class="-intro-x hidden md:flex">
				<img src="assets/img/logo_udg.png" />
			</a>

			<nav aria-label="breadcrumb" class="-intro-x h-full mr-auto">
				<app-breadcrumb></app-breadcrumb>
			</nav>

			<div class="intro-x mr-4 sm:mr-6 hidden">
				<div (click)="isNotificationMenuOpen = !isNotificationMenuOpen" #toggleNotificationButton
					class="dropdown-toggle notification notification--bullet cursor-pointer flex" role="button"
					aria-expanded="false">
					<div class="pointer-events-none flex">
						<ng-icon class="notification__icon text-primary" name="feather-bell" size="20px"></ng-icon>
					</div>
				</div>
				<div #menuNotification [ngClass]="isNotificationMenuOpen ? 'show' : ''"
					class="notification-content pt-2 absolute dropdown-menu"
					style="inset: auto auto auto auto; margin: 0px; transform: translate(0px, 20px); right: 0; top: 0">
					<div class="notification-content__box dropdown-content">
						<div class="notification-content__title">Notificaciones</div>

						<div class="notification-item cursor-pointer relative flex items-center">
							<div class="w-12 h-12 flex-none image-fit mr-1 pointer-events-none">
								<img class="rounded-full" src="assets/img/placeholders/profile.png" />
								<div
									class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white">
								</div>
							</div>
							<div class="ml-2 overflow-hidden pointer-events-none">
								<div class="flex items-center">
									<a href="javascript:;" class="font-medium truncate mr-5">Angelina Jolie</a>
									<div class="text-xs text-slate-400 ml-auto whitespace-nowrap">05:09 AM</div>
								</div>
								<div class="w-full truncate text-slate-500 mt-0.5">Lorem Ipsum is simply dummy text of
									the printing and typesetting industry. Lorem Ipsum has been the industry&#039;s
									standard dummy text ever since the 1500</div>
							</div>
						</div>

						<div class="notification-item cursor-pointer relative flex items-center mt-5">
							<div class="w-12 h-12 flex-none image-fit mr-1 pointer-events-none">
								<img class="rounded-full" src="assets/img/placeholders/profile.png" />
								<div
									class="w-3 h-3 bg-success absolute right-0 bottom-0 rounded-full border-2 border-white">
								</div>
							</div>
							<div class="ml-2 overflow-hidden pointer-events-none">
								<div class="flex items-center">
									<a href="javascript:;" class="font-medium truncate mr-5">Johnny Depp</a>
									<div class="text-xs text-slate-400 ml-auto whitespace-nowrap">06:05 AM</div>
								</div>
								<div class="w-full truncate text-slate-500 mt-0.5">There are many variations of passages
									of Lorem Ipsum available, but the majority have suffered alteration in some form, by
									injected humour, or randomi</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="intro-x mr-4 sm:mr-6" *ngIf="this.UserDataFull.fk_id_profile == 9">
				<div (click)="isConfigMenuOpen = !isConfigMenuOpen" #toggleConfigButton
					class="dropdown-toggle cursor-pointer flex" style="color: #1e3a8a" role="button"
					aria-expanded="false">
					<div class="pointer-events-none flex">
						<ng-icon class="mr-2 pointer-events-none" name="feather-settings" size="20px"></ng-icon>
					</div>
				</div>
				<div #menuConfig [ngClass]="isConfigMenuOpen ? 'show' : ''" class="dropdown-menu w-56 absolute"
					style="inset: auto auto auto auto; transform: translate(0px, 0px); top: 35px; right: -3px">
					<ul
						class="dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
						<li class="p-2">
							<div class="font-medium">Configuración</div>
						</li>
						<li>
							<hr class="dropdown-divider border-white/[0.08]" />
						</li>
						<li>
							<a routerLink="/dashboard/settings/users/list" class="dropdown-item hover:bg-white/5"
								(click)="ResetFilter('Usuarios')">
								<ng-icon class="mr-2 pointer-events-none" name="feather-user" size="16px"></ng-icon>
								Usuarios
							</a>
						</li>
						<li>
							<a routerLink="/dashboard/settings/first-contact/list"
								class="dropdown-item hover:bg-white/5" (click)="ResetFilter('PrimerContacto')">
								<span class="material-icons w-4 h-4 mr-2 text-lg leading-4 align-middle justify-center flex" size="16px"> support_agent </span>
								Primer Contacto
							</a>
						</li>
						<li>
							<a routerLink="/dashboard/settings/groups/list" class="dropdown-item hover:bg-white/5"
								(click)="ResetFilter('Grupos')">
								<ng-icon class="mr-2 pointer-events-none" name="feather-users" size="16px"></ng-icon>
								Grupos
							</a>
						</li>
						<li>
							<a routerLink="/dashboard/settings/flowcharts-formats/list"
								class="dropdown-item hover:bg-white/5" (click)="ResetFilter('FlujogramaFormatos')">
								<ng-icon class="mr-2 pointer-events-none" name="feather-git-pull-request" size="16px"></ng-icon>
								Flujos y Formatos
							</a>
						</li>
					</ul>
				</div>
			</div>

			<div class="intro-x w-8 h-8 relative">
				<div (click)="isMenuOpen = !isMenuOpen" #toggleButton
					class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110"
					style="box-shadow: 0px 0px 0px 1px #1e3a8a" role="button">
					<div class="pointer-events-none">
						<img
							[src]="UserDataFull.url_picture ? UserDataFull.url_picture : 'assets/img/placeholders/profile.png'" />
					</div>
				</div>
				<div #menu [ngClass]="isMenuOpen ? 'show' : ''" class="dropdown-menu w-56 absolute"
					style="inset: auto auto auto auto; transform: translate(0px, 0px); top: 35px; right: -3px">
					<ul
						class="dropdown-content bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white">
						<li class="p-2">
							<div class="font-medium">{{ this.UserDataFull.names + ' ' + this.UserDataFull.last_names |
								titlecase }}</div>
							<div class="text-xs text-white/60 mt-0.5">{{ UserDataFull.email | lowercase }}</div>
						</li>
						<li>
							<hr class="dropdown-divider border-white/[0.08]" />
						</li>
						<li>
							<a routerLink="/dashboard/security" class="dropdown-item hover:bg-white/5">
								<ng-icon class="mr-2 pointer-events-none" name="feather-user" size="16px"></ng-icon>
								Perfil
							</a>
						</li>
						<li>
							<a href="https://uisr.notion.site/Gu-a-de-utilizaci-n-de-la-plataforma-TechEscucha-d1e75d47de2b4de2a01db5a0ef97770c"
								class="dropdown-item hover:bg-white/5" target="_blank">
								<ng-icon class="mr-2 pointer-events-none" name="feather-help-circle"
									size="16px"></ng-icon>
								Ayuda
							</a>
						</li>
						<li>
							<hr class="dropdown-divider border-white/[0.08]" />
						</li>
						<li>
							<a routerLink="/" (click)="logout()" class="dropdown-item hover:bg-white/5">
								<ng-icon class="mr-2 pointer-events-none" name="feather-toggle-right"
									size="16px"></ng-icon>
								Salir
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</div>