import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { Select2 } from 'src/app/core/interfaces/select2';
import AirDatepicker from 'air-datepicker';
import localeEs from 'air-datepicker/locale/es';
import moment from 'moment';
import { UserData } from 'src/app/core/interfaces/user-data';
import { select, Store } from '@ngrx/store';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { CustomValidators } from 'src/app/core/exports/custom-validators';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { surtechUtils } from 'src/app/core/exports/surtechUtils';
import { ActivatedRoute, ParamMap } from '@angular/router';
@Component({
	selector: 'app-overlay-risk-assessment',
	templateUrl: './overlay-risk-assessment.component.html',
	styleUrls: ['./overlay-risk-assessment.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class OverlayRiskAssessmentComponent implements OnInit, AfterViewInit {
	private _unsubscribe = new Subject<void>();
	@Input() disabled = false;
	@Input() complaintData!: any;
	@Input() lists!: any;
	loading: boolean = false;
	@Output() readonly onComplete = new EventEmitter<any>();
	assessmentData: any;
	@ViewChild('ElementRef', { static: false }) ElementRef!: ElementRef;
	UserData$?: Observable<any>;
	UserDataFull: UserData = {} as UserData;
	processId: number | null = null;
	htmlTest: any = `<div class="row">1</div>`
	complainantList: Select2[] = [
		{
			text: 'Maestra',
			id: '1',
		},
		{
			text: 'Alumna',
			id: '2',
		},
		{
			text: ' Trabajadora administrativa',
			id: '3',
		},
		{
			text: 'Directiva',
			id: '4',
		},
		{
			text: 'Maestro',
			id: '5',
		},
		{
			text: 'Alumno',
			id: '6',
		},
		{
			text: 'Trabajador administrativo',
			id: '7',
		},
		{
			text: 'Directivo',
			id: '8',
		},
		{
			text: 'Otro (Especificar)',
			id: '9',
		},
	];
	responsibleList: Select2[] = [
		{
			text: 'Maestra',
			id: '1',
		},
		{
			text: 'Alumna',
			id: '2',
		},
		{
			text: ' Trabajadora administrativa',
			id: '3',
		},
		{
			text: 'Directiva',
			id: '4',
		},
		{
			text: 'Maestro',
			id: '5',
		},
		{
			text: 'Alumno',
			id: '6',
		},
		{
			text: 'Trabajador administrativo',
			id: '7',
		},
		{
			text: 'Directivo',
			id: '8',
		},
	];

	PropertyJson: any = {
		'risk-assessment': 'risk-assessment',
		personal_complaint: [
			{
				text: 'La persona denunciante (o la persona señalada como afectada) tiene menos de 18 años.',
				art: ['ART 17, NUM 3, FRACC I y NUM 4)'],
				group: 'URGENCIA',
				value: null,
			},
			{
				text: 'Se denuncia violencia sexual o física con lesiones evidentes.',
				group: 'URGENCIA',
				value: null,
			},
			{
				text: 'La persona denunciante (o la persona señalada como afectada) se presenta en crisis emocional o con evidentes secuelas psicológicas o emocionales (llanto, temor, ansiedad, etc.)',
				art: ['ART 3 III (Art. 33 Fracc II)'],
				group: 'URGENCIA',
				value: null,
			},
			{
				text: 'La diferencia de edad con la persona señalada como presunto responsable es de diez años o más',
				art: ['ART 87 Fracc I, inciso a)'],
				group: 'URGENCIA',
				value: null,
			},
			{
				text: 'La persona denunciante (o la persona señalada como afectada) es sobreviviente de otras violencias (sexual, física, digital, etc. por  éste/ésta u otras personas agresoras en otros contextos).',
				art: ['ART 87 Fracc I, inciso b)'],
				group: 'URGENCIA',
				value: null,
			},
			{
				text: 'La persona denunciante (o la persona señalada como afectada) cuenta con red de apoyo (familia, amistades, compañeros/as de grupo).',
				art: ['ART 87 Fracc I, inciso b)'],
				group: 'URGENCIA',
				value: null,
			},
			{
				text: 'La persona denunciante (o la persona señalada como afectada) se encuentra en alguno de los supuestos de vulnerabilidad (discapacidad, embarazo, grupo étnico, diversidad sexual, etc.).',
				art: ['ART 87 Fracc I, inciso b)'],
				group: 'URGENCIA',
				value: null,
			},
			{
				text: 'La más reciente violencia sexual se propició hace menos de un año a la persona denunciante (o a la persona señalada como afectada).',
				group: 'GRAVEDAD',
				value: null,
			},
			{
				text: 'Existen lesiones físicas',
				group: 'GRAVEDAD',
				value: null,
			},
			{
				text: 'Las lesiones físicas tardan más de 15 días en sanar.',
				group: 'GRAVEDAD',
				value: null,
			},
			{
				text: 'Antes de la violencia denunciada ha habido otras interacciones (ejemplificar) de la persona señalada como presunto responsable con la persona señalada como afectada, sus familiares,  amistades o personas cercanas.',
				group: 'GRAVEDAD',
				value: null,
			},
			{
				text: 'Después de la violencia denunciada ha habido otras interacciones (ejemplificar) de la persona señalada como presunto responsable con la persona señalada como afectada, sus familiares, amistades o personas cercanas.',
				group: 'GRAVEDAD',
				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable cuenta con (o puede acceder a) los datos personales de la denunciante  (o persona señalada como afectada): dirección de vivienda, teléfono de casa, número de celular, datos familiares, etc.',
				group: 'GRAVEDAD',
				value: null,
			},
			{
				text: 'La persona denunciante (o la persona señalada como afectada) refiere que la persona señalada como presunto responsable la ha amenazado de alguna forma, la ha seguido, la ha humillado o coaccionado públicamente.',
				group: 'GRAVEDAD',
				value: null,
			},
			{
				text: 'La violencia referida se ha dado en más de una ocasión.',
				art: ['ART 70'],
				group: 'FRECUENCIA',

				value: null,
			},
			{
				text: 'Se refieren diferentes interacciones que indiquen que va avanzando un plan de la persona señalada como presunto responsable, aplica para cualquier tipo de violencia (por ejemplo: inició por pedirle una entrevista privada para hablarle de un trabajo escolar, luego la invitó a salir, le ha hecho cada vez más frecuentes llamadas, etc.)',
				group: 'FRECUENCIA',

				value: null,
			},
			{
				text: 'Existen interacciones donde la denunciante (o la persona señalada como afectada) y la persona señalada como presunto responsable  se encuentren solos o en lugares apartados o cerrados.',
				group: 'FRECUENCIA',
				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable es un hombre.',
				art: ['ART 87 Fracc I, inciso a)'],
				group: 'POSICIÓN DE PODER',
				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable es titular u ocupa un puesto alto o medio en la dependencia.',
				art: ['ART 87 Fracc I, inciso a)'],
				group: 'POSICIÓN DE PODER',
				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable goza de reconocimiento formal, social, político o simbólico. (Por ejemplo: es un investigador reconocido, sale en la tv u otros medios, participa en la política universitaria, es un alumno popular, etc.)',
				art: ['ART 87 Fracc I, inciso a)'],
				group: 'POSICIÓN DE PODER',
				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable tiene una antigüedad mayor a 5 años en el mismo puesto.',
				group: 'POSICIÓN DE PODER',
				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable se ha jactado de tener amistad con jefes u otro tipo de influencia política, judicial o criminal.',
				group: 'POSICIÓN DE PODER',
				art: ['ART 87 Fracc I, inciso a)'],
				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable ha amenazado a la denunciante (o a la persona señalada como afectada) con otras represalias derivadas de su puesto o posición jerárquica que tiene.',
				art: ['ART 70'],
				group: 'POSICIÓN DE PODER',
				value: null,
			},
			{
				text: 'La denunciante (o la persona señalada como afectada) ha sufrido represalias por parte de la persona señalada como presunto responsable o sus aliados.',
				art: ['ART 70'],
				group: 'POSICIÓN DE PODER',
				value: null,
			},
			{
				text: 'La denunciante (o la persona señalada como afectada)   ha comentado su situación con algunas personas dentro de la institución y dichas personas no lo consideraron real o posible o lo consideran un malentendido.',
				art: ['ART 87 Fracc I, inciso a)'],
				group: 'POSICIÓN DE PODER',
				value: null,
			},
			{
				text: 'Se sabe de otras conductas violentas hacia otras personas por parte de la persona señalada como presunto responsable, aunque no se tengan denuncias formales o conocimiento cabal de los casos.',
				art: ['ART 70'],
				group: 'PROBABLE RECURRENCIA DE LA CONDUCTA DENUNCIADA',
				value: null,
			},
			{
				text: 'En la dependencia se sabe que la persona señalada como presunto responsable es una persona poco tolerante, autoritaria, violenta, que se expresa a gritos y/o que ha tenido problemas con otras personas.',
				art: ['ART 90'],
				group: 'PROBABLE RECURRENCIA DE LA CONDUCTA DENUNCIADA',
				value: null,
			},
			{
				text: 'La denunciante (o la persona señalada como afectada) recurre a nosotros/as en una acción desesperada.',
				group: 'INMINENCIA DEL RIESGO',

				value: null,
			},
			{
				text: 'La persona señalada como presunto responsable ha proferido amenazas contra la denunciante (o contra la persona señalada como afectada), su familia o personas cercanas.',
				art: ['ART 70'],
				group: 'INMINENCIA DEL RIESGO',

				value: null,
			},
			{
				text: 'Se sabe (por su dicho o por verlas) que la persona señalada como presunto responsable tiene armas o vínculos con personas que las tienen.',
				art: ['ART 90'],
				group: 'INMINENCIA DEL RIESGO',

				value: null,
			},
			{
				text: 'Otros (especificarlos)',
				group: 'INMINENCIA DEL RIESGO',

				value: null,
			},
		],
		sexual_violence: [
			{
				text: '… realizado silbidos, señales con las manos o a través de los movimientos del cuerpo, miradas o gestos con connotación sexual. ',
				value: null,
			},
			{
				text: '… tenido contacto físico, sugestivo o de naturaleza sexual sin consentimiento ( o aun con su consentimiento cuando se trate de niñas, niños y adolescentes), como tocamientos, abrazos, besos, manoseo, jalones, acorralamiento, pellizcos, roces. ',
				value: null,
			},
			{
				text: '… manifestado de forma reiterada y sin consentimiento, directa o indirectamente el interés sexual por una persona, ya sea de manera presencial o por cualquier medio de comunicación (redes sociales ej. whatsapp, etc.)',
				value: null,
			},
			{
				text: '… realizado conductas dominantes, agresivas, intimidatorias u hostiles hacia una persona para que se someta a sus deseos y/o intereses sexuales, o al de alguna otra u otras personas.',
				value: null,
			},
			{
				text: '… espiado a una persona mientras se cambia de ropa o está en el sanitario.',
				value: null,
			},
			{
				text: '… presionado para obtener fotografías o imágenes en cualquier circunstancia o con el agravante de que sea en ropa íntima o desnuda.',
				value: null,
			},
			{
				text: '… exhibido fotografías o imágenes con contenido pornográfico o en ropa interior con intenciones sugerentes de carácter sexual.',
				value: null,
			},
			{
				text: '… realizado promesas para obtener un beneficio personal a cambio de favores de naturaleza sexual.',
				value: null,
			},
			{
				text: '… condicionado la prestación de un trámite o servicio o evaluación escolar a cambio de que la persona acceda a acudir a citas fuera del ámbito académico y escolar o realizar conductas sexuales de cualquier naturaleza.',
				value: null,
			},
			{
				text: '… expresado comentarios, burlas, piropos o bromas hacia otra persona individualmente o en grupo que hagan referencia a la apariencia o con connotación sexual, bien sean presenciales o a través de medios digitales, incluyendo redes sociales.',
				value: null,
			},
			{
				text: '… realizado comentarios, burlas, chistes o bromas sugerentes respecto de la vida sexual de otra persona, sean de manera presencial o a través de medios digitales.',
				value: null,
			},
			{
				text: '… expresado insinuaciones, invitaciones, favores o propuestas a citas o encuentros de carácter sexual de forma reiterada y sin consentimiento (o aun con su consentimiento cuando se trate de niñas, niños y adolescentes), fuera de las instalaciones universitarias.',
				value: null,
			},
			{
				text: '… preguntado a una persona sobre historias, fantasías u orientación sexual o sobre su sexualidad, sin consentimiento (o aun con su consentimiento cuando se trate de niñas, niños y adolescentes). ',
				value: null,
			},
			{
				text: '… exhibido o enviado a través de un medio físico o digital, contenido de naturaleza sexual, no deseadas ni solicitadas por la persona receptora. ',
				value: null,
			},
			{
				text: '… difundido rumores o cualquier tipo de información sobre la vida sexual de una persona.',
				value: null,
			},
			{
				text: '…mostrado deliberadamente partes íntimas del cuerpo a una o varias personas, sin consentimiento (o aun con su consentimiento cuando se trate de niñas, niños y adolescentes).',
				value: null,
			},
			{
				text: '… video grabado, audio grabado, fotografiado o elaborado vídeos reales o simulados, memes o gifs de contenido sexual íntimo de una persona sin su consentimiento (aun con su consentimiento cuando se trate de niñas, niños y adolescentes) o mediante engaño.',
				value: null,
			},
			{
				text: '… expuesto, distribuido, difundido, exhibido, reproducido, transmitido, comercializado, ofertado, intercambiar y compartir imágenes, conversaciones, audios o videos de contenido sexual íntimo de una persona a sabiendas de que no existe consentimiento  (aun con su consentimiento cuando se trate de niñas, niños y adolescentes), mediante materiales impresos, correo electrónico, mensajes telefónicos, redes sociales o cualquier medio tecnológico',
				value: null,
			},
			{
				text: '… grabado, difundido o amenazar con difundir información, imágenes o videos íntimos o de contenido sexual de una persona integrante de la comunidad universitaria sin su consentimiento, o aun con su consentimiento cuando se trate de niñas, niños y adolescentes. ',
				value: null,
			},
			{
				text: 'Coaccionar a una persona integrante de la comunidad universitaria, para que realice un acto u omisión en contra de su voluntad, utilizado como amenaza el difundir información, imágenes o videos íntimos o de contenido sexual. ',
				value: null,
			},
			{
				text: 'Acosar sexualmente, entendiéndose como tal, el asedio con fines o móviles sexuales, a cualquier persona integrante de la comunidad universitaria',
				value: null,
			},
			{
				text: 'Hostigar sexualmente, entendiéndose como tal, el asedio con fines o móviles sexuales, valiéndose de su posición jerárquica en sus relaciones laborales, docentes o cualquier otra que implique subordinación de la víctima, a cualquier persona integrante de la comunidad universitaria.',
				value: null,
			},
			{
				text: 'Abusar sexualmente de una persona integrante de la comunidad universitaria, entendiéndose como tal, realizar tocamientos o manoseos corporales obscenos o cualquier otro acto erótico-sexual sin consentimiento  (aun con su consentimiento cuando se trate de niñas, niños y adolescentes), u obligar a la víctima a realizarlos.',
				value: null,
			},
			{
				text: '… cometido el delito de violación, en los términos establecidos en la legislación penal aplicable al Estado de Jalisco (incorporar artículo e instrumento normativo)',
				value: null,
			},
			{
				text: '… cometido conductas ilícitas (aclarar cuáles) de carácter sexual en contra de cualquier persona integrante de la comunidad universitaria. ',
				value: null,
			},
		],
		school_violence: [
			{
				text: '… provocado de manera intencional un daño parcial o total en las pertenencias de otra alumna, alumno o alumne. (P) ',
				value: null,
			},
			{
				text: '… realizado o incitado a realizar, agresiones verbales mediante insultos, burlas, rumores, apodos, comentarios con el fin de causar menosprecio o humillación en contra de otra alumna, alumno o alumne. ',
				value: null,
			},
			{
				text: '…realizado acciones dirigidas a desvalorar, intimidar o controlar las acciones, comportamientos y decisiones de la víctima, las cuales pueden consistir en prohibiciones, coacciones, persecución, exclusión social, condicionamientos, sometimiento, manipulación, amenaza, indiferencia, chantaje, humillaciones, comparaciones destructivas, abandono o actitudes devaluatorias, incluidas las gesticulaciones y obscenidades mediante señas, miradas o expresiones corporales.',
				value: null,
			},
			{
				text: '…agredido físicamente a otra alumna, alumno o alumne, con intención de causar daño corporal.',
				value: null,
			},
		],
		workplace_violence: [
			{
				text: '…maltratado (insultos, burlas, humillaciones y/o ridiculizaciones) a otro/a integrante del personal académico, administrativo o directivo, realizados de manera continua y persistente (más de una vez y/o en diferentes ocasiones).',
				value: null,
			},
			{
				text: '… realizado actos que dañan la estabilidad psicológica, la personalidad, la dignidad o integridad de otro integrante del personal académico, administrativo o directivo, los cuales consisten en acciones de intimidación sistemática y persistente, tales como: descrédito, insultos, humillaciones, devaluación, marginación, indiferencia, comparaciones destructivas, rechazo, restricción a la autodeterminación y amenazas, con el fin de llevar a la víctima a la depresión, al aislamiento, y/o a la pérdida de su autoestima.',
				value: null,
			},
			{
				text: '…realizar hostigamiento laboral, como el ejercicio de poder en una relación de subordinación real de la persona víctima frente al agresor(a) en el ámbito laboral, que se expresa en conductas verbales, físicas o ambas. Ej. Negar prestación del contrato colectivo',
				value: null,
			},
			{
				text: '…impedir a las mujeres de llevar a cabo el período de lactancia o cualquier otra forma de discriminación. Ej. Decir frases como “ser mamá baja el rendimiento laboral”.',
				value: null,
			},
			{
				text: '…solicitado como requisito de contratación examen de ingravidez.',
				value: null,
			},
		],
		gender_violence: [
			{
				text: '…realizado cualquier acto de violencia por razones de género, entendiéndose como tal, la violencia dirigida a una persona o grupos de personas, con base en su género o sexo, que cause un daño o sufrimiento psicológico, físico, patrimonial, económico, sexual, o cualesquier otro que lesione o sea susceptible de dañar la dignidad, integridad o libertad.',
				value: null,
			},
		],
		hostility_coercion: [
			{
				text: '… impedido la libre manifestación de las ideas, siempre que éstas no afecten los derechos de terceros?',
				value: null,
			},
			{
				text: '… proferido o incitado al discurso de odio en contra de cualquier persona, o contra determinados grupos por razón de cualquier condición o circunstancia personal, étnica o social.',
				value: null,
			},
		],
		respect_consideration: [
			{
				text: '… realizado cualquier acto de discriminación en su contra por razones de origen étnico o nacional, género, edad, discapacidades, condicion social, condiciones de salud, religión, preferencias sexuales, estado civil o cualquier otra que atente contra la dignidad humana? Cuente un punto por cada una de las que se presente .',
				value: null,
			},
		],
	};

	gruop_personal_complaint: any = {};

	constructor(
		private store: Store,
		private formBuilder: FormBuilder,
		private apiService: ApiService,
		private toastr: ToastrService,
		private route: ActivatedRoute
	) {
		this.PropertyJson['personal_complaint'].map((el: any, i: number) => (el.id = i + 1));
		this.PropertyJson['sexual_violence'].map((el: any, i: number) => (el.id = i + 1));
		this.PropertyJson['school_violence'].map((el: any, i: number) => (el.id = i + 1));
		this.PropertyJson['workplace_violence'].map((el: any, i: number) => (el.id = i + 1));
		this.PropertyJson['gender_violence'].map((el: any, i: number) => (el.id = i + 1));
		this.PropertyJson['hostility_coercion'].map((el: any, i: number) => (el.id = i + 1));
		this.PropertyJson['respect_consideration'].map((el: any, i: number) => (el.id = i + 1));
		this.route.paramMap.subscribe((params: ParamMap) => {
			this.processId = +(params.get('id') as any);
		});
	}
	clickElementRef() {
		this.ElementRef.nativeElement.click();
	}
	ngAfterViewInit(): void {
		this.assessmentData.get('personal_complaint').valueChanges.subscribe(change => {
			this.SelectionArtPersonalComplaint();
		});
		this.assessmentData.get('respect_consideration').valueChanges.subscribe(change => {
			this.SelectionArt();
		});
		this.assessmentData.get('hostility_coercion').valueChanges.subscribe(change => {
			this.SelectionArt();
		});
		this.assessmentData.get('sexual_violence').valueChanges.subscribe(change => {
			this.SelectionArt();
		});
		this.assessmentData.get('school_violence').valueChanges.subscribe(change => {
			this.SelectionArt();
		});
		this.assessmentData.get('workplace_violence').valueChanges.subscribe(change => {
			this.SelectionArt();
		});
		this.assessmentData.get('gender_violence').valueChanges.subscribe(change => {
			this.SelectionArt();
		});
	}

	ngOnInit(): void {
		this.initializeLib();
		this.UserData$ = this.store.pipe(select(UserDataFull));
		this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe(data => {
			this.UserDataFull = data;
		});

		this.assessmentData = this.formBuilder.group({
			responsible_assessment: [{ value: this.UserDataFull.id_user ? `${this.UserDataFull.names} ${this.UserDataFull.last_names} ` : '', disabled: this.UserDataFull.id_user ? true : false }, Validators.required],
			file: [{ value: this.UserDataFull.id_user ? this.complaintData?.code : '', disabled: this.UserDataFull.id_user ? true : false }, Validators.required],
			date_assessment: [{ value: moment(new Date()).format('MM-DD-YYYY'), disable: true }, Validators.required],
			date_facts: [{ value: null, disable: false }, Validators.required],
			complainant: ['', [Validators.required, CustomValidators.select2(this.complainantList)]],
			responsible: ['', [Validators.required, CustomValidators.select2(this.responsibleList)]],

			personal_complaint_factor_analysis: [
				{
					value: `El análisis de los factores de riesgos evaluados nos permite sugerir que con base en los artículos... `,
					disabled: true,
				},
				Validators.required,
			],
			personal_complaint_foreseeable_circumstances: ['', Validators.required],

			factor_analysis: [
				{
					value: `El análisis de los factores de riesgos evaluados nos permite sugerir que con base en los artículos... `,
					disabled: true,
				},
				Validators.required,
			],
			foreseeable_circumstances: ['', Validators.required],
			personal_complaint: this.formBuilder.array(
				this.PropertyJson.personal_complaint.map(x =>
					this.formBuilder.group(
						{
							personal_complaint_value: this.formBuilder.control(x.value)
						}
					)
				)
			),
			respect_consideration: this.formBuilder.array(
				this.PropertyJson.respect_consideration.map(x =>
					this.formBuilder.group({
						respect_consideration_value: this.formBuilder.control(x.value),
					})
				)
			),
			hostility_coercion: this.formBuilder.array(
				this.PropertyJson.hostility_coercion.map(x =>
					this.formBuilder.group({
						hostility_coercion_value: this.formBuilder.control(x.value),
					})
				)
			),
			sexual_violence: this.formBuilder.array(
				this.PropertyJson.sexual_violence.map(x =>
					this.formBuilder.group({
						sexual_violence_value: this.formBuilder.control(x.value),
					})
				)
			),
			school_violence: this.formBuilder.array(
				this.PropertyJson.school_violence.map(x =>
					this.formBuilder.group({
						school_violence_value: this.formBuilder.control(x.value),
					})
				)
			),
			workplace_violence: this.formBuilder.array(
				this.PropertyJson.workplace_violence.map(x =>
					this.formBuilder.group({
						workplace_violence_value: this.formBuilder.control(x.value),
					})
				)
			),
			gender_violence: this.formBuilder.array(
				this.PropertyJson.gender_violence.map(x =>
					this.formBuilder.group({
						gender_violence_value: this.formBuilder.control(x.value),
					})
				)
			),
		});
	}
	initializeLib() {
		setTimeout(() => {
			new AirDatepicker('#date_assessmentDn', {
				locale: localeEs,
				onSelect: e => {
					if (e.formattedDate) {
						this.assessmentData.controls['date_assessment'].setValue((e.formattedDate as string).replace('/', '-').replace('/', '-'));
					} else {
						this.assessmentData.controls['date_assessment'].setValue(e.formattedDate);
					}
				},
				maxDate: new Date(),
			});
			new AirDatepicker('#date_factsDn', {
				locale: localeEs,
				onSelect: e => {
					if (e.formattedDate) {
						this.assessmentData.controls['date_facts'].setValue((e.formattedDate as string).replace('/', '-').replace('/', '-'));
					} else {
						this.assessmentData.controls['date_facts'].setValue(e.formattedDate);
					}
				},
				maxDate: new Date(),
			});
		}, 0);
	}
	checkSchoolViolence(): boolean {
		if ((this.assessmentData.controls['complainant'].value == '2' || this.assessmentData.controls['complainant'].value == '6' || this.assessmentData.controls['complainant'].value == '9') && (this.assessmentData.controls['responsible'].value == '2' || this.assessmentData.controls['responsible'].value == '6')) {
			return false;
		}
		return true;
	}
	checkWorkplaceViolence(): boolean {
		if (this.assessmentData.controls['complainant'].value != '2' && this.assessmentData.controls['complainant'].value != '6' && this.assessmentData.controls['responsible'].value != '2' && this.assessmentData.controls['responsible'].value != '6') {
			return false;
		}
		return true;
	}

	CheckSum(): {
		text: string;
		color: string;
		count_text: string;
	} {
		let respect_consideration_count = this.assessmentData.controls['respect_consideration'].value.filter(el => el.respect_consideration_value?.value == 1).length;
		let hostility_coercion_count = this.assessmentData.controls['hostility_coercion'].value.filter(el => el.hostility_coercion_value?.value == 1).length;
		let sexual_violence_count = this.assessmentData.controls['sexual_violence'].value.filter(el => el.sexual_violence_value?.value == 1).length;
		let school_violence_count = this.assessmentData.controls['school_violence'].value.filter(el => el.school_violence_value?.value == 1).length;
		let workplace_violence_count = this.assessmentData.controls['workplace_violence'].value.filter(el => el.workplace_violence_value?.value == 1).length;
		let gender_violence_count = this.assessmentData.controls['gender_violence'].value.filter(el => el.gender_violence_value?.value == 1).length;

		let total = respect_consideration_count + hostility_coercion_count + sexual_violence_count + school_violence_count + workplace_violence_count + gender_violence_count;

		if (total >= 1 && total <= 10) {
			return {
				text: 'RIESGO MODERADO',
				color: 'bg-sky-400',
				count_text: `Se seleccionó ${total} entre la consideración de 1 a 10 respuestas afirmativas`,
			};
		} else if (total >= 11 && total <= 20) {
			return {
				text: 'RIESGO ALTO',
				color: 'bg-amber-400',
				count_text: `Se seleccionó ${total} entre la consideración de 11  a 20 respuestas afirmativas`,
			};
		} else if (total >= 21) {
			return {
				text: 'RIESGO MUY ALTO',
				color: 'bg-rose-400',
				count_text: `Se seleccionó ${total} entre la consideración de 21 o mas respuestas afirmativas`,
			};
		} else {
			return {
				text: 'SIN SELECCIÓN',
				color: 'bg-stone-400',
				count_text: '',
			};
		}
	}
	CheckSumPersonalComplaint(): {
		text: string;
		color: string;
		count_text: string;
	} {
		let personal_complaint_count = this.assessmentData.controls['personal_complaint'].value;
		personal_complaint_count = personal_complaint_count.slice(0, -1);
		personal_complaint_count = personal_complaint_count.filter(el => el.personal_complaint_value?.value == 1).length;

		let total = personal_complaint_count;

		if (total >= 1 && total <= 3) {
			return {
				text: 'RIESGO MODERADO',
				color: 'bg-sky-400',
				count_text: `Se seleccionó ${total} entre la consideración de 1 a 3 respuestas afirmativas`,
			};
		} else if (total >= 4 && total <= 10) {
			return {
				text: 'RIESGO ALTO',
				color: 'bg-amber-400',
				count_text: `Se seleccionó ${total} entre la consideración de 3 a 10 respuestas afirmativas`,
			};
		} else if (total >= 11) {
			return {
				text: 'RIESGO MUY ALTO',
				color: 'bg-rose-400',
				count_text: `Se seleccionó ${total} entre la consideración de 11 o mas respuestas afirmativas`,
			};
		} else {
			return {
				text: 'SIN SELECCIÓN',
				color: 'bg-stone-400',
				count_text: '',
			};
		}
	}


	SelectionArtPersonalComplaint() {
		let personal_complaint_count: string[] = [];
		this.PropertyJson['personal_complaint'].forEach((el: any) => {
			this.assessmentData.get('personal_complaint').value.forEach((el_2: any) => {
				if (el.id == el_2.personal_complaint_value?.id && el.art?.length > 0 && el_2.personal_complaint_value.value == 1) {
					el.art.forEach((el_3: string) => {
						personal_complaint_count.push(el_3);
					});
				}
			});
		});

		let text = 'El análisis de los factores de riesgos evaluados nos permite sugerir que con base en los artículos ';
		if (personal_complaint_count.length > 0) {
			if (personal_complaint_count.length == 1) {
				text = 'El análisis de los factores de riesgos evaluados nos permite sugerir que con base en el artículo ' + personal_complaint_count[0];
			} else {
				personal_complaint_count.forEach((el: any, i: number) => {
					if (personal_complaint_count.length == i + 1) {
						text = text + `y ${el} `;
					} else if (personal_complaint_count.length - 1 == i + 1) {
						text = text + `${el} `;
					} else {
						text = text + `${el}, `;
					}
				});
			}
			this.assessmentData.get('personal_complaint_factor_analysis').setValue(text);
		}
	}
	SelectionArt() {
		let all_count: string[] = [];
		let sum_respect_consideration = this.assessmentData.get('respect_consideration').value;
		if (sum_respect_consideration != null) {
			sum_respect_consideration = sum_respect_consideration.filter((el_2: any) => el_2.respect_consideration_value?.value == 1);
			if (sum_respect_consideration.length > 0) {
				all_count.push('ART 09');
			}
		}

		let sum_hostility_coercion = this.assessmentData.get('hostility_coercion').value;
		if (sum_hostility_coercion != null) {
			sum_hostility_coercion = sum_hostility_coercion.filter((el_2: any) => el_2.hostility_coercion_value?.value == 1);
			if (sum_hostility_coercion.length > 0) {
				all_count.push('ART 10');
			}
		}

		let sum_sexual_violence = this.assessmentData.get('sexual_violence').value;
		if (sum_sexual_violence != null) {
			sum_sexual_violence = sum_sexual_violence.filter((el_2: any) => el_2.sexual_violence_value?.value == 1);
			if (sum_sexual_violence.length > 0) {
				all_count.push('ART 17');
			}
		}

		let sum_school_violence = this.assessmentData.get('school_violence').value;
		if (sum_school_violence != null) {
			sum_school_violence = sum_school_violence.filter((el_2: any) => el_2.school_violence_value?.value == 1);
			if (sum_school_violence.length > 0) {
				all_count.push('ART 18');
			}
		}

		let sum_workplace_violence = this.assessmentData.get('workplace_violence').value;
		if (sum_workplace_violence != null) {
			sum_workplace_violence = sum_workplace_violence.filter((el_2: any) => el_2.workplace_violence_value?.value == 1);
			if (sum_workplace_violence.length > 0) {
				all_count.push('ART 19');
			}
		}

		let sum_gender_violence = this.assessmentData.get('gender_violence').value;
		if (sum_gender_violence != null) {
			sum_gender_violence = sum_gender_violence.filter((el_2: any) => el_2.gender_violence_value?.value == 1);
			if (sum_gender_violence.length > 0) {
				all_count.push('ART 20');
			}
		}

		let text = 'El análisis de los factores de riesgos evaluados nos permite sugerir que con base en los artículos ';
		if (all_count.length > 0) {
			if (all_count.length == 1) {
				text = 'El análisis de los factores de riesgos evaluados nos permite sugerir que con base en el artículo ' + all_count[0];
			} else {
				all_count.forEach((el: any, i: number) => {
					if (all_count.length == i + 1) {
						text = text + `y ${el} `;
					} else if (all_count.length - 1 == i + 1) {
						text = text + `${el} `;
					} else {
						text = text + `${el}, `;
					}
				});
			}
			this.assessmentData.get('factor_analysis').setValue(text);
		}
	}

	checkResponseFormArray(value: number) {
		if (value == 0) {
			return 'No.';
		}
		if (value = 1) {
			return 'Si.';
		}
		return 'Se desconoce.';
	}

	getTitle(key: string) {
		switch (key) {
			case 'date_assessment':
				return 'Fecha de valoración';
				break;
			case 'date_facts':
				return 'Fecha de los hechos';
				break;
			case 'factor_analysis':
				return 'CIRCUNSTANCIAS PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO';
				break;
			case 'personal_complaint_foreseeable_circumstances':
				return 'CIRCUNSTANCIAS PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO';
				break;
			case 'responsible_assessment':
				return 'Responsable de la valoración';
				break;
			case 'foreseeable_circumstances':
				return 'CIRCUNSTANCIAS PREVISIBLES QUE PUEDEN AUMENTAR EL NIVEL DE RIESGO EN EL FUTURO';
				break;
			case 'file':
				return 'Folio';
			default:
				return key;
				break;
		}
	}

	formSubmit(event: any) {

		const formattedData = {};
		for (const obj of Object.keys(this.assessmentData.controls)
			.map((key) => ({ [key]: this.assessmentData.controls[key].value })
			)) {
			const keys = Object.keys(obj);
			if (keys.length === 1) {
				const key = keys[0];
				formattedData[key] = obj[key];
			}
		}
		let dataShowNote: any[] = [] 
		Object.keys(formattedData).forEach((key) => {

			if (key == 'complainant' || key == 'responsible') return

			if (_.isArray(formattedData[key])) {
				formattedData[key].forEach((el: any, index: number) => {
					dataShowNote.push(`<strong>${this.PropertyJson[key][index].text}${this.PropertyJson[key][index].art ? `${this.PropertyJson[key][index].art.join(',')}` : ''}:</strong><br/>R: ${el[`${key}_value`] ? this.checkResponseFormArray(el[`${key}_value`].value) : 'Sin registro'}`)
				})
				return
			}
			if (_.isString(formattedData[key])) {
				dataShowNote.push(`<strong>${this.getTitle(key)}</strong><br/>R: ${formattedData[key]}`)
				return
			}
			if (_.isObject(formattedData[key])) {
				dataShowNote.push(`<strong>${this.getTitle(key)}</strong><br/>R: ${formattedData[key].value}`)
				return
			}
		})

		this.htmlTest = `
		<h2>Valoración de riesgo</h2>
		<br/>
		<ul>`;
		dataShowNote.forEach((el: any, index: number) => {
			this.htmlTest += `<li style="margin-bottom: 10px;"><strong>${index + 1})</strong> ${el} </li>`
		})
		this.htmlTest += `</ul>` 
 
		Swal.fire({
			...(surtechUtils.alertDefaults as any),
			...{
				icon: 'question',
				title: surtechUtils.statusMessages('overlay-agregar-nota-pregunta'),
				showCancelButton: true,
			},
		}).then((data: any) => {
			if (data.isConfirmed) {
				this.toastr.info(surtechUtils.statusMessages('agregando-nota-denuncia'), undefined, {
					disableTimeOut: true,
					tapToDismiss: false,
					closeButton: false,
				});

				this.loading = true;
				surtechUtils.lockDocument();

				let data: any = {
					id_complaint: this.processId,
					nota: this.htmlTest
				};
				this.apiService.overlay_note_create(data).subscribe({
					next: _data => {
						this.toastr.clear();
						this.loading = false;
						surtechUtils.unlockDocument();

						if (_data.success) {
							Swal.fire({
								...(surtechUtils.alertDefaults as any),
								...{
									icon: 'success',
									title: surtechUtils.statusMessages('overlay-agregar-nota-completado'),
								},
							});
							this.onComplete.next(true);
							event.click();
						} else {
							Swal.fire({
								...(surtechUtils.alertDefaults as any),
								...{
									title: surtechUtils.statusMessages('error-overlay-agregar-nota'),
								},
							});
						}
					},
					error: _error => {
						this.toastr.clear();
						this.loading = false;
						surtechUtils.unlockDocument();

						Swal.fire({
							...(surtechUtils.alertDefaults as any),
							...{
								title: surtechUtils.statusMessages('error-overlay-agregar-nota'),
							},
						});
					},
				});
			}
		});
	}

}
