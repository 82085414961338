import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { BaseRoutingModule } from './base-routing.module';
import { BaseComponent } from './base.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { CompleteDataComponent } from '../modules/complete-data/complete-data.component';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { UserReducer } from '../core/reducer/user-data/user-data.reducer';
import { HttpConfigInterceptor } from '../core/services/auth-interceptor/auth-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterReducer } from '../core/reducer/table-filter/table-filter.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    BaseComponent,
    CompleteDataComponent,
  ],
  imports: [
    BrowserModule,
    BaseRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    StoreModule.forRoot({
      UserData: UserReducer,
      Filter:FilterReducer
    }),
    StoreDevtoolsModule.instrument({ 
      logOnly: environment.production ? false : true, // Restrict extension to log-only mode
    }),
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ],
  bootstrap: [BaseComponent]
})
export class BaseModule { }
