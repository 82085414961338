import Swal from 'sweetalert2';
import { TitleCasePipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { CustomValidators } from './custom-validators';

export const surtechUtils = {
	parseDataToSelect2Data: (data: any, key1: string, key2: string, isTitleCase: boolean = true) => {
		let titleCasePipe: TitleCasePipe = new TitleCasePipe();
		let parsedData: any = [];

		if (isTitleCase) {
			data.forEach((element: any) => {
				let seldata = { id: element[key1], text: titleCasePipe.transform(element[key2]) };
				parsedData.push(seldata);
			});
		} else {
			data.forEach((element: any) => {
				let seldata = { id: element[key1], text: element[key2] };
				parsedData.push(seldata);
			});
		}

		return parsedData;
	},

	formatBytes: (bytes: number, decimals: number = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	},

	statusMessages: (code: number | string = 0) => {
		const messages: any = {
			/* Error de servidor */
			'500': 'Ha ocurrido un error desconocido al procesar su solicitud, por favor vuelva a intentarlo',

			/* Ejecutando acciones (toasts)*/
			'registrando-usuario': 'Registrando usuario.',
			'registrando-grupo': 'Registrando grupo.',
			'registrando-formato': 'Registrando formato o flujo.',
			'iniciando-sesion': 'Iniciando sesión.',
			'completando-registro': 'Completando registro de usuario.',
			'validanto-codigo-seguridad': 'Validando código de seguridad.',
			'actualizando-contraseña': 'Actualizando contraseña.',
			'presentando-denuncia': 'Se está presentando su denuncia, por favor no actualice ni salga de esta página hasta que el proceso finalice.',
			'solicitud-valida': 'Solicitud valida.',
			'formato-imagen-valido': 'Imagen cargada, por favor seleccione el área que desea utilizar y de click en establecer imagen.',
			'actualizando-informacion-personal': 'Actualizando su información personal.',
			'actualizando-usuario': 'Actualizando información personal del usuario.',
			'actualizando-grupo': 'Actualizando información del grupo.',
			'actualizando-primer-contacto': 'Actualizando información del primer contacto.',
			'agrego-miembro-grupo': 'Se agrego el miembro al grupo.',
			'agregando-miembro-grupo': 'Agregando Miembro al grupo.',
			'eliminado-miembro-grupo': 'Se removió el miembro del grupo.',
			'recuperando-contraseña': 'Generando solicitud de recuperación.',
			'ingresando-denuncia': 'Validando el código de ingreso.',
			'obteniendo-denuncia': 'Obteniendo información de la denuncia.',
			'obteniendo-archivo': 'Obteniendo información del archivo.',
			'archivo-no-previsualizable': 'Este tipo de archivo no se puede previsualizar pero será descargado.',
			'agendando-contacto': 'Agendando cita con el primer contacto.',
			'agendando-crys': 'Agendando cita de la CRyS.',
			'agendando-audience': 'Agendando cita de la audiencia.',
			'reagendando-contacto': 'Reagendando cita de la CRyS.',
			'archivando-denuncia': 'Archivando denuncia.',
			'cargando-ipr': 'Cargando IPR.',
			'cargando-pruebas': 'Se están cargando sus pruebas, por favor no actualice ni salga de esta página hasta que el proceso finalice.',
			'verificando-requisitos': 'Verificando Requisitos.',
			'actualizando-numero-expediente': 'Actualizando el número del expediente.',
			'actualizando-horario-atencion': 'Actualizando horario de atención.',
			'horario-atencion-pregunta': '¿Está seguro que desea actualizar el horario del grupo seleccionado?',
			'registro-usuario-pregunta': '¿Está seguro que desea agrear el usuario con los datos suministrados?',
			'generando-constancia-presentacion': 'Generando Constancia de Presentación.',
			'generando-constancia-denuncia': 'Generando Constancia de Denuncia.',
			'determinando-violencia': 'Determinando el tipo de violencia.',
			'determinando-radicacion': 'Determinando la radicación.',
			'determinando-competencia': 'Determinando la competencia.',
			'identificando-atencion-integral': 'Identificando la atención integral.',
			'determinando-agravantes': 'Determinando agravantes.',
			'determinando-sancion': 'Determinando sanción.',
			'verificando-señalado': 'Verificando datos de presuntos responsables.',
			'remitiendo-denuncia': 'Remitiendo denuncia.',
			'agregando-nota-denuncia': 'Agregando nota en la denuncia.',
			'registrando-denuncia': 'Registrando denuncia.',
			'aclarando-denuncia': 'Aclarando la denuncia',
			'cargando-documentos': 'Cargando documentos.',
			'solicitando-ratificacion': 'Solicitando la ratificación de la firma del denunciante.',
			'ejecutando-atencion-integral': 'Ejecutando atención integral',
			'close-appoinment': 'Ejecutando atención integral',
			'ratificando-firma': 'Ratificando firma del denunciante.',

			/* Errores generales (toasts) */
			'error-informacion-proporcionada': 'Por favor verifique la información proporcionada.',
			'error-sesion-inactiva': 'Debe tener una sesión activa para continuar.',
			'error-ruta-no-encontrada': '404 Ruta no encontrada.',
			'error-medidas-cautelares': 'Por favor justifique las medidas cautelares seleccionadas.',
			'formato-imagen-invalido': 'Formato de imagen no soportado.',

			/* Errores listados (toasts) */
			'error-listado-estados': 'Ha ocurrido un error desconocido al obtener el listado de estados.',
			'error-listado-municipios': 'Ha ocurrido un error desconocido al obtener el listado de municipios.',
			'error-listado-generos': 'Ha ocurrido un error desconocido al obtener el listado de géneros.',
			'error-listado-personas': 'Ha ocurrido un error desconocido al obtener el listado de géneros.',
			'error-listado-instancias': 'Ha ocurrido un error desconocido al obtener el listado de instancias.',
			'error-listado-subinstancias': 'Ha ocurrido un error desconocido al obtener el listado de sub instancias.',
			'error-listado-grupos-participante': 'Ha ocurrido un error desconocido al obtener el listado de grupos de participantes.',
			'error-listado-tipos-pruebas': 'Ha ocurrido un error desconocido al obtener el listado de los tipos de pruebas.',
			'error-listado-denuncias': 'Ha ocurrido un error desconocido al obtener el listado de las denuncias.',

			/* Registro */
			'titulo-registro-completo': '¡Usuario Registrado!',
			'titulo-registro-grupo-completo': '¡Grupo Registrado!',
			'titulo-actualizacion-completo': '¡Usuario Actualizado!',
			'titulo-actualizacion-grupo-completo': '¡Grupo Actualizado!',
			'titulo-registro-formato-completo': 'Formato o flujo Registrado!',
			'titulo-actualizacion-primer-contacto-completo': '¡Primer Contacto Actualizado!',
			'registro-completo': 'Se ha registrado exitosamente, por favor revise su bandeja de entrada, inicie sesión y complete sus datos para finalizar.',
			'registro-grupo-completo': 'Se ha registrado exitosamente el grupo',
			'registro-formato-completo': 'Se ha registrado exitosamente el formato o flujo',
			'error-registro': 'Ha ocurrido un error desconocido al registrar el usuario, por favor vuelve a intentarlo.',
			'error-creacion-user-correo-existente': 'No se ha podido realizar el registro, el correo ya se encuentra en uso. Por favor registre otro correo o intente recuperar su cuenta.',

			/* Login */
			'titulo-codigo-seguridad': '¡Código de Verificación!',
			'codigo-seguridad': 'Introduce el código de seguridad que te hemos enviado a tu correo.',
			'sesion-iniciada': 'Sesión iniciada.',
			'error-login-invalido': 'Usuario o clave inválido.',
			'error-login-user-no-verificado': 'No ha verificado su correo electrónico, por favor verifique su cuenta mediante el enlace enviado a su correo o intente recuperar su cuenta.', //el usuario debe verificar su correo

			/* Completar Datos */
			'titulo-registro-completado': '¡Registro Completado!',
			'registro-completado': 'Ha completado los datos de su registro exitosamente.',
			'error-registro-completado': 'Ha ocurrido un error desconocido al completar sus datos, por favor vuelve a intentarlo.',
			'advertencia-completar-datos': 'Por favor debe completar sus datos para continuar.',

			/* Validación del correo */
			'titulo-validacion-correo-valida': '¡Usuario Validado!',
			'validacion-correo-valida': 'Se ha validado correctamente su dirección de correo electrónico. Por favor ingrese para completar su registro.',
			'titulo-validacion-correo-invalida': '¡Error!',
			'validacion-correo-invalida': 'Este enlace de validación expiró.',

			/* Recuperacion de Contraseña */
			'titulo-recuperacion-contraseña-valida': '¡Recuperación Solicitada!',
			'recuperacion-contraseña-valida': 'Si su información es correcta, recibirá un correo para recuperar su contraseña.',
			'error-recuperacion-contraseña': 'Ha ocurrido un error desconocido al recuperar su contraseña, por favor vuelve a intentarlo.',
			'error-solicitud-recuperacion-contraseña': 'Esta solicitud de cambio de contraseña es inválida o expiró.',
			'error-cambio-contraseña': 'Ha ocurrido un error desconocido al recuperar su contraseña, por favor vuelve a intentarlo.',
			'titulo-contraseña-actualizada': '¡Contraseña Actualizada!',
			'contraseña-actualizada': 'Su contraseña ha sido actualizada correctamente.',

			/* Agendar crys */
			'subinstancia-primer-contacto-vacio': 'No hay primer contacto asociado a la escuela o dependencia. Por favor inténtelo más tarde.',
			'titulo-agendar-contacto': '¡Agendar Primer Contacto!',
			'titulo-agendar-grupo': '¡Agendar CRyS!',
			'titulo-agendar-grupo-audience': '¡Agendar Audiencia!',
			'titulo-reagendar-grupo': '¡Reagendar CRyS!',
			'titulo-reagendar-contacto': '¡Reagendar Primer Contacto!',
			'titulo-reagendar-grupo-audience': '¡Reagendar Audiencia!',

			'agendar-contacto': '¿Desea agendar su cita con el primer contacto en la fecha: ${fecha}?. Esta acción no se puede deshacer.',
			'reagendar-contacto': '¿Desea reagendar la cita con el primer contacto en la fecha: ${fecha}?. Esta acción no se puede deshacer.',
			'agendar-grupo': '¿Desea agendar la cita con la CRyS en la fecha: ${fecha}?. Esta acción no se puede deshacer.',
			'reagendar-grupo': '¿Desea reagendar la cita con la CRyS en la fecha: ${fecha}?. Esta acción no se puede deshacer.',
			'agendar-grupo-audience': '¿Desea agendar la cita de la audiencia en la fecha: ${fecha}?. Esta acción no se puede deshacer.',
			'reagendar-grupo-audience': '¿Desea reagendar la cita de la audiencia en la fecha: ${fecha}?. Esta acción no se puede deshacer.',
			'titulo-cita-agendada': '¡Cita Agendada!',
			'titulo-cita-reagendada': '¡Cita Reagendada!',
			'info-cita-agendada': 'Información de cita',
			'titulo-cita-eliminada': '¡Cita eliminada!',
			'info-cita-eliminada': 'La cita ha sido eliminada con éxito, por favor vuelva agendar una cita nueva',
			'cita-agendada': 'Se ha agendado su cita correctamente.',
			'cita-reagendada': 'Se ha reagendado su cita correctamente.',
			'usuario-citas-incompletas': 'No fue posible agendar su cita, usted aún tiene citas pendientes en su calendario.',
			'conflicto-otras-citas': 'No fue posible agendar su cita, el primer contacto posee un evento agendado en la fecha seleccionada, por favor recargue la página y vuelva a intentarlo.',

			/* Presentar Denuncia */
			'titulo-presentar-denuncia': '¡Presentación de Denuncia!',
			'presentar-denuncia': '¿Desea continuar y presentar la denuncia?',
			'titulo-denuncia-presentada': '¡Denuncia Presentada!',

			/* Presentar Denuncia (toasts) */
			'error-validar-datos-denunciante': 'Por favor validar los datos de la persona denunciante.',
			'error-invalid-evidence-type': 'Por favor introduzca un tipo de medio válido.',
			'error-invalid-measure-type': 'Por favor introduzca un tipo medida cautelar válida.',
			'error-añadir-victima': 'Por favor debe añadir al menos una víctima a la denuncia.',
			'error-validar-datos-victima-indice': 'Por favor validar los datos de la víctima #${indice}.',
			'error-añadir-tutor-victima': 'Por favor agregar al menos un tutor a la víctima #${indice}.',
			'error-validar-tutor1-victima': 'Por favor validar los datos del tutor 1 de la víctima #${indice}.',
			'error-validar-tutor2-victima': 'Por favor validar los datos del tutor 2 de la víctima #${indice}.',
			'error-añadir-responsable': 'Por favor debe añadir al menos una persona señalada como presunto responsable a la denuncia.',
			'error-validar-datos-responsable-indice': 'Por favor validar los datos del la persona señalada como presunto responsable #${indice}.',
			'error-validar-tutor1-responsable': 'Por favor validar los datos del tutor 1 del la persona señalada como presunto responsable #${indice}.',
			'error-validar-tutor2-responsable': 'Por favor validar los datos del tutor 2 del la persona señalada como presunto responsable#${indice}.',
			'error-añadir-tutor-responsable': 'Por favor agregar al menos un tutor de la persona señalada como presunto responsable #${indice}.',
			'error-descripcion-hechos': 'Por favor describa detalladamente los hechos.',
			'error-validar-testigo': 'Por favor validar los datos de la o el Testigo  #${indice}.',
			'error-observaciones': 'Por favor compruebe sus observaciones.',
			'error-firma': 'Por favor firme para presentar su denuncia.',

			/* Login */
			'login-incorrecto': 'Usuario o clave inválido.',
			'error-login': 'Ha ocurrido un error desconocido al iniciar sesión, por favor vuelve a intentarlo.',

			/* Unirse a denuncia */
			'titulo-ingreso-denuncia': '¡Ingreso Exitoso!',
			'ingreso-denuncia': 'Ha ingresado correctamente al proceso ${codigo} en calidad de ${tipo_participante}.',
			'error-codigo-usado': 'El código ya fue utilizado.',
			'tipo-usuario-erroneo': 'El tipo de usuario es incorrecto',
			'error-actualizar-involved': 'No fue posible realizar la asignación al proceso.',
			'error-denuncia-invalida': 'La denuncia es inválida',

			/* Cambio decontraseña */
			'pregunta-cambio-contraseña': '¿Está seguro que desea cambiar su contraseña?',

			/* Profile */
			'informacion-enviada': 'Su información se ha enviado',
			'actualizado-exitoso': 'Se ha actualizado con éxito su información',
			'actualizado-usuario-exitoso': 'Se ha actualizado con éxito la información del usuario',
			'actualizado-grupo-exitoso': 'Se ha actualizado con éxito la información del grupo',
			'actualizado-primer-contacto-exitoso': 'Se ha actualizado con éxito la información del primer contacto',
			'actualizado-exitoso-usuarios-estado': 'Se ha actualizado con éxito el estado',

			/* Contacto */
			'titulo-contacto-exitoso': '¡Mensaje Enviado!',
			'contacto-exitoso': 'Se ha enviado su información con éxito, nos comunicaremos con usted a la brevedad posible.',

			/* Horario de Atencion */
			'titulo-horario-atencion-exitoso': '¡Horario de Atención Actualizado!',
			'horario-atencion-exitoso': 'Se ha actualizado su horario de atención con éxito.',

			/* Errores en servicios */
			'error-creacion-user-location': 'Ha ocurrido un error desconocido durante la creación del usuario.',
			'error-creacion-user-person': 'Ha ocurrido un error desconocido durante la creación del usuario.',
			'error-creacion-user': 'Ha ocurrido un error desconocido durante la creación del usuario.',
			'error-campos-invalidos': 'Los datos proporcionados son inválidos.',
			'error-user-restringido': 'El usuario no tiene acceso al sistema.',
			'error-user-inhabilitado': 'Tu usuario ha sido deshabilitado. Por favor contacta a los administradores de la plataforma de tu institución.',
			'error-recaptcha-invalido': 'No aprobó la validación de seguridad anti-spam.',
			'user-erroneo': 'Ha ocurrido un error desconocido al obtener los datos del usuario.',
			'error-actualizar-user': 'Ha ocurrido un error desconocido al actualizar los datos del usuario.',
			'error-actualizar-user-status': 'Ha ocurrido un error desconocido al actualizar el estado del usuario.',
			'error-actualizar-user-location': 'Ha ocurrido un error desconocido al actualizar los datos del usuario.',
			'error-actualizar-user-person': 'Ha ocurrido un error desconocido al actualizar los datos del usuario.',
			'error-eliminar-user-picture': 'Ha ocurrido un error desconocido al eliminar la imagen del usuario.',
			'error-actualizar-user-person-picture': 'Ha ocurrido un error desconocido al actualizar la imagen del usuario.',
			'error-actualizar-user-picture': 'Ha ocurrido un error desconocido al actualizar la imagen del usuario.',
			'error-token-expiro': 'La solicitud expiró.',
			'error-codigo-expiro': 'El código expiró',
			'error-actualizar-horario-atencion': 'Ha ocurrido un error desconocido al actualizar el horario de atención.',

			/* Overlay constancia de Presentación */
			'overlay-constancia-presentacion-pregunta': '¿Está seguro que desea generar la Constancia de Presentación?',
			'overlay-constancia-presentacion-completado': 'Se ha generado la Constancia de Presentación exitosamente.',
			'error-overlay-constancia-presentacion': 'Ha ocurrido un error desconocido al generar la Constancia de Presentación.',

			/* Overlay determinar tipo de violencia */
			'overlay-tipo-violencia-pregunta': '¿Está seguro que desea determinar el tipo de violencia?',
			'overlay-tipo-violencia-completado': 'Se ha determinado el tipo de violencia exitosamente.',
			'error-overlay-tipo-violencia': 'Ha ocurrido un error desconocido al determinar el tipo de violencia.',

			/* Overlay determinar tipo de agravantes */
			'overlay-tipo-agravantes-pregunta': '¿Está seguro que desea determinar agravantes?',
			'overlay-tipo-agravantes-completado': 'Se han determinado los agravantes exitosamente.',
			'error-overlay-tipo-agravantes': 'Ha ocurrido un error desconocido al determinar agravantes.',

			/* Overlay identificar atencion integral */
			'overlay-identificar-atencion-integral-pregunta': '¿Está seguro que desea identificar la atención integral?',
			'overlay-identificar-atencion-integral-completado': 'Se ha identificado la atención integral exitosamente.',
			'error-overlay-identificar-atencion-integral': 'Ha ocurrido un error desconocido al identificar la atención integral.',

			/* Overlay determinar sancion */
			'overlay-determinar-sancion-pregunta': '¿Está seguro que desea determinar la sanción?',
			'overlay-determinar-sancion-completado': 'Se ha determinado la sanción exitosamente.',
			'error-overlay-determinar-sancion': 'Ha ocurrido un error desconocido al determinar la sanción.',

			/* Overlay verificados datos de las personas señaladas como responsables */
			'overlay-verificar-señalado-pregunta': '¿Está seguro que desea verificar los presuntos responsables?',
			'overlay-verificar-señalado-completado': 'Se han verificado los presuntos responsables exitosamente.',
			'error-overlay-verificar-señalado': 'Ha ocurrido un error desconocido al verificar los presuntos responsables.',

			/* Overlay remitir denuncia */
			'overlay-remitir-denuncia-pregunta': '¿Está seguro que desea remitir la denuncia?',
			'overlay-remitir-denuncia-completado': 'Se han remitido la denuncia exitosamente.',
			'error-overlay-remitir-denuncia': 'Ha ocurrido un error desconocido al remitir la denuncia.',

			/* Overlay remitir denuncia */
			'overlay-agregar-nota-pregunta': '¿Está seguro que desea agregar una nota en la denuncia?',
			'overlay-agregar-nota-completado': 'Se han agregado una nota en la denuncia exitosamente.',
			'error-overlay-agregar-nota': 'Ha ocurrido un error desconocido al agregar nota.',

			/* Overlay Constancia de Denuncia */
			'overlay-constancia-denuncia-pregunta': '¿Está seguro que desea generar la constancia de denuncia?',
			'overlay-constancia-denuncia-completado': 'Se ha generado la constancia de denuncia exitosamente.',
			'error-overlay-constancia-denuncia': 'Ha ocurrido un error desconocido al generar la constancia de denuncia.',

			/* Overlay registrar denuncia */
			'overlay-registrar-denuncia-pregunta': '¿Está seguro que desea registrar la denuncia?',
			'overlay-registrar-denuncia-completado': 'Se ha registrado la denuncia exitosamente.',
			'error-overlay-registrar-denuncia': 'Ha ocurrido un error desconocido al registrar la denuncia.',

			/* Overlay cargar documentos */
			'overlay-cargar-documentos-pregunta': '¿Está seguro que desea cargar documentos?',
			'overlay-cargar-documentos-completado': 'Se ha cargado documentos exitosamente.',
			'error-overlay-cargar-documentos': 'Ha ocurrido un error desconocido al cargar documentos.',

			/* Overlay cargar documentos */
			'overlay-aclaracion-pregunta': '¿Está seguro que desea cargar la aclaración?',
			'overlay-aclaracion-completado': 'Se ha cargado la aclaración exitosamente.',
			'error-overlay-aclaracion': 'Ha ocurrido un error desconocido al cargar la aclaración.',

			/* Overlay solicitar ratificacion de firma */
			'overlay-solicitar-ratificacion-pregunta': '¿Está seguro que desea solicitar la ratificación de la firma del denunciante?',
			'overlay-solicitar-ratificacion-completado': 'Se ha solicitado la ratificación de la firma del denunciante exitosamente.',
			'error-overlay-solicitar-ratificacion': 'Ha ocurrido un error desconocido al solicitar la ratificación de la firma del denunciante.',

			/* Overlay satencion integral ejecutada */
			'overlay-comprehensive-care-executed-pregunta': '¿Se ejecutó la atención integral de manera satisfactoria?',
			'overlay-comprehensive-care-executed-completado': 'Se ha ejecutado la atención integral exitosamente.',
			'error-overlay-comprehensive-care-executed': 'Ha ocurrido un error desconocido al ejecutar la atención integral.',

			'overlay-close-appoinment-pregunta': '¿Está seguro que desea cerrar la audiencia?',
			'overlay-close-appoinment-completado': 'Se ha cerrar la audiencia exitosamente.',
			'error-overlay-close-appoinment': 'Ha ocurrido un error desconocido al cerrar la audiencia.',
			'error-overlay-close-appoinment-undefined': 'No existe una audiencia para cerrar.',

			'overlay-citar-primer-contacto-pregunta': '¿Está seguro que desea agendar la cita en la franja seleccionada?',

			/* Overlay ratificar firma */
			'overlay-ratificacion-firma-pregunta': '¿Está seguro que desea ratificar la firma del denunciante?',
			'overlay-ratificacion-firma-completado': 'Se ha ratificado la firma del denunciante exitosamente.',
			'error-overlay-ratificacion-firma': 'Ha ocurrido un error desconocido al solicitar la ratificar la firma del denunciante.',

			/* Overlay Carga de pruebas */
			'overlay-carga-evidencias-pregunta': '¿Está seguro que desea presentar sus pruebas?',
			'overlay-carga-evidencias-completado': 'Se han presentado sus pruebas exitosamente.',

			/* Overlay Verificar requisitos de la denuncia*/
			'overlay-verificar-requisitos-pregunta': '¿Está seguro que desea verificar los requisitos de la denuncia?',
			'overlay-verificar-requisitos-completado': 'Se han verificado los requisitos exitosamente.',
			'error-overlay-verificar-requisitos': 'Ha ocurrido un error desconocido al verificar los requisitos de la denuncia.',

			/* Overlay aclarar denuncia */
			'overlay-aclarar-denuncia-pregunta': '¿Está seguro que desea aclarar la denuncia?',
			'overlay-aclarar-denuncia-completado': 'Se ha aclarado la denuncia exitosamente.',
			'error-overlay-aclarar-denuncia': 'Ha ocurrido un error desconocido al aclarar la denuncia.',

			/* Overlay verificar numero del expediente */
			'overlay-verificar-numero-expediente-pregunta': '¿Está seguro que desea actualizar el número del expediente de la denuncia?',
			'overlay-verificar-numero-expediente-completado': 'Se ha actualizado el número del expediente exitosamente.',

			/* Overlay determinar competencia */
			'overlay-determinar-competencia-pregunta': '¿Está seguro que desea determinar la competencia de la CRyS?',
			'overlay-determinar-competencia-completado': 'Se ha determinado la competencia de la CRyS exitosamente.',
			'error-overlay-determinar-competencia': 'Ha ocurrido un error desconocido al determinar la competencia dela CRyS.',

			/* Overlay determinar competencia */
			'overlay-determinar-radicacion-pregunta': '¿Está seguro que desea determinar la radicación?',
			'overlay-determinar-radicacion-completado': 'Se ha determinado la radicación exitosamente.',
			'error-overlay-determinar-radicacion': 'Ha ocurrido un error desconocido al determinar la radicación.',

			/* Overlay citar CRyS */
			'titulo-reagendar-cita': 'Reagendar cita de la CRyS',
			'titulo-agendar-cita-crys': 'Agendar cita de la CRyS',
			'reagendar-cita': '¿Desea usted reagendar esta cita?',
			'agendar-cita': '¿Desea usted agendar esta cita?',
			'titulo-agendar-audiencia': 'Agendar Audiencia',
			'agendar-audiencia': '¿Desea usted agendar esta audiencia?',

			/* Overlay archivar */
			'overlay-titulo-denuncia-archivada': 'Se ha archivado con exito la denuncia',
			'overlay-archivar-denuncia-pregunta': '¿Está seguro que desea archivar esta denuncia?',
			'overlay-cargando-ipr-pregunta': '¿Está seguro que desea cargar el IPR?',
			'overlay-titulo-ipr-cargado': 'Se ha guardado el IPR con exito',

			/* Overlay citar audiencia */
			'titulo-reagendar-cita-audience': 'Reagendar cita de la audiencia',

			/* Overlay votaciones */
			'votacion-a-favor': 'Para que el proyecto final sea aprobado se debe tener un numero mayor de votos a favor que en contra.',
			'overlay-proyecto-final-pregunta': '¿Está seguro que desea guardar la información del protecto final?',
			'carga-final-project': 'Cargando proyecto final.',
			'titulo-final-project-success': 'Proyecto final cargado',

			/* Overlay Solicitar aclaración */
			'titulo-solicitando-aclaracion': 'Solicitando aclaración de la denuncia.',
			'overlay-solicitar-aclaracion-pregunta': '¿Está seguro que desea presentar el recurso de aclaración con la información ingresada?',
			'overlay-solicitud-aclaracion': 'Se ha solicitado la aclaración con exito.',

			/* Overlay Solicitar aclaración */
			'titulo-enviando-aclaracion': 'Se esta procesando su aclaración',
			'overlay-aclaracion-enviada': 'Se ha enviado la aclaración exito.',

			/* Overlay cerrar denuncia */
			'titulo-cerrando-denuncia': 'Cerrando denuncia',
			'overlay-cerrando-denuncia-pregunta': '¿Está seguro que desea cerrar la denuncia?',
			'overlay-denuncia-cerrada': 'Se ha cerrado la denuncia exitosamente.',

			/* Overlay cerrar denuncia */
			'titulo-desarchivando-denuncia': 'Desarchivando denuncia',
			'overlay-desarchivando-denuncia-pregunta': '¿Está seguro que desea desarchivar esta denuncia?',
			'overlay-denuncia-desarchivada': 'Se ha desarchivado la denuncia exitosamente.',

			/* Overlay asociar denuncias */
			'titulo-merge-complaint': 'Mezclar Denuncia(s)',
			'question-merge-complaint': '¿Desea usted mezclar estas denuncias a este caso?',
			'merge-complaint': 'Se esta mezclando las denuncia(s)',

			'title-success-merge-complaint': '¡Denuncia(s) mezclada(s)!)',
			'success-merge-complaint': 'Se ha mezclado con exito las denuncia(s)',

			'overlay-editar-usuario-pregunta': '¿Está seguro que desea editar el usuario?',

			'question-first-contact-add-inst-sub': '¿Desea usted actualizar los datos para este primer contacto?',
			'question-flowcharts-formats': '¿Desea usted agregar el flujo o formato al sistema?',

			'flowcharts-formats-pregunta': '¿Está seguro que desea eliminar este formato o diagrama de Flujo?',
			'flowcharts-formats-completado': 'Se ha eliminado este formato o diagrama de Flujo.',
			'error-flowcharts-formats': 'Ha ocurrido un error desconocido al eliminar este formato o diagrama de Flujo.',
			'flowcharts-formats-delete': 'Eliminando.',

			'permiso-denegado': 'Usted no tiene acceso a esta sección del sistema.',

			'pending-overlay-10': 'Se ha determinado que la denuncia no cumple con los requisitos. En espera del denunciante por aclaración de la denuncia.',
			'pending-overlay-36': 'La actual CRyS ha radicado el expediente. En espera de determinación de competencia por parte de la CRyS radicada.',

			'sin-grupo-tipo-1-asignado': 'No se ha podido ejecutar ya que no existe una CRyS para la instancia o subinstancia a la que pertenece el proceso del expediente. Porfavor intente más tarde o comuníquese con un administrador.',
			'sin-grupo-tipo-2-asignado': 'No se ha podido ejecutar ya que no existe una CGRH para la instancia o subinstancia a la que pertenece el proceso del expediente. Porfavor intente más tarde o comuníquese con un administrador.',
			'sin-grupo-tipo-3-asignado': 'No se ha podido ejecutar ya que no existe una DDU para la instancia o subinstancia a la que pertenece el proceso del expediente. Porfavor intente más tarde o comuníquese con un administrador.',
			'sin-grupo-tipo-4-asignado': 'No se ha podido ejecutar ya que no existe un Consejo Competente para la instancia o subinstancia a la que pertenece el proceso del expediente. Porfavor intente más tarde o comuníquese con un administrador.',

			'proceso-usuario-invalido': 'No tiene permisos para ejecutar esta acción',

			/* BreadCrumb */
			complaints: 'Denuncias',
			security: 'Perfil',
			'change password': 'Cambio de Contraseña',
			'personal information': 'Información Personal',
			'administrative information': 'Información Administrativa',
			'business hours': 'Horario de Atención',
			list: 'Lista',
			user: 'Usuarios',
			groups: 'Grupos',
			addinstance: 'Instancia',
			addusers: 'Usuarios',
			edit: 'Editar',
			create: 'Crear',
			process: 'Proceso',
			active: 'Activas',
			closed: 'Cerradas',
			archived: 'Archivadas',
			information: 'Información',
			activity: 'Actividad',
			evidences: 'Evidencias',
			documents: 'Documentos',
			flowcharts: 'Librería de Formatos',
			dashboard: 'Dashboard',
			schedule: 'Agenda',
			home: 'Inicio',

			// extras error

			'error-codigo-invalido': '',
			'tipo-correo-inexistente': '',
			'error-envio-correo': '',
		};

		return messages[code] ? messages[code] : code;
	},

	TitleCasePipe: (text: string): string => {
		let titleCasePipe: TitleCasePipe = new TitleCasePipe();
		return titleCasePipe.transform(text);
	},

	emptyObject: (object: any): boolean => {
		return Object.values(object).every(x => x === null || x === '');
	},

	getParticipantTypeString: (id: number): string => {
		let cat = ['', 'Primer Contacto', 'Denunciante', 'Víctima', 'Señalado como Presunto Responsable', 'Testigo', 'Tutor', 'Presidente de la CRyS', 'Secretario de la CRyS', 'Miembro de la CRyS', 'Miembro de la CGRH', 'Miembro de la DDU', 'Tutor de Señalado', '', 'Tutor de Victima'];
		return cat[id];
	},

	getUserTypeString: (id: number): string => {
		let cat = ['', 'Primer Contacto', 'Usuario Común'];
		return cat[id];
	},

	getUserTypeStringComplete: (id: number): string => {
		let cat = ['', 'Primer Contacto', 'Usuario Común', 'Preregistro', 'Presidente de la CRyS', 'Secretario de la CRyS', 'Miembro de la CRyS', 'Miembro de la CGRH', 'Miembro de la DDU', 'Administrador'];
		return cat[id];
	},

	lockDocument: () => {
		$('html').addClass('lockDom');
	},

	unlockDocument: () => {
		$('html').removeClass('lockDom');
	},

	validatorsSets: {
		names: [Validators.required, Validators.minLength(2), Validators.maxLength(50), CustomValidators.names()],
		gender: [Validators.required, Validators.minLength(1)],
		state: [Validators.required, Validators.minLength(1)],
		id_person: [Validators.required, Validators.minLength(1)],
		municipality: [Validators.required, Validators.minLength(1)],
		type_group: [Validators.required, Validators.minLength(1)],
		type_flowcharts_formats: [Validators.required, Validators.min(1)],
		email: [Validators.required, Validators.email],
		password: [Validators.required, Validators.minLength(8), Validators.maxLength(50), CustomValidators.password()],
		birthday: [Validators.required, CustomValidators.dateString('DDMMYYYY')],
		phone: [Validators.required, Validators.maxLength(10), Validators.minLength(10)],
		colony: [Validators.required, Validators.maxLength(50), Validators.minLength(5)],
		zipcode: [Validators.required, Validators.maxLength(5), Validators.minLength(5)],
		street: [Validators.required, Validators.maxLength(100), Validators.minLength(5)],
		instreet: [Validators.maxLength(50)],
		extstreet: [Validators.maxLength(50)],
	},

	alertDefaults: {
		icon: 'error',
		title: '¡Error!',
		confirmButtonText: 'Continuar',
		cancelButtonText: 'Cancelar',
		allowOutsideClick: false,
		customClass: {
			confirmButton: 'btn btn-primary py-3 px-4 mr-2 ml-2',
			cancelButton: 'btn btn-secondary py-3 px-4 mr-2 ml-2',
			input: 'text-center',
		},
		buttonsStyling: false,
	},

	toastDefaults: {
		icon: 'error',
		toast: true,
		position: 'top-end',
		showConfirmButton: false,
		timer: 3000,
		timerProgressBar: true,
		didOpen: (toast: any) => {
			toast.addEventListener('mouseenter', Swal.stopTimer);
			toast.addEventListener('mouseleave', Swal.resumeTimer);
		},
	},
};
