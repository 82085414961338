import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Angular2AirDatepickerModule } from 'angular2-air-datepicker';
import { NgSelect2Module } from 'ng-select2';
import { NgIconsModule } from '@ng-icons/core';
import { FeatherChevronsRight, FeatherChevronsLeft, FeatherMoreHorizontal, FeatherType, FeatherPaperclip, FeatherCheckCircle, FeatherCheck, FeatherSettings, FeatherDownload, FeatherCopy, FeatherTrash, FeatherFile, FeatherTool, FeatherChevronRight, FeatherChevronLeft, FeatherXCircle, FeatherTrash2, FeatherCheckSquare, FeatherVolume, FeatherVolume1, FeatherVolume2, FeatherVolumeX, FeatherSkipBack, FeatherSkipForward, FeatherPause, FeatherPlay, FeatherEye, FeatherEyeOff, FeatherX, FeatherKey, FeatherMail, FeatherUser, FeatherCalendar, FeatherPhone, FeatherMap, FeatherMapPin, FeatherHome, FeatherBriefcase, FeatherArchive, FeatherLock, FeatherSearch, FeatherPlus, FeatherUpload, FeatherBarChart2, FeatherBell, FeatherToggleRight, FeatherHelpCircle, FeatherChevronDown, FeatherCircle, FeatherHash, FeatherNavigation, FeatherUsers, FeatherSquare, FeatherTriangle, FeatherUserPlus, FeatherUserMinus, FeatherClipboard, FeatherMinusCircle, FeatherPlusCircle, FeatherPenTool, FeatherDelete, FeatherGlobe, FeatherInfo, FeatherEdit, FeatherFilePlus, FeatherFileText, FeatherBookOpen, FeatherList, FeatherActivity, FeatherFolder, FeatherGitPullRequest, FeatherTag, FeatherCrosshair, FeatherUserCheck, FeatherAlertOctagon, FeatherCornerDownRight, FeatherShield, FeatherBook, FeatherExternalLink, FeatherClock, FeatherColumns, FeatherArrowDown, FeatherArrowUp } from '@ng-icons/feather-icons';
import { matHelpOutline, matSupportAgentOutline, matEditOffOutline } from '@ng-icons/material-icons/outline';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NglrxPipesModule } from '@nglrx/pipes';
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { NgxMaskModule } from 'ngx-mask';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ComplaintReviewComponent } from '../modules/dashboard/modules/complaints/modules/complaint-review/complaint-review.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { TrimInputDirective } from '../core/directives/trim-input.directive';
import { ActionValidatorDirective } from '../core/directives/security.directive';
import { ViewDocComponent } from './view-doc/view-doc.component';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ToastrModule } from 'ngx-toastr';
import { SpinnerComponent } from '../core/components/spinner/spinner.component';
import { UcFirstPipe } from '../core/pipe/uc-first.pipe';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { WebViewerComponent } from './web-viewer/web-viewer.component';
import { NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationService } from './service/pagination.service';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { RouterModule } from '@angular/router';
import { MyDefaultTooltipOptions } from '../core/constants/tooltip-config';
import { TableSkeletonLoaderComponent } from './table-skeleton-loader/table-skeleton-loader.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { OverlayRiskAssessmentComponent } from './overlay-risk-assessment/overlay-risk-assessment.component';
import { MyFilterPipe } from '../core/pipe/my-filter.pipe';

FullCalendarModule.registerPlugins([
	// register FullCalendar plugins
	dayGridPlugin,
	interactionPlugin,
	timeGridPlugin,
	listPlugin,
]);

@NgModule({
	declarations: [MyFilterPipe, OverlayRiskAssessmentComponent, TableSkeletonLoaderComponent, SpinnerComponent, ComplaintReviewComponent, TrimInputDirective, ActionValidatorDirective, ViewDocComponent, UcFirstPipe, WebViewerComponent, SideMenuComponent, TopBarComponent, MobileMenuComponent],
	imports: [
		CommonModule,
		Angular2AirDatepickerModule,
		NglrxPipesModule,
		NgSelect2Module,
		NgxMaskModule.forRoot(),
		NgIconsModule.withIcons({ matHelpOutline, matEditOffOutline, matSupportAgentOutline, FeatherChevronsRight, FeatherChevronsLeft, FeatherMoreHorizontal, FeatherType, FeatherPaperclip, FeatherCheckCircle, FeatherCheck, FeatherSettings, FeatherDownload, FeatherCopy, FeatherTrash, FeatherFile, FeatherTool, FeatherChevronRight, FeatherChevronLeft, FeatherXCircle, FeatherTrash2, FeatherCheckSquare, FeatherVolume, FeatherVolume1, FeatherVolume2, FeatherVolumeX, FeatherSkipBack, FeatherSkipForward, FeatherPlay, FeatherPause, FeatherEye, FeatherEyeOff, FeatherX, FeatherKey, FeatherMail, FeatherUser, FeatherCalendar, FeatherPhone, FeatherMap, FeatherMapPin, FeatherHome, FeatherBriefcase, FeatherArchive, FeatherLock, FeatherSearch, FeatherPlus, FeatherUpload, FeatherBarChart2, FeatherBell, FeatherToggleRight, FeatherHelpCircle, FeatherChevronDown, FeatherCircle, FeatherHash, FeatherNavigation, FeatherUsers, FeatherSquare, FeatherTriangle, FeatherUserPlus, FeatherUserMinus, FeatherClipboard, FeatherMinusCircle, FeatherPlusCircle, FeatherPenTool, FeatherDelete, FeatherGlobe, FeatherInfo, FeatherEdit, FeatherFilePlus, FeatherFileText, FeatherBookOpen, FeatherList, FeatherActivity, FeatherFolder, FeatherGitPullRequest, FeatherTag, FeatherCrosshair, FeatherUserCheck, FeatherAlertOctagon, FeatherCornerDownRight, FeatherShield, FeatherBook, FeatherExternalLink, FeatherClock, FeatherColumns, FeatherArrowDown, FeatherArrowUp }),
		FormsModule,
		ReactiveFormsModule,
		TooltipModule.forRoot(MyDefaultTooltipOptions as TooltipOptions),
		NgxDropzoneModule,
		ImageCropperModule,
		FullCalendarModule,
		NgxExtendedPdfViewerModule,
		NgxTrimDirectiveModule,
		NgxPaginationModule,
		ToastrModule.forRoot({
			closeButton: true,
			progressBar: true,
		}),
		NgbTimepickerModule,
		RouterModule,
	],
	exports: [OverlayRiskAssessmentComponent, NgxPaginationModule, WebViewerComponent, CommonModule, Angular2AirDatepickerModule, NglrxPipesModule, NgSelect2Module, NgxMaskModule, NgIconsModule, FormsModule, ReactiveFormsModule, TooltipModule, NgxDropzoneModule, ImageCropperModule, FullCalendarModule, ComplaintReviewComponent, TrimInputDirective, ActionValidatorDirective, ViewDocComponent, NgxTrimDirectiveModule, ToastrModule, SpinnerComponent, UcFirstPipe, NgxExtendedPdfViewerModule, NgbTimepickerModule, SideMenuComponent, TopBarComponent, MobileMenuComponent, TableSkeletonLoaderComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	providers: [MyFilterPipe, PaginationService],
})
export class SharedModule { }
