<app-mobile-menu></app-mobile-menu>
<app-top-bar></app-top-bar>
<div class="wrapper">
	<div class="wrapper-box"> 
		<div class="content" [ngClass]="{ 'pt-10': selectFile == 'audio' }" >
			<app-spinner *ngIf="loader"></app-spinner>
			<img *ngIf="selectFile == 'image'" [src]="file.content" class="m-auto" />
			<ngx-extended-pdf-viewer class="flex-1 h-full w-full" *ngIf="selectFile == 'pdf'" [src]="file.content"
				[useBrowserLocale]="true" [showDownloadButton]="false" [showPrintButton]="false" [showOpenFileButton]="false">
			</ngx-extended-pdf-viewer>
			<app-web-viewer [filePath]="file" *ngIf="selectFile == 'audio'"></app-web-viewer>
		</div>
	</div>
</div>