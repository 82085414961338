import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { Observable, Subject, takeUntil } from 'rxjs';
import { surtechUtils } from 'src/app/core/exports/surtechUtils';
import { UserData } from 'src/app/core/interfaces/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';

@Component({
	selector: 'app-complaint-review',
	templateUrl: './complaint-review.component.html',
	styleUrls: ['./complaint-review.component.scss'],
})
export class ComplaintReviewComponent implements OnInit {
	nowDate = new Date();

	@Input() canExport: boolean = false;
	@Input() lists: any = {};
	@Input() complaintData: any = null;
	@Input() type: string = 'resume';
	@Input() presentationProofFCsign: string | null = null;

	UserData$?: Observable<any>;
	UserDataFull: UserData = {} as UserData;
	hideSensibleData: boolean = true;

	private _unsubscribe = new Subject<void>();
	constructor(private store: Store) {
		this.UserData$ = this.store.pipe(select(UserDataFull));
		this.UserData$.pipe(takeUntil(this._unsubscribe)).subscribe(data => {
			this.UserDataFull = data;
		});
	}

	ngOnDestroy(): void {
		this._unsubscribe.next();
		this._unsubscribe.complete();
	}

	ngOnInit(): void {
		this.hideSensibleData = this.isRestrictedUser();
	}

	getCleanFileType(type: string) {
		return type.split('.').pop();
	}

	getReadableFileSize(bytes: number, transform = true): string {
		if (transform) {
			return surtechUtils.formatBytes(bytes);
		} else {
			return bytes as unknown as string;
		}
	}

	getListString(id: number, list: any): string {
		if (list) {
			let elem = list.filter((element: any) => element.id == id);
			return elem[0].text;
		}

		return '';
	}

	formatDate(date: string) {
		return moment(date).format('DD/MM/YYYY hh:mm A');
	}

	/** Método que evalúa si la persona en sesión es un tutor como responsable o el responsable y oculta información sensible del resumen de la denuncia */
	isRestrictedUser(): boolean {
		// Obtener la información de los responsables y de los tutores de los responsables
		let allegedPerpetrators = this.complaintData.responsibles;
		let allegedPerpetratorsTutors: any[] = [];
		allegedPerpetrators.forEach((allegedPerpetrator: any) => {
			if (allegedPerpetrator.parents && allegedPerpetrator.parents[0]) {
				allegedPerpetrator.parents.forEach(tutor => {
					allegedPerpetratorsTutors.push(tutor);
				});
			}
		});

		// Llenar arreglo con los ids para los que se debe ocultar información sensible
		let userIdsWithRestrictions: any[] = [];
		allegedPerpetrators.forEach((allegedPerpetrator: any) => {
			if (allegedPerpetrator.fk_id_user) {
				userIdsWithRestrictions.push(allegedPerpetrator.fk_id_user);
			}
		});

		allegedPerpetratorsTutors.forEach((allegedPerpetratorsTutor: any) => {
			if (allegedPerpetratorsTutor.fk_id_user) {
				userIdsWithRestrictions.push(allegedPerpetratorsTutor.fk_id_user);
			}
		});

		//Evaluar si el ID de la persona en sesión se encuentra dentro del arreglo de usuarios con restricciones
		return userIdsWithRestrictions.includes(this.UserDataFull.id_user);
	}
}
