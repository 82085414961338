<div *ngIf="loader" class="flex flex-col w-full h-full">
	<div class="flex-1"></div>
</div>
<div [ngClass]="loader ? 'hidden' : ''" class="flex flex-col w-full h-full">
	<div
		style="border-bottom: 1px solid gray; background-color: #21242a; color: white; text-align: center; padding: 3px">
		{{ file.name }}.{{ file.fileExtension }}</div>
	<div #viewer class="flex-1 flex flex-col items-center justify-center" id="viewer">
		<div class="w-[90%]">
			<div class="flex items-center justify-between relative">
				<div style="color: #adb5bd">
					<span id="time-total">00:00:00</span>
				</div>
				<div class="absolute flex w-full justify-center">
					<ng-icon name="feather-skip-back" size="24px" class="iconColor cursor-pointer mr-4"
						(click)="skipBack()"></ng-icon>
					<ng-icon name="feather-play" size="24px" class="iconColor cursor-pointer" *ngIf="!viewPausePlay"
						(click)="PausePlay()"></ng-icon>
					<ng-icon name="feather-pause" size="24px" class="iconColor cursor-pointer" *ngIf="viewPausePlay"
						(click)="PausePlay()"></ng-icon>
					<ng-icon name="feather-skip-forward" size="24px" class="iconColor cursor-pointer ml-4"
						(click)="skipForward()"></ng-icon>
				</div>
				<div class="z-[1]">
					<ng-icon name="feather-volume-1" size="24px" class="iconColor cursor-pointer mx-4 align-text-bottom"
						(click)="getMute()" *ngIf="!(WaveSurferDoc?.getMute()) && volumen < 0.50 && volumen != 0 ">
					</ng-icon>
					<ng-icon name="feather-volume-2" size="24px" class="iconColor cursor-pointer mx-4 align-text-bottom"
						(click)="getMute()" *ngIf="!(WaveSurferDoc?.getMute()) && volumen > 0.50 "></ng-icon>
					<ng-icon name="feather-volume-x" size="24px" class="iconColor cursor-pointer mx-4 align-text-bottom"
						(click)="getMute()" *ngIf=" volumen == 0 "></ng-icon>
					<input type="range" min="0" max="1" step="0.01" [(ngModel)]="volumen" (change)="changeZoom()">
					<strong class="iconColor mx-3 align-super cursor-pointer"
						(click)="setPlaybackRateFunction()">{{WaveSurferDoc?.getPlaybackRate()}}x</strong>
				</div>
			</div>
			<div id="audio-viewer"></div>
		</div>
	</div>
</div>