import { Action, createReducer, on } from '@ngrx/store';
import { UsersFilterModel, GroupsFilterModel, FirstContactsFilterModel, FlowchartsFormatsFilterModel } from '../../models/users-filter.model';
import { FilterActionsTypes, UserActions } from './table-filter.actions';

export interface FilterState {
	UsersFilter: UsersFilterModel;
	GroupsFilter: GroupsFilterModel;
	FirstContactsFilter: FirstContactsFilterModel;
	FlowchartsFormatsFilter:FirstContactsFilterModel;
}

export const initialUsersFilter: UsersFilterModel = {
	page: 1,
	num: 5,
	search: '',
} as UsersFilterModel;

export const initialGroupsFilter: GroupsFilterModel = {
	page: 1,
	num: 5,
	search: '',
	tipo: 0
} as GroupsFilterModel;

export const initialFirstContactsFilter: FirstContactsFilterModel = {
	page: 1,
	num: 5,
	search: '',
} as FirstContactsFilterModel;

export const initialFlowchartsFormatsFilter: FlowchartsFormatsFilterModel = {
	page: 1,
	num: 5,
	search: '',
	tipo: 0
} as FlowchartsFormatsFilterModel;

export const initialUsersState: FilterState = {
	UsersFilter: initialUsersFilter,
	GroupsFilter: initialGroupsFilter,
	FirstContactsFilter: initialFirstContactsFilter,
	FlowchartsFormatsFilter:initialFlowchartsFormatsFilter
};

export function FilterReducer(state = initialUsersState, action: Action) {
	const tutorialAction = action as UserActions;
	switch (tutorialAction.type) {
		case FilterActionsTypes.UsersFilter:
			return {
				...state,
				UsersFilter: tutorialAction.payload.UsersFilter,
			};
		case FilterActionsTypes.UserFilterClear:
			return {
				...state,
				UsersFilter: initialUsersFilter,
			};
		case FilterActionsTypes.GroupsFilter:
			return {
				...state,
				GroupsFilter: tutorialAction.payload.GroupsFilter,
			};
		case FilterActionsTypes.GroupsFilterClear:
			return {
				...state,
				GroupsFilter: initialGroupsFilter,
			};
		case FilterActionsTypes.FirstContactsFilter:
			return {
				...state,
				FirstContactsFilter: tutorialAction.payload.FirstContactsFilter,
			};
		case FilterActionsTypes.FirstContactsFilterClear:
			return {
				...state,
				FirstContactsFilter: initialFirstContactsFilter,
			};
			case FilterActionsTypes.FlowchartsFormatsFilter:
			return {
				...state,
				FlowchartsFormatsFilter: tutorialAction.payload.FlowchartsFormatsFilter,
			};
		case FilterActionsTypes.FlowchartsFormatsFilterClear:
			return {
				...state,
				FlowchartsFormatsFilter: initialFlowchartsFormatsFilter,
			};
		default:
			return state;
	}
}
