import { Action, createReducer, on, State } from '@ngrx/store';
import { UserData, AccessToken } from '../../interfaces/user-data';
import { UserDataActions, UserDataActionsTypes } from './user-data.actions';

export interface UserDataState {
    UserData: UserData,
    AccessToken: AccessToken
}

export const UserDataInititalState: UserData = {} as UserData;
export const x_access_tokenInititalState: AccessToken = {} as AccessToken;

export const initialUserDataState: UserDataState = {
    UserData: UserDataInititalState,
    AccessToken: x_access_tokenInititalState
}

export function UserReducer(state = initialUserDataState, action: Action) {
    const tutorialAction = action as UserDataActions;
    switch (tutorialAction.type) {
        case UserDataActionsTypes.UserData:
            return {
                ...state,
                UserData: tutorialAction.payload.UserData
            }
        case UserDataActionsTypes.SaveToken:
            return {
                ...state,
                AccessToken: tutorialAction.payload.AccessToken
            }
        case UserDataActionsTypes.Logout:
            localStorage.clear();
            return {
                ...initialUserDataState
            }
        default: {
            return state;
        }
    }
}