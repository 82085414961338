<div class="mobile-menu lg:hidden">
	<div class="mobile-menu-bar">
		<a routerLink="/" class="flex mr-auto">
			<img src="assets/img/logo_udg_white.png" />
		</a>
		<a class="flex cursor-pointer" id="mobile-menu-toggler">
			<ng-icon class="mr-2" name="feather-bar-chart-2" class="text-white transform -rotate-90" size="32px"></ng-icon>
		</a>
	</div>
	<ul class="border-t border-white/[0.08] py-5 hidden">
		<li>
			<a routerLink="/" class="menu">
				<div class="menu__title">Inicio</div>
			</a>
		</li>
		<li>
			<a routerLink="complaints" class="menu">
				<div class="menu__title">Denuncias</div>
			</a>
		</li>
	</ul>
</div>
